import {
  arrayRemove,
  deleteDoc,
  getDoc,
  Timestamp,
  updateDoc,
} from 'firebase/firestore';
import { JIRA_CONNECT_APPS_DATA_COLLECTION } from './../constants';
import {
  arrayUnion,
  doc,
  onSnapshot,
  setDoc,
  query,
  collection,
  orderBy,
} from 'firebase/firestore';
import { auth, firestore } from '../../firebase';
import { CustomDeck } from '../types';

type AppInfoInFirestore = {
  uuid: string;
  domain: string;
};

export const createApp = (app: AppInfoInFirestore) =>
  setDoc(doc(firestore, 'connect-apps', app.uuid), app);

export type AppData = {
  customDecks: CustomDeck[];
};
export type AppDataServer = AppData;

export const updateAppData = (appId: string, appData: Partial<AppData>) =>
  setDoc(doc(firestore, JIRA_CONNECT_APPS_DATA_COLLECTION, appId), appData, {
    merge: true,
  });

export const addCustomDeckToApp = async (
  appId: string,
  customDeck: CustomDeck,
) => {
  const signedInUser = auth.currentUser;

  if (!signedInUser) {
    return Promise.reject();
  }

  setDoc(
    doc(firestore, JIRA_CONNECT_APPS_DATA_COLLECTION, appId),
    {
      customDecks: arrayUnion(customDeck),
    },
    { merge: true },
  );
};
export const deleteCustomDeckFromApp = async (
  appId: string,
  customDeck: CustomDeck,
) => {
  const signedInUser = auth.currentUser;

  if (!signedInUser) {
    return Promise.reject();
  }

  const appData = await (
    await getDoc(doc(firestore, JIRA_CONNECT_APPS_DATA_COLLECTION, appId))
  ).data();

  if (!appData) {
    return;
  }

  const customDecks = appData.customDecks as CustomDeck[];

  setDoc(
    doc(firestore, JIRA_CONNECT_APPS_DATA_COLLECTION, appId),
    {
      customDecks: customDecks.filter(
        (deck) =>
          deck.name !== customDeck.name ||
          deck.value !== customDeck.value ||
          deck.creatorId !== customDeck.creatorId,
      ),
    },
    { merge: true },
  );
};

export const subscribeToAppData = (
  appId: string,
  onAppDataChanged: (appData: AppData) => void,
) => {
  const docRef = doc(firestore, JIRA_CONNECT_APPS_DATA_COLLECTION, appId);

  return onSnapshot(docRef, (docSnapshot) => {
    const appData = docSnapshot.data() as AppDataServer;

    if (!appData) {
      return;
    }

    onAppDataChanged({
      ...appData,
    });
  });
};

export type PlayerServer = {
  uid: string;
  displayName: string;
  avatar: string;
  last_changed: Timestamp;
  state: 'offline' | 'online';
  gameId: string;
  gameName: string;
};

export type AppUser = {
  uid: string;
  displayName: string;
  avatar: string;
  lastOnline: Date | null;
  isOnline: boolean;
  lastGameId: string | null;
  lastGameName: string | null;
};

export const subscribeToAppUsers = (
  appId: string,
  onAppUsersChanged: (appUsers: AppUser[]) => void,
) => {
  const playersRef = query(
    collection(
      firestore,
      `${JIRA_CONNECT_APPS_DATA_COLLECTION}/${appId}/players`,
    ),
    orderBy('last_changed', 'desc'),
  );

  return onSnapshot(playersRef, (querySnapshot) => {
    const players: PlayerServer[] = [];

    querySnapshot.forEach(function (doc) {
      const data = doc.data() as PlayerServer;

      players.push(data);
    });

    const uniquePlayers = players.filter(
      (player, i) =>
        i === players.findIndex((_player) => _player.uid === player.uid),
    );

    const appUsers: AppUser[] = uniquePlayers.map((player) => {
      return {
        uid: player.uid,
        displayName: player.displayName,
        avatar: player.avatar,
        lastOnline: player.last_changed ? player.last_changed.toDate() : null,
        isOnline: player.state === 'online',
        lastGameId: player.gameId,
        lastGameName: player.gameName,
      };
    });

    onAppUsersChanged(appUsers);
  });
};
