import { useEffect } from 'react';
import { useGameContext } from '../../hooks/useGameContext';
import { useAuthContext } from '../../../auth/useAuthContext';
import { Spotlight, SpotlightTransition } from '@atlaskit/onboarding';
import { useOneTimeUserAction } from '../../../auth/useOneTimeUserAction';

export const ShowCardsTooltip = () => {
  const [isDone, setIsDone] = useOneTimeUserAction('showCardsTooltip');
  const gameContext = useGameContext();
  const auth = useAuthContext();
  const game = gameContext?.game;
  const user = auth.user;

  const isLoading = !game || !user;
  const isOwner = game?.ownerId === user?.uid;
  const isVoting = !!gameContext?.isVoting;
  const showCards = !!gameContext?.game?.cardsUp;

  const isOpen =
    !isLoading && isDone === false && isOwner && isVoting && !showCards;

  useEffect(() => {
    if (isDone === false && isOwner && showCards) {
      setIsDone();
    }
  }, [isDone, isOwner, setIsDone, showCards]);

  return (
    <SpotlightTransition>
      {isOpen && (
        <Spotlight
          actions={[
            {
              onClick: setIsDone,
              text: 'OK',
            },
          ]}
          heading="Table is glowing, all players have voted!"
          target="table"
          key="show-cards"
          targetOnClick={setIsDone}
          targetRadius={30}
        >
          Click to reveal the cards to everyone.
        </Spotlight>
      )}
    </SpotlightTransition>
  );
};
