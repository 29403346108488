export const openIssueModal = (issueKey: string, onClose?: () => void) =>
  window.AP.jira.openIssueDialog(issueKey, () => {
    if (onClose) {
      onClose();
    }
  });

export const showNotification = (notification: {
  title: string;
  body: string;
  type: 'info' | 'success' | 'warning' | 'error';
  close?: 'manual' | 'auto';
  // actions: {
  //   actionkey: 'Click me',
  // },
}) =>
  // Display a nice green flag using the Flags JavaScript API.
  window.AP.flag.create({ close: 'auto', ...notification });

export const getCurrentJiraUserAccountId = async () => {
  return new Promise<string>((resolve) => {
    window.AP.user.getCurrentUser((user) =>
      resolve(user.atlassianAccountId as string),
    );
  });
};

export const openJQLSearch = ({
  jql,
  header,
  onSubmit,
  submitText,
  cancelText,
}: {
  jql: string;
  header: string;
  submitText: string;
  cancelText: string;
  onSubmit: (res: { jql: string }) => void;
}) => {
  const options = {
    jql,
    header,
    submitText,
    cancelText,
  };

  window.AP.jira.showJQLEditor(options, onSubmit);
};
