import { useEffect, useRef, useState } from 'react';
import cx from 'classnames';
import Button, { ButtonGroup } from '@atlaskit/button';
import { LinkButton } from '@atlaskit/button/new';
import { useGameContext } from '../../hooks/useGameContext';
import { IssueListSidebar } from '../IssueListSidebar/IssueListSidebar';
import { QuickAdd } from '../QuickAdd/QuickAdd';
import { Inline, Stack } from '@atlaskit/primitives';
import { useIssueActions } from '../../hooks/useIssueActions';
import styles from './Sidebar.module.scss';
import Tooltip from '@atlaskit/tooltip';
import { SearchPanel } from '../SearchPanel/SearchPanel';
import CloseIcon from '@atlaskit/icon/glyph/cross';
import { IconButton } from '@atlaskit/button/new';
import SectionMessage, {
  SectionMessageAction,
} from '@atlaskit/section-message';
import Modal, {
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  ModalTransition,
} from '@atlaskit/modal-dialog';
import { useSidebarLayoutContext } from '../SidebarLayoutWrapper/useSidebarLayoutContext';
import { SpotlightTarget } from '@atlaskit/onboarding';
import { AddIssuesTooltip } from './AddIssueTooltip';
import { StoryPointsFieldPicker } from '../SearchPanel/components/StoryPointsFieldPicker/StoryPointsFieldPicker';
import { SelectStoryPointsModal } from '../../../jira/components/SelectStoryPointsModal/SelectStoryPointsModal';

export const Sidebar = () => {
  const gameContext = useGameContext();
  const sidebarContext = useSidebarLayoutContext();
  const issuesContainerEnd = useRef<HTMLDivElement>(null);
  const [isConfirmDeleteOpen, setIsConfirmDeleteOpen] = useState(false);
  const [isSelectStoryPointsModalOpen, setIsSelectStoryPointsModalOpen] =
    useState(false);
  const [isErrorMessageOpen, setIsErrorMessageOpen] = useState<null | boolean>(
    null,
  );
  const { deleteAll } = useIssueActions();

  const isSidebarOpen = sidebarContext.issuesSidebar[0];
  const setIsSidebarOpen = sidebarContext.issuesSidebar[1];

  const isAddIssuesPanelOpen = sidebarContext.manageIssuesPanel[0];
  const setIsAddIssuesPanelOpen = sidebarContext.manageIssuesPanel[1];
  const setIsSettingsSidebarOpen = sidebarContext.settingsSidebar[1];

  const storyPointsIsMissing =
    !!gameContext?.game && !gameContext.game.storyPointsField;

  useEffect(() => {
    if (isErrorMessageOpen === false) {
      return;
    }

    setIsErrorMessageOpen(storyPointsIsMissing ? true : null);
  }, [storyPointsIsMissing, isErrorMessageOpen]);

  if (!gameContext) {
    return null;
  }

  const handleBlanketClick = () => {
    if (isAddIssuesPanelOpen) {
      setIsAddIssuesPanelOpen(false);
    } else {
      setIsSidebarOpen(false);
    }
  };

  const handleIssueAdded = () => {
    if (issuesContainerEnd.current) {
      issuesContainerEnd.current.scrollIntoView({
        behavior: 'smooth',
      });
    }
  };

  const getErrorMessage = () => {
    return isErrorMessageOpen ? (
      <SectionMessage
        title="Cannot detect the story points field"
        appearance="warning"
        actions={[
          <SectionMessageAction onClick={() => setIsSettingsSidebarOpen(true)}>
            Edit in settings
          </SectionMessageAction>,
          <SectionMessageAction onClick={() => setIsErrorMessageOpen(false)}>
            Dismiss
          </SectionMessageAction>,
        ]}
      >
        <p>
          We're unable to detect which field do you use for story points
          estimates.
        </p>
      </SectionMessage>
    ) : null;
  };

  const handleDeleteAll = () => {
    deleteAll();
    setIsConfirmDeleteOpen(false);
  };

  const handleAddIssuesWhenIdle = () => {
    if (storyPointsIsMissing) {
      setIsSelectStoryPointsModalOpen(true);
    } else {
      setIsAddIssuesPanelOpen(true);
    }
  };

  const game = gameContext.game;
  const isIssuesEmpty = !game?.issues?.length;

  return (
    <>
      <div
        className={cx(
          styles['blanket'],
          isSidebarOpen && styles['blanket--sidebar-open'],
          isAddIssuesPanelOpen && styles['blanket--manage-issues-panel-open'],
        )}
        onClick={handleBlanketClick}
      />
      <div
        className={cx(
          styles['manage-issues-panel'],
          isAddIssuesPanelOpen && styles['manage-issues-panel--open'],
          !isSidebarOpen && styles['manage-issues-panel--sidebar-closed'],
        )}
      >
        {isAddIssuesPanelOpen && (
          <SearchPanel
            onIssueAdded={handleIssueAdded}
            errorMessage={getErrorMessage()}
          />
        )}
      </div>
      <div
        className={cx(
          styles['sidebar'],
          isSidebarOpen && styles['sidebar--open'],
          isAddIssuesPanelOpen && styles['sidebar--manage-issues-open'],
        )}
      >
        <div className={styles['sidebar-header']}>
          <div>
            <Inline spread="space-between" alignBlock="center">
              <Inline space="space.100" alignBlock="center">
                {isAddIssuesPanelOpen ? (
                  <ButtonGroup>
                    {isAddIssuesPanelOpen && (
                      <Button
                        isDisabled={isIssuesEmpty}
                        onClick={() => setIsConfirmDeleteOpen(true)}
                      >
                        Remove all issues
                      </Button>
                    )}
                  </ButtonGroup>
                ) : (
                  !isIssuesEmpty && <span>Issues</span>
                )}
              </Inline>

              <Inline space="space.100" alignBlock="center">
                {!isAddIssuesPanelOpen && !isIssuesEmpty && (
                  <Button
                    onClick={() => setIsAddIssuesPanelOpen(true)}
                    isSelected={!!isAddIssuesPanelOpen}
                  >
                    Manage issues
                  </Button>
                )}

                {isAddIssuesPanelOpen ? (
                  <Button
                    onClick={() => {
                      setIsAddIssuesPanelOpen(false);
                    }}
                  >
                    Done
                  </Button>
                ) : (
                  <Tooltip content="Hide issues sidebar">
                    {(tooltipProps) => (
                      <div {...tooltipProps}>
                        <IconButton
                          onClick={() => {
                            setIsSidebarOpen(false);
                          }}
                          label="Close"
                          shape="circle"
                          icon={CloseIcon}
                        />
                      </div>
                    )}
                  </Tooltip>
                )}
              </Inline>
            </Inline>
          </div>
        </div>
        <div className={styles['sidebar-content']}>
          {isIssuesEmpty ? (
            isAddIssuesPanelOpen ? (
              <div className={styles['empty-state']}>
                <Stack space="space.100" alignInline="center">
                  <Inline
                    space="space.075"
                    alignBlock="center"
                    alignInline="center"
                  >
                    <span>👈</span>
                    <span>Add issues on the left</span>
                  </Inline>
                  <Inline
                    space="space.075"
                    alignBlock="center"
                    alignInline="center"
                  >
                    <span>Or quick add issues on the bottom</span>
                    <div> 👇</div>
                  </Inline>
                </Stack>
              </div>
            ) : (
              <div className={styles['empty-state']}>
                <Stack space="space.300" alignInline="center">
                  <Stack space="space.100" alignInline="center">
                    <div>
                      <strong>No issues yet</strong>
                    </div>
                    <div>Add the issues you want to vote (Optional)</div>
                  </Stack>
                  <div>
                    <SpotlightTarget name="add-issues-button">
                      <Button
                        onClick={handleAddIssuesWhenIdle}
                        appearance="primary"
                      >
                        Add issues now
                      </Button>
                    </SpotlightTarget>
                  </div>
                </Stack>
                <AddIssuesTooltip />
              </div>
            )
          ) : (
            <>
              <IssueListSidebar
                errorMessage={isAddIssuesPanelOpen ? null : getErrorMessage()}
                issuesContainerEnd={issuesContainerEnd}
              />
            </>
          )}
        </div>
        <div className={styles['sidebar-footer']}>
          <div>
            <QuickAdd onIssueAdded={handleIssueAdded} />
          </div>
        </div>
        <div className={styles['sidebar-separator']} />
      </div>

      <ModalTransition>
        {isConfirmDeleteOpen && (
          <Modal onClose={() => setIsConfirmDeleteOpen(false)}>
            <ModalHeader>
              <ModalTitle appearance="danger">Remove all issues</ModalTitle>
            </ModalHeader>
            <ModalBody>
              <p>Are you sure you want to remove all issues from the game?</p>
              <p>
                (The issues won't be deleted from Jira, just removed from this
                game)
              </p>
            </ModalBody>
            <ModalFooter>
              <Button
                appearance="subtle"
                onClick={() => setIsConfirmDeleteOpen(false)}
              >
                Cancel
              </Button>
              <Button appearance="danger" onClick={handleDeleteAll}>
                Remove all issues
              </Button>
            </ModalFooter>
          </Modal>
        )}
      </ModalTransition>
      <SelectStoryPointsModal
        isOpen={isSelectStoryPointsModalOpen}
        onClose={() => setIsSelectStoryPointsModalOpen(false)}
        onSubmitted={() => {
          setIsSelectStoryPointsModalOpen(false);
          setIsAddIssuesPanelOpen(true);
        }}
        onSkipped={() => {
          setIsSelectStoryPointsModalOpen(false);
          setIsAddIssuesPanelOpen(true);
        }}
      />
    </>
  );
};
