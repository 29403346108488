import {
  getDoc,
  setDoc,
  getDocs,
  doc,
  onSnapshot,
  query,
  serverTimestamp,
  collection,
  where,
  orderBy,
  increment,
  arrayUnion,
} from 'firebase/firestore';
import {
  ref,
  uploadBytesResumable,
  getDownloadURL,
  deleteObject,
} from 'firebase/storage';
import {
  PokerTableSummary,
  User,
  UserMetadata,
  UserServer,
  CustomDeck,
  UserServerForUpdate,
  RetroSummaryHistory,
  TeamSummaryHistory,
} from '@we-agile-you/types-planning-poker';

import { auth, firestore, storage } from '@we-agile-you/firebase';

const USER_COLLECTION = 'users';
const POKER_GAMES_COLLECTION = 'poker-tables';
const RETROS_COLLECTION = 'retrospectives';

export const updateCurrentUser = (user: Partial<UserServerForUpdate>) => {
  const signedInUser = auth.currentUser;

  if (!signedInUser) {
    return Promise.reject();
  }

  return setDoc(doc(firestore, USER_COLLECTION, signedInUser.uid), user, {
    merge: true,
  });
};

export const updateUser = (uid: string, user: Partial<UserServerForUpdate>) => {
  return setDoc(doc(firestore, USER_COLLECTION, uid), user, {
    merge: true,
  });
};

export const updateCurrentUserGameHistory = (
  user: User,
  pokerTable: PokerTableSummary,
) => {
  if (!pokerTable.id) {
    return Promise.reject();
  }

  const gameHistory = user?.gameHistory
    ? user.gameHistory.filter((game) => pokerTable.id !== game.id)
    : [];

  return updateCurrentUser({
    gameHistory: [
      {
        ...pokerTable,
        name: pokerTable.name || '',
      },
      ...gameHistory,
    ],
  });
};

export const updateCurrentUserRetroHistory = (
  user: User,
  retro: RetroSummaryHistory,
) => {
  if (!retro.id) {
    return Promise.reject();
  }

  const retroHistory = user?.retroHistory
    ? user.retroHistory.filter((game) => retro.id !== game.id)
    : [];

  return updateCurrentUser({
    retroHistory: [
      {
        ...retro,
        id: retro.id || '',
        name: retro.name || '',
      },
      ...retroHistory,
    ],
  });
};
export const updateCurrentUserTeamHistory = (
  user: User,
  team: TeamSummaryHistory,
) => {
  if (!team.id) {
    return Promise.reject();
  }

  const teamHistory = user?.teamHistory
    ? user.teamHistory.filter((teamH) => team.id !== teamH.id)
    : [];

  return updateCurrentUser({
    teamHistory: [
      {
        ...team,
        id: team.id || '',
        name: team.name || '',
        lastJoinedAtMilis: Date.now(),
      },
      ...teamHistory,
    ],
  });
};

/**
 *  Called after completely signup
 * @param user new user attierbutes to update
 * @param firebase set another firebase project namespace
 */
export const updateCurrentUserWhenRegistered = (user: Partial<UserServer>) => {
  return updateCurrentUser({
    ...user,
    registeredAt: serverTimestamp(),
  });
};

export const updateHasDoneRoomTour = (
  currentMetadata: UserMetadata,
  hasDoneRoomTour: boolean,
) => {
  const metadata = Object.assign({}, currentMetadata, {
    hasDoneRoomTour,
  });

  return updateCurrentUser({
    metadata,
  });
};

export const getUser = (uid: string) => {
  return getDoc(doc(firestore, USER_COLLECTION, uid))
    .then((doc) => doc.data())
    .then(
      (user) =>
        user &&
        ({
          ...user,
          registeredAt: user.registeredAt?.toDate(),
          subscriptionStartDate: user.subscriptionStartDate?.toDate(),
          subscriptionCurrentPeriodEnd:
            user.subscriptionCurrentPeriodEnd?.toDate(),
          uid: uid,
        } as User),
    );
};
export const subscribeToUser = (
  uid: string,
  onUserChanged: (
    user: User,
    isPremium: boolean,
    isFacilitator: boolean,
    isTaxExempt: boolean,
  ) => void,
) => {
  const docRef = doc(firestore, USER_COLLECTION, uid);

  return onSnapshot(docRef, (docSnapshot) => {
    const data = docSnapshot.data();
    const user: UserServer = data ? data : {};

    let isPremium = false;
    let isFacilitator = false;
    const isTaxExempt =
      !user.customerTaxExempt ||
      user.customerTaxExempt === 'exempt' ||
      user.customerTaxExempt === 'reverse';

    if (user.subscriptionCurrentPeriodEnd) {
      const nowMilis = Date.now();
      const subscriptionEndMilis = user.subscriptionCurrentPeriodEnd.toMillis();

      if (nowMilis < subscriptionEndMilis) {
        if (user.subscriptionId) {
          isPremium = true;
        }

        if (user.isFacilitator || !user.facilitators) {
          isFacilitator = true;
        }
      }
    }

    onUserChanged(
      {
        ...user,
        registeredAt: user.registeredAt?.toDate(),
        subscriptionStartDate: user.subscriptionStartDate?.toDate(),
        lastShowCards: user.lastShowCards?.toDate(),
        subscriptionCurrentPeriodEnd:
          user.subscriptionCurrentPeriodEnd?.toDate(),
        uid,
      },
      isPremium,
      isFacilitator,
      isTaxExempt,
    );
  });
};

export const getCurrentUserGamesCreated = () => {
  const signedInUser = auth.currentUser;

  if (!signedInUser) {
    return Promise.reject();
  }

  const q = query(
    collection(firestore, POKER_GAMES_COLLECTION),
    where('ownerId', '==', signedInUser.uid),
    orderBy('createdAt', 'desc'),
  );

  return getDocs(q).then((querySnapshot) => {
    const games: PokerTableSummary[] = [];

    querySnapshot.forEach((doc) => {
      const data = doc.data();

      games.push({
        id: doc.id || '',
        name: data.name || '',
        createdAt: new Date((data.createdAt?.seconds || 0) * 1000),
      });
    });

    return games;
  });
};
export const getCurrentUserRetrosCreated = () => {
  const signedInUser = auth.currentUser;

  if (!signedInUser) {
    return Promise.reject();
  }

  const q = query(
    collection(firestore, RETROS_COLLECTION),
    where('ownerId', '==', signedInUser.uid),
    orderBy('createdAt', 'desc'),
  );

  return getDocs(q).then((querySnapshot) => {
    const games: PokerTableSummary[] = [];

    querySnapshot.forEach((doc) => {
      const data = doc.data();

      games.push({
        id: doc.id || '',
        name: data.name || '',
        createdAt: new Date((data.createdAt?.seconds || 0) * 1000),
      });
    });

    return games;
  });
};

/**
 *  Adds 1 voting to the user's voting count
 * @param firebase set another firebase project namespace
 */
export const updatecurrentUserVotingCount = () => {
  const signedInUser = auth.currentUser;

  if (!signedInUser) {
    return Promise.reject();
  }

  return updateCurrentUser({
    votingCount: increment(1),
  });
};

export const updateCurrentUserFirstPageView = (userKey: keyof User) => {
  return updateCurrentUser({
    [userKey]: serverTimestamp(),
  });
};

export const addCustomDeckToUser = async (user: User, deck: CustomDeck) => {
  if (!user.uid) {
    throw new Error('No user id');
  }

  if (user.customDecks && user.customDecks.length >= 20) {
    throw new Error("Sorry, you can't create more than 20 custom decks");
  }

  return updateUser(user.uid, {
    customDecks: user.customDecks ? [...user.customDecks, deck] : [deck],
  });
};

export const removeDeckFromUser = async (user: User, deckIndex: number) => {
  if (!user.uid) {
    throw new Error('No user id');
  }

  if (
    !user.customDecks ||
    !user.customDecks.length ||
    deckIndex < 0 ||
    deckIndex > user.customDecks.length - 1
  ) {
    throw new Error('Sorry, deck not found');
  }

  return updateUser(user.uid, {
    customDecks: [
      ...user.customDecks.slice(0, deckIndex),
      ...user.customDecks.slice(deckIndex + 1),
    ],
  });
};

export const updateCurrentUserPicture = async (
  image: Blob | null,
  onComplete: () => void,
  onProgress?: (progress: number) => void,
) => {
  const signedInUser = auth.currentUser;

  if (!signedInUser?.uid) {
    throw new Error('No user signed in');
  }

  // Get a reference to the storage service, which is used to create references in your storage bucket
  const profileImageRef = ref(
    storage,
    `users/${signedInUser.uid}/profilePicture.jpg`,
  );

  if (image) {
    const uploadTask = uploadBytesResumable(profileImageRef, image);
    // Register three observers:
    // 1. 'state_changed' observer, called any time the state changes
    // 2. Error observer, called on failure
    // 3. Completion observer, called on successful completion
    uploadTask.on(
      'state_changed',
      (snapshot) => {
        // Observe state change events such as progress, pause, and resume
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        if (onProgress) {
          onProgress(progress);
        }

        switch (snapshot.state) {
          case 'paused': // or 'paused'
            console.log('Upload is paused');
            break;
          case 'running': // or 'running'
            console.log('Upload is running');
            break;
        }
      },
      (error) => {
        throw error;
      },
      () => {
        // Handle successful uploads on complete
        getDownloadURL(uploadTask.snapshot.ref)
          .then((profilePictureUrl) => updateCurrentUser({ profilePictureUrl }))
          .then(() => {
            onComplete();
          });
      },
    );
  } else {
    await deleteObject(profileImageRef);
    await updateCurrentUser({ profilePictureUrl: null });
  }
};

export const markLastUpdatesAsRead = async (updatesIds: string[]) => {
  return await updateCurrentUser({
    readUpdates: arrayUnion(...updatesIds),
  });
};
