import React from 'react';

import { IssueCard } from './IssueCard/IssueCard';
import styles from './IssueListSidebar.module.scss';
import { Container } from 'react-smooth-dnd';
import { Issue } from '../../../types';
import { useGameContext } from '../../hooks/useGameContext';
import { useAuthContext } from '../../../auth/useAuthContext';
import { updateGame } from '../../data/actions';

interface IssueListSidebarProps {
  issuesContainerEnd: React.RefObject<HTMLDivElement>;
  errorMessage: React.ReactNode;
}

export const applyDrag = (arr: Issue[], dragResult: any) => {
  if (!dragResult) return arr;

  const { removedIndex, addedIndex, payload } = dragResult;
  if (removedIndex === null && addedIndex === null) return arr;

  const result = [...arr];
  let itemToAdd = payload;

  if (removedIndex !== null) {
    itemToAdd = result.splice(removedIndex, 1)[0];
  }

  if (addedIndex !== null) {
    result.splice(addedIndex, 0, itemToAdd);
  }

  return result;
};

export const IssueListSidebar = ({
  issuesContainerEnd,
  errorMessage,
}: IssueListSidebarProps) => {
  const gameContext = useGameContext();
  const auth = useAuthContext();
  const issues = gameContext?.game?.issues;

  const appId = auth && auth.appId;

  return (
    <div className={styles['container']}>
      <Container
        onDrop={(e) =>
          appId &&
          issues &&
          gameContext?.game?.id &&
          updateGame(appId, gameContext.game.id, {
            issues: applyDrag(issues, e),
          })
        }
        dropPlaceholder={{
          animationDuration: 150,
          showOnTop: true,
          className: styles['drop-preview'],
        }}
        dragClass={styles['card-ghost']}
        dropClass={styles['card-ghost-drop']}
        render={(ref) => (
          <div ref={ref}>
            {errorMessage && (
              <div className={styles['error-message']}>{errorMessage}</div>
            )}
            {issues?.map(
              (issue, index) =>
                issue.key && (
                  <IssueCard key={issue.key} issue={issue} index={index} />
                ),
            )}
            <div className={styles['container-end']} ref={issuesContainerEnd} />
          </div>
        )}
      />
    </div>
  );
};
