import { Inline } from '@atlaskit/primitives';
import { Loader } from '../../../../components/Loader/Loader';
import { useAuthContext } from '../../../auth/useAuthContext';
import { DECKS, DEFAULT_DECK } from '../../../constants';
import { useFieldsContext } from '../../../jira/fields/useFieldsContext';
import { guessStoryPointsField } from '../../../jira/fields/utils';
import { CustomDeck, Game } from '../../../types';
import { createGame } from '../../data/actions';
import SettingsForm, {
  getDeckOption,
  SettingsFormValues,
} from '../SettingsForm/SettingsForm';

export const CreateGameForm = ({
  onCreated,
}: {
  onCreated: (gameId: string) => void;
}) => {
  const authContext = useAuthContext();
  const { fields } = useFieldsContext();
  const appData = authContext && authContext.appData;

  const appId = authContext && authContext.appId;

  if (!authContext.user || !fields) {
    return (
      <Inline alignInline="center">
        <Loader message="Loading form..." />
      </Inline>
    );
  }

  const decks = [
    ...DECKS,
    ...(appData && appData.customDecks ? appData.customDecks : []),
  ];

  const userLastSelectedDeck =
    typeof authContext.user.lastSelectedDeck === 'string'
      ? decks.find(
          (deck) =>
            // @ts-ignore
            deck.value === (authContext.user.lastSelectedDeck as string),
        )
      : authContext.user.lastSelectedDeck;

  const defaultDeck =
    userLastSelectedDeck &&
    decks.find(
      (deck) =>
        deck.name === userLastSelectedDeck.name &&
        deck.value === userLastSelectedDeck.value,
    )
      ? userLastSelectedDeck
      : DEFAULT_DECK;

  const defaultFormValues: Partial<SettingsFormValues> = {
    storyPointsField: guessStoryPointsField(authContext.user, fields) || null,
    deck: getDeckOption(defaultDeck),
    whoCanShowCards: authContext.user.lastSelectedWhoCanShowCards || null,
    whoCanEditIssues: authContext.user.lastSelectedWhoCanEditIssues || null,
    autoShowCards:
      typeof authContext.user.lastSelectedAutoShowCards !== 'undefined'
        ? authContext.user.lastSelectedAutoShowCards
        : false,
    enableProjectiles:
      typeof authContext.user.lastSelectedEnableProjectiles !== 'undefined'
        ? authContext.user.lastSelectedEnableProjectiles
        : true,
    showAverageInResults:
      typeof authContext.user.lastSelectedHideAverage !== 'undefined'
        ? !authContext.user.lastSelectedHideAverage
        : true,
  };

  const handleSubmit = async (game: Partial<Game>) => {
    try {
      if (!appId || !authContext.user) {
        return;
      }

      const gameId = await createGame(
        appId,
        {
          ...game,
          storyPointsField:
            game.storyPointsField || defaultFormValues.storyPointsField || null,
          deck: game.deck || defaultFormValues.deck?.deck,
        },
        authContext.user,
      );

      if (onCreated) {
        onCreated(gameId);
      }
    } catch (error: any) {
      console.error(error);

      return {
        name: `Unexpected error: ${error?.message ? error.message : ''}`,
      };
    }
  };

  return (
    <SettingsForm
      onSubmit={handleSubmit}
      formMode="new-game"
      defaultFormValues={defaultFormValues}
    />
  );
};
