import {
  setDoc,
  doc,
  onSnapshot,
  QueryDocumentSnapshot,
  FieldPath,
  updateDoc,
} from 'firebase/firestore';
import { auth, firestore } from '../../firebase';
import { JIRA_CONNECT_APPS_DATA_COLLECTION } from '../constants';
import { User, UserServer } from '../types';

const converter = {
  toFirestore: (data: User) => data,
  fromFirestore: (snap: QueryDocumentSnapshot) => {
    const userFirebase = snap.data() as UserServer;

    const user: User = { uid: snap.id, ...userFirebase };

    return user;
  },
};

export const subscribeToCurrentUser = (
  appId: string,
  onUserChanged: (user: User) => void,
) => {
  const signedInUser = auth.currentUser;

  if (!signedInUser) {
    throw new Error('No signed in user found.');
  }

  const docRef = doc(
    firestore,
    `${JIRA_CONNECT_APPS_DATA_COLLECTION}/${appId}/users`,
    signedInUser.uid,
  ).withConverter(converter);

  return onSnapshot(docRef, (docSnapshot) => {
    const user = docSnapshot.data();

    if (!user) {
      return;
    }

    onUserChanged({
      ...user,
    });
  });
};

export const updateCurrentUser = (appId: string, user: Partial<User>) => {
  const signedInUser = auth.currentUser;

  if (!signedInUser) {
    return Promise.reject();
  }

  return updateDoc(
    doc(
      firestore,
      `${JIRA_CONNECT_APPS_DATA_COLLECTION}/${appId}/users`,
      signedInUser.uid,
    ).withConverter(converter),
    user,
  );
};
