import { createContext } from "react";
import { GeoLocalization } from "./types";

export type GeoLocalizationContextType = {
  geoLocalization: GeoLocalization | null;
};

export const GeoLocalizationContext = createContext<GeoLocalizationContextType>(
  {
    geoLocalization: null,
  }
);
