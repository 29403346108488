import React from 'react';

export const Sidebar = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 3C2.34315 3 1 4.34315 1 6V18C1 19.6569 2.34315 21 4 21H20C21.6569 21 23 19.6569 23 18V6C23 4.34315 21.6569 3 20 3H4ZM3 6C3 5.44772 3.44772 5 4 5H15V19H4C3.44772 19 3 18.5523 3 18V6Z"
      fill="currentColor"
    />
  </svg>
);
