import { useEffect, useState } from 'react';
import { useGameContext } from '../../hooks/useGameContext';
import { useAuthContext } from '../../../auth/useAuthContext';
import { Spotlight, SpotlightTransition } from '@atlaskit/onboarding';
import { useOneTimeUserAction } from '../../../auth/useOneTimeUserAction';
import { usePrevious } from '@we-agile-you/react-base';

export const StartNewGameTooltip = () => {
  const [isDone, setIsDone] = useOneTimeUserAction('startNewGameTooltip');
  const [hasVoted, setHasVoted] = useState(false);
  const gameContext = useGameContext();
  const auth = useAuthContext();
  const game = gameContext?.game;
  const user = auth.user;

  const isLoading = !game || !user;
  const isOwner = game?.ownerId === user?.uid;
  const showCards = !!gameContext?.game?.cardsUp;
  const previousShowCards = usePrevious(showCards);

  const isOpen = !isLoading && isDone === false && isOwner && showCards;

  useEffect(() => {
    if (isDone === false && isOwner && hasVoted) {
      setIsDone();
    }
  }, [hasVoted, isDone, isOwner, setIsDone, showCards]);

  useEffect(() => {
    if (!showCards && previousShowCards) {
      setHasVoted(true);
    }
  }, [showCards, previousShowCards]);

  return (
    <SpotlightTransition>
      {isOpen && (
        <Spotlight
          actions={[
            {
              onClick: setIsDone,
              text: 'OK',
            },
          ]}
          target="table"
          key="start-new-voting"
          targetOnClick={setIsDone}
          targetRadius={30}
          dialogPlacement="right middle"
          targetBgColor="transparent"
          heading="Discuss the results and start a new voting"
        >
          Click on “start new voting” to reset cards and start again
        </Spotlight>
      )}
    </SpotlightTransition>
  );
};
