import React from 'react';

export const VotingHistory = () => (
  <svg
    width="22"
    height="20"
    viewBox="0 0 22 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 2C8.74797 2 5.94698 3.94063 4.69605 6.73074L6.14461 6.33274C6.67716 6.18642 7.22749 6.49952 7.37381 7.03207C7.52013 7.56462 7.20703 8.11495 6.67448 8.26127L2.81742 9.32102C2.56167 9.39129 2.28848 9.35708 2.05795 9.22592C1.82743 9.09475 1.65845 8.87738 1.5882 8.62162L0.528662 4.76421C0.38238 4.23165 0.695521 3.68134 1.22808 3.53506C1.76064 3.38878 2.31095 3.70192 2.45723 4.23448L2.90038 5.84781C4.47641 2.39916 7.95693 0 12 0C17.5228 0 22 4.47715 22 10C22 15.5228 17.5228 20 12 20C7.64437 20 3.94159 17.2159 2.56927 13.3332C2.38523 12.8125 2.65815 12.2412 3.17887 12.0572C3.69958 11.8731 4.27091 12.146 4.45495 12.6668C5.55366 15.7753 8.51831 18 12 18C16.4183 18 20 14.4183 20 10C20 5.58172 16.4183 2 12 2ZM13 5C13 4.44772 12.5523 4 12 4C11.4477 4 11 4.44772 11 5V10V10.5352L11.4453 10.8321L14.4453 12.8321C14.9048 13.1384 15.5257 13.0142 15.832 12.5547C16.1384 12.0952 16.0142 11.4743 15.5547 11.1679L13 9.46482V5Z"
      fill="currentColor"
    />
  </svg>
);
