import { useState, useRef } from 'react';
import { ButtonDropdown } from '../../../../components/ButtonDropdown/ButtonDropdown';

import Form, { ErrorMessage, Field, FormFooter } from '@atlaskit/form';

import ButtonGroup from '@atlaskit/button/button-group';
import LoadingButton from '@atlaskit/button/loading-button';
import FeedbackIcon from '@atlaskit/icon/glyph/feedback';
import TextField from '@atlaskit/textfield';
import TextArea from '@atlaskit/textarea';
import SectionMessage from '@atlaskit/section-message';

import styles from './FeedbackButton.module.scss';
import { useAuthContext } from '../../../auth/useAuthContext';
import { createContactMessage } from './data';
import { Stack } from '@atlaskit/primitives';
import Button from '@atlaskit/button';
import { getObjectWithoutUndefinedValues } from '@we-agile-you/js-base';

export type ContactData = {
  name: string;
  email: string;
  message: string;
  app: 'jira-plugin';
};

export const FeedbackButton = ({
  isTableButton,
}: {
  isTableButton?: boolean;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isSuccess, setIsSuccess] = useState<boolean | null>(null);
  const { user } = useAuthContext();

  const handleSubmit = async (values: ContactData) => {
    if (!values.message) {
      alert('please write a message');

      return;
    }

    try {
      await createContactMessage({
        ...getObjectWithoutUndefinedValues(values),
        app: 'jira-plugin',
      });
      setIsSuccess(true);
    } catch (error) {
      console.error(error);
      setIsSuccess(false);
    }
  };

  const handleClose = () => {
    setIsOpen(false);
    setIsSuccess(null);
  };

  const renderDropdown = () => {
    if (isSuccess) {
      return (
        <Stack alignInline="center" space="space.400">
          <div />
          <div style={{ width: '100%', boxSizing: 'border-box' }}>
            <SectionMessage appearance="success">
              <p>Success! We'll get back to you soon.</p>
            </SectionMessage>
          </div>
          <div>
            <Button onClick={handleClose}>Close</Button>
          </div>
        </Stack>
      );
    }

    if (isSuccess === false) {
      return (
        <Stack alignInline="center" space="space.400">
          <div />
          <div style={{ width: '100%', boxSizing: 'border-box' }}>
            <SectionMessage appearance="error">
              <p>
                Unexpected error sending your message. Please send it via email
                to info@weagileyou.com
              </p>
            </SectionMessage>
          </div>
          <div>
            <Button onClick={handleClose}>Close</Button>
          </div>
        </Stack>
      );
    }

    return (
      <Form<ContactData> onSubmit={handleSubmit}>
        {({ formProps, submitting }) => (
          <form {...formProps}>
            <Field
              name="name"
              label="Name"
              defaultValue={user?.displayName || ''}
            >
              {({ fieldProps, error }) => (
                <>
                  <TextField
                    maxLength={60}
                    placeholder="Your name (optional)"
                    {...fieldProps}
                  />
                  {error && <ErrorMessage>{error}</ErrorMessage>}
                </>
              )}
            </Field>
            <Field name="email" label="Email">
              {({ fieldProps, error }) => (
                <>
                  <TextField
                    type="email"
                    placeholder="Your email (optional if you want to be contacted back)"
                    {...fieldProps}
                  />
                  {error && <ErrorMessage>{error}</ErrorMessage>}
                </>
              )}
            </Field>
            <Field
              label="Message"
              name="message"
              aria-required={true}
              isRequired
            >
              {({ fieldProps }: any) => (
                <>
                  <TextArea
                    placeholder="Type your message for us.. ✍️"
                    resize="vertical"
                    maxHeight={250}
                    minimumRows={5}
                    autoFocus
                    {...fieldProps}
                  />
                </>
              )}
            </Field>
            <FormFooter>
              <ButtonGroup>
                <LoadingButton
                  type="submit"
                  appearance="primary"
                  isLoading={submitting}
                >
                  Send message
                </LoadingButton>
              </ButtonGroup>
            </FormFooter>
          </form>
        )}
      </Form>
    );
  };

  return (
    <ButtonDropdown
      buttonComponent="icon"
      dropdownAlign={isTableButton ? 'right-bottom' : 'bottom-left'}
      isOpen={isOpen}
      onIsOpenChange={() => (isOpen ? handleClose() : setIsOpen(true))}
      translate="no"
      buttonTooltip="Contact us"
      icon={FeedbackIcon}
      dropdown={<div className={styles['dropdown']}>{renderDropdown()}</div>}
    ></ButtonDropdown>
  );
};
