import { ReactNode } from 'react';
import { VerticalSpacing } from '../spacings/VerticalSpacing/VerticalSpacing';

import styles from './Loader.module.scss';

interface LoaderProps {
  message?: ReactNode;
}

export const Loader = ({ message }: LoaderProps) => {
  return (
    <div className={styles['loader']}>
      <div className={styles['robot-container']}>
        <div className={styles['robot']} />
      </div>
      <VerticalSpacing spacing="spacing-s" />
      <p>{message}</p>
    </div>
  );
};
