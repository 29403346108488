import { getMonthWithDayAndYearAndTimeInLocalTime } from '@we-agile-you/js-base';
import { useContext, useState, useEffect, Ref } from 'react';
import { AuthContext } from '../../../auth/AuthContext';
import { useCurrentAppGames } from '../../hooks/useCurrentAppGames';
import DynamicTable from '@atlaskit/dynamic-table';
import { RowType } from '@atlaskit/dynamic-table/dist/types/types';
import { Inline, Stack } from '@atlaskit/primitives';
import Button, { IconButton } from '@atlaskit/button/new';
import ButtonOld from '@atlaskit/button';
import MoreIcon from '@atlaskit/icon/glyph/more';
import SettingsIcon from '@atlaskit/icon/glyph/settings';
import ghost from './ghost.svg';
import DropdownMenu, {
  DropdownItem,
  DropdownItemGroup,
} from '@atlaskit/dropdown-menu';
import { Loader } from '../../../../components/Loader/Loader';
import Heading from '@atlaskit/heading';
import { Container } from '../../../../components/Container/Container';
import Avatar from '@atlaskit/avatar';
import { Link } from '../../../../components/Link/Link';
import { FeedbackButton } from '../../components/FeedbackButton/FeedbackButton';
import { N0 } from '@atlaskit/theme/colors';
import Modal, {
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  ModalTransition,
} from '@atlaskit/modal-dialog';

import {
  Spotlight,
  SpotlightPulse,
  SpotlightTarget,
  SpotlightManager,
  SpotlightTransition,
} from '@atlaskit/onboarding';
import { useOneTimeUserAction } from '../../../auth/useOneTimeUserAction';
import { deleteGame } from '../../data/actions';
import { createPortal } from 'react-dom';
import { ManageDecksModal } from '../../components/ManageDecksModal/ManageDecksModal';

const head = {
  cells: [
    {
      key: 'name',
      content: 'Name',
      isSortable: true,
    },
    {
      key: 'createdAt',
      content: 'Created at',
      isSortable: true,
    },
    {
      key: 'creator',
      content: 'Creator',
      isSortable: true,
    },
    {
      key: 'actions',
      content: '',
      isSortable: false,
    },
  ],
};

export const DashboardTemplate = () => {
  const auth = useContext(AuthContext);
  const appId = auth && auth.appId;
  const games = useCurrentAppGames(appId);
  const [isSpotlightActive, setIsSpotlightActive] = useState(false);
  const [isManageDecksModalOpen, setIsManageDecksModalOpen] = useState(false);

  const [isConfirmDeleteOpen, setIsConfirmDeleteOpen] = useState<
    false | string
  >(false);
  const uid = auth.uid;

  const [isDone, setIsDone] = useOneTimeUserAction('createGameTooltip');
  const gamesLength = games && games.length;

  useEffect(() => {
    if (isDone === false && gamesLength === 0) {
      setIsSpotlightActive(true);
    }
  }, [isDone, gamesLength]);

  const handleDelete = (gameId: string) => {
    setIsConfirmDeleteOpen(false);
    deleteGame(appId, gameId);
  };

  const rows: RowType[] | null = games
    ? games.map((game, index) => ({
        key: `row-${index}-${game.name}`,
        cells: [
          {
            key: game.name?.replace(/\s/g, '').toLowerCase() || '',
            content: (
              <Link href={`game/${game.id}`}>
                <ButtonOld appearance="link">{game.name || `Game`}</ButtonOld>
              </Link>
            ),
          },
          {
            key: game.createdAt?.getTime().toString() || '',
            content: game.createdAt
              ? getMonthWithDayAndYearAndTimeInLocalTime(game.createdAt)
              : '',
          },
          {
            key: game.ownerName?.replace(/\s/g, '').toLowerCase() || '',
            content: (
              <Inline space="space.100" alignBlock="center">
                <Avatar
                  size="small"
                  src={game.ownerAvatar}
                  name={game.ownerName}
                />
                <span>{game.ownerName}</span>
              </Inline>
            ),
          },
          {
            key: `row-${index}-actions`,
            content: (
              <DropdownMenu
                trigger={({ triggerRef, ...props }) => (
                  <IconButton
                    {...props}
                    icon={MoreIcon}
                    label="Actions"
                    appearance="subtle"
                    ref={triggerRef as Ref<HTMLButtonElement> | undefined}
                  />
                )}
              >
                <DropdownItemGroup>
                  <DropdownItem
                    isDisabled={game.ownerId !== uid}
                    onClick={() => setIsConfirmDeleteOpen(game.id)}
                    description={
                      game.ownerId !== uid
                        ? 'You can only delete the games created by you'
                        : undefined
                    }
                  >
                    Delete game
                  </DropdownItem>
                </DropdownItemGroup>
              </DropdownMenu>
            ),
          },
        ],
      }))
    : null;

  const renderGames = () => {
    if (!rows) {
      return <Loader message="Loading games..." />;
    }

    if (rows.length === 0) {
      return (
        <Stack space="space.300" alignInline="center">
          <div />
          <div />
          <div />
          <Stack space="space.100" alignInline="center">
            <div>
              <strong>No games yet</strong>
            </div>
            <div>Create a new game to get started!</div>
          </Stack>
          <Stack>
            <img src={ghost} alt="ghost" />
            <img style={{ opacity: 0.5 }} src={ghost} alt="ghost" />
            <img style={{ opacity: 0.2 }} src={ghost} alt="ghost" />
          </Stack>
        </Stack>
      );
    }

    return (
      <DynamicTable
        head={head}
        rows={rows}
        rowsPerPage={20}
        defaultPage={1}
        defaultSortKey="createdAt"
        defaultSortOrder="DESC"
      />
    );
  };

  return (
    <SpotlightManager>
      <Container>
        <Stack space="space.300">
          <Inline alignInline="end" spread="space-between" alignBlock="center">
            <Heading level="h900">Games</Heading>
            <Inline space="space.200">
              <FeedbackButton />
              <DropdownMenu<HTMLButtonElement>
                trigger={({ triggerRef, ...props }) => (
                  <IconButton
                    {...props}
                    icon={SettingsIcon}
                    label="settings"
                    ref={triggerRef}
                    shape="circle"
                  />
                )}
              >
                <DropdownItemGroup>
                  <DropdownItem onClick={() => setIsManageDecksModalOpen(true)}>
                    Manage decks
                  </DropdownItem>
                </DropdownItemGroup>
              </DropdownMenu>
              {gamesLength === 0 ? (
                <SpotlightTarget name="new">
                  <SpotlightPulse pulse={gamesLength === 0} radius={3}>
                    <Link href="new-game">
                      <Button appearance="primary">Create Game</Button>
                    </Link>
                  </SpotlightPulse>
                </SpotlightTarget>
              ) : (
                <Link href="new-game">
                  <Button appearance="primary">Create Game</Button>
                </Link>
              )}
            </Inline>
          </Inline>

          {renderGames()}
        </Stack>
        <SpotlightTransition>
          {isSpotlightActive && (
            <Spotlight
              actions={[
                {
                  onClick: () => {
                    setIsDone();
                    setIsSpotlightActive(false);
                  },
                  text: 'OK',
                },
              ]}
              heading="Welcome to Planning Poker Online!"
              target="new"
              key="new-game-button"
              targetRadius={3}
              targetBgColor={N0}
            >
              Create your first game by clicking here!
            </Spotlight>
          )}
        </SpotlightTransition>
      </Container>
      <ModalTransition>
        {isConfirmDeleteOpen && (
          <Modal onClose={() => setIsConfirmDeleteOpen(false)}>
            <ModalHeader>
              <ModalTitle appearance="danger">
                Delete game{' '}
                {games?.find((game) => game.id === isConfirmDeleteOpen)?.name ||
                  ''}
              </ModalTitle>
            </ModalHeader>
            <ModalBody>
              <p>Are you sure you want to delete the game?</p>
              <p>This action is irreversible.</p>
            </ModalBody>
            <ModalFooter>
              <Button
                appearance="subtle"
                onClick={() => setIsConfirmDeleteOpen(false)}
              >
                Cancel
              </Button>
              <Button
                appearance="danger"
                onClick={() => handleDelete(isConfirmDeleteOpen)}
              >
                Delete game
              </Button>
            </ModalFooter>
          </Modal>
        )}
      </ModalTransition>

      {isManageDecksModalOpen &&
        createPortal(
          <ManageDecksModal onClose={() => setIsManageDecksModalOpen(false)} />,
          document.body,
        )}
    </SpotlightManager>
  );
};
