import { Projectile } from '@we-agile-you/types-planning-poker';
import { useState } from 'react';

export const useProjectiles = () => {
  const [projectiles, setProjectiles] = useState<Projectile[]>([]);

  const handleProjectilesChange = (newProjectiles: Projectile[]) => {
    setProjectiles((existingProjectiles) => {
      const missingProjectiles = newProjectiles.filter(
        (newProjectile) =>
          !existingProjectiles.find(
            (existingProjectile) => existingProjectile.id === newProjectile.id,
          ),
      );

      return [...existingProjectiles, ...missingProjectiles];
    });
  };

  const handleProjectileThrowComplete = (projectileId: string) => {
    setProjectiles((existingProjectiles) =>
      existingProjectiles.map((existingProjectile) =>
        existingProjectile.id === projectileId
          ? { ...existingProjectile, isThrown: true }
          : existingProjectile,
      ),
    );
  };

  return {
    projectiles: projectiles.filter((projectile) => !projectile.isThrown),
    handleProjectilesChange,
    handleProjectileThrowComplete,
  };
};
