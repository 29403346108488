import { Field } from '@atlaskit/form';
import Select from '@atlaskit/select';
import { Project } from '../../../../../../jira/api';
import styles from '../Pickers.module.scss';

export const ProjectPicker = ({
  projects,
  onChange,
  projectId,
}: {
  projects: Project[] | null;
  onChange: (project: Project | null) => void;
  projectId?: string | null;
}) => {
  const projectOptions = projects?.map((project) => ({
    value: project,
    label: project.name,
  }));

  return (
    <div className={styles['filter-select']}>
      <Field aria-required={true} name="project" label="Project">
        {() => (
          <Select
            placeholder="Project"
            options={projectOptions || []}
            isLoading={!projectOptions}
            value={
              projectOptions?.find((option) => option.value.id === projectId) ||
              null
            }
            onChange={(value) => onChange(value?.value || null)}
            isClearable
          />
        )}
      </Field>
    </div>
  );
};
