import { AuthContext } from './spaces/auth/AuthContext';
import { NavigationContext } from './spaces/navigation/NavigationContext';
import { Router } from './spaces/navigation/Router';
import { useNavigation } from './spaces/navigation/useNavigaton';
import styles from './App.module.scss';
import { Loader } from './components/Loader/Loader';
import InlineMessage from '@atlaskit/inline-message';
import { useSignIn } from './spaces/auth/useSignin';
import { useSubscribeToCurrentUser } from './spaces/auth/useSubscribeToCurrentUser';
import { useSubscribeToAppData } from './spaces/auth/useSubscribeToAppData';
import { useSubscribeToAppUsers } from './spaces/auth/useSubscribeToAppUsers';
import { Stack } from '@atlaskit/primitives';

function App() {
  const navigation = useNavigation();
  const auth = useSignIn();
  const user = useSubscribeToCurrentUser(auth);
  const appData = useSubscribeToAppData(auth);
  const appUsers = useSubscribeToAppUsers(user, auth);

  if (auth === null) {
    return (
      <div className={styles['loading-container']}>
        <Loader message="Loading app..." />{' '}
      </div>
    );
  }

  if (!auth.isAuthenticated) {
    const errorMessage = auth.error?.message || 'An unexpected error occurred';

    if (errorMessage === 'NOT_LICENSED') {
      return (
        <Stack alignInline="center" space="space.500">
          <div />
          <div />
          <InlineMessage
            title="Not licensed"
            secondaryText="Please activate/renew app's license."
          >
            <p>This app requires an active license.</p>
          </InlineMessage>
          <div />
        </Stack>
      );
    }

    return (
      <InlineMessage
        title="Auth error"
        secondaryText="There was an unexpected authentication error"
      >
        {errorMessage}
      </InlineMessage>
    );
  }

  return (
    <AuthContext.Provider value={{ ...auth, user, appData, appUsers }}>
      <NavigationContext.Provider value={navigation}>
        <Router />
      </NavigationContext.Provider>
    </AuthContext.Provider>
  );
}

export default App;
