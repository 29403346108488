import {
  IssueApi,
  IssueApiField,
  IssueJira,
  User,
  WithDisplayNameField,
  WithNameField,
} from '../types';
import { getCurrentJiraUserAccountId } from './actions';
import { ROWS_PER_PAGE } from './contants';
import * as Sentry from '@sentry/react';

type RequestOptions = {
  type: 'GET' | 'POST' | 'PUT';
  bodyString?: string;
};

const UNEXPECTED_API_ERROR_MESSAGE = 'UNEXPECTED_API_ERROR_MESSAGE';

export const requestApi = async <T>(
  path: string,
  options?: RequestOptions,
): Promise<T> => {
  try {
    const response: string = (
      await window.AP.request(
        path,
        options && {
          ...options,
          data: options.bodyString,
          contentType: 'application/json',
        },
      )
    ).body;

    if (options?.type !== 'PUT') {
      const data = JSON.parse(response);

      return data;
    }

    return null as T;
  } catch (e: any) {
    const errors = e.err && JSON.parse(e.err);

    if (errors?.errorMessages?.length) {
      throw new Error(errors.errorMessages[0]);
    }

    if (errors?.errors && Object.keys(errors.errors).length) {
      const key = Object.keys(errors.errors)[0];

      throw new Error(`${errors.errors[key]} (${key})`);
    }

    throw new Error(UNEXPECTED_API_ERROR_MESSAGE);
  }
};

type PaginatedResponse<T> = {
  isLast: boolean;
  values: T[];
};
const MAX_AUTO_PAGINATION = 5;

const requestApiPaginated = async <T>(path: string): Promise<T[]> => {
  const requestPaginated = async (
    path: string,
    startAt = 0,
    values: T[] = [],
    i = 0,
  ): Promise<T[]> => {
    const data = await requestApi<PaginatedResponse<T>>(path, {
      type: 'GET',
      bodyString: `{
        "startAt": ${startAt}
      }`,
    });

    if (!data.isLast && i < MAX_AUTO_PAGINATION) {
      return await requestPaginated(
        path,
        startAt + data.values.length,
        [...values, ...data.values],
        i + 1,
      );
    }

    return [...values, ...data.values];
  };

  return requestPaginated(path);
};

const resolveStoryPointsValue = (
  issueApi: IssueApi,
  storyPointsField: Field | null,
) => {
  if (!storyPointsField?.id || !issueApi?.fields) {
    return null;
  }

  const field = issueApi.fields[
    storyPointsField.id as keyof IssueApiField
  ] as IssueApiField;

  if (typeof field === 'undefined' || field === null) {
    return null;
  }

  if (typeof field === 'string') {
    return field;
  }

  if (typeof field === 'number') {
    return field.toString();
  }

  if (typeof (field as WithNameField).name === 'string') {
    return (field as WithNameField).name;
  }

  if (typeof (field as WithDisplayNameField).displayName === 'string') {
    return (field as WithDisplayNameField).displayName;
  }

  return null;
};

const mapIssueApiToIssue = (
  issueApi: IssueApi,
  storyPointsField: Field | null,
): IssueJira => {
  const storyPoints = resolveStoryPointsValue(issueApi, storyPointsField);

  const issue: IssueJira = {
    id: issueApi.id,
    key: issueApi.key,
    summary: issueApi.fields.summary,
    storyPoints: storyPoints || null,
    epic:
      issueApi.fields.parent?.fields.issuetype.name === 'Epic'
        ? {
            name: issueApi.fields.parent.fields.summary,
            color: '',
            key: issueApi.fields.parent.key,
          }
        : null,
    assignee: issueApi.fields.assignee
      ? {
          displayName: issueApi.fields.assignee?.displayName,
          avatar: issueApi.fields.assignee?.avatarUrls['24x24'],
        }
      : null,
    creator: issueApi.fields.creator
      ? {
          displayName: issueApi.fields.creator.displayName,
          avatar: issueApi.fields.creator.avatarUrls['24x24'],
        }
      : null,
  };

  if (issueApi.fields.issuetype) {
    issue.issueType = {
      name: issueApi.fields.issuetype.name,
      iconUrl: issueApi.fields.issuetype.iconUrl,
    };
  }

  return issue;
};

export type ProjectApi = {
  id: string;
  key: string;
  name: string;
  style: 'classic' | 'next-gen';
  archived?: boolean;
  deleted?: boolean;
};

export type Project = {
  id: string;
  key: string;
  name: string;
  style: 'classic' | 'next-gen';
};

export const getProjects = async () => {
  const projects = await requestApiPaginated<ProjectApi>(
    '/rest/api/3/project/search',
  );

  return projects.filter((project) => !project.archived && !project.deleted); // Not tested (premium jira required)
};

export const getProject = (projectId: string) => {
  return requestApi<ProjectApi>(`/rest/api/3/project/${projectId}`);
};

export type UserJira = {
  displayName: string;
  accountId: string;
  avatarUrls: {
    '16x16': string;
    '24x24': string;
    '32x32': string;
    '48x48': string;
  };
  timeZone: string;
};

export const getUserJira = async () => {
  const accountId = await getCurrentJiraUserAccountId();

  return requestApi<UserJira>(`/rest/api/3/user?accountId=${accountId}`);
};

export type IssueType = {
  id: string;
  name: string;
  description: string;
};

export const getIssueTypes = () => {
  return requestApi<IssueType[]>('/rest/api/3/issuetype');
};

export type Status = {
  id: string;
  key: string;
  name: string;
};

export const getStatuses = async (projectId: string) => {
  return requestApiPaginated<Status>(
    `/rest/api/3/statuses/search?projectId=${projectId}`,
  );
};

export type Filter = {
  id: string;
  name: string;
  description: string;
  jql: string;
};

export const getFilters = async () => {
  return requestApi<Filter[]>(`/rest/api/3/filter/my?includeFavourites=true`);
};

export type BoardApi = {
  id: string;
  key: string;
  name: string;
  location: {
    projectId: number;
  };
};
export type Board = {
  id: string;
  key: string;
  name: string;
  projectId: string;
};

export const getBoards = async () => {
  const boardsApi = await requestApiPaginated<BoardApi>(
    `/rest/agile/1.0/board`,
  );

  const boards: Board[] = boardsApi.map((boardApi) => {
    const board: Board = {
      id: boardApi.id,
      key: boardApi.key,
      name: boardApi.key,
      projectId: boardApi?.location?.projectId?.toString(),
    };

    return board;
  });

  return boards;
};

export type SprintApi = {
  id: string;
  state: 'future';
  name: string;
  startDate: string;
  endDate: string;
  completeDate: string;
  goal: string;
};
export type Sprint = {
  id: string;
  state: 'future';
  name: string;
  startDate: Date;
  endDate: Date;
  completeDate: Date;
  goal: string;
};

export const getSprints = async (boardId: string) => {
  const sprints = await requestApi<{ values: SprintApi[] }>(
    `/rest/agile/1.0/board/${boardId}/sprint`,
    {
      type: 'GET',
      bodyString: `{
        "state": "feature"
      }`,
    },
  );

  const mappedSprints: Sprint[] = sprints.values.map((sprintApi) => {
    const sprint: Sprint = {
      id: sprintApi.id,
      state: sprintApi.state,
      name: sprintApi.name,
      startDate: sprintApi.startDate
        ? new Date(sprintApi.startDate)
        : new Date(),
      endDate: sprintApi.endDate ? new Date(sprintApi.endDate) : new Date(),
      completeDate: sprintApi.completeDate
        ? new Date(sprintApi.completeDate)
        : new Date(),
      goal: sprintApi.goal,
    };

    return sprint;
  });

  return mappedSprints;
};

export type EpicApi = {
  id: string;
  name: string;
  summary: string;
  color: {
    key: string;
  };
  done: boolean;
};
export type Epic = {
  id: string;
  name: string;
  summary: string;
  color: string;
  done: boolean;
};
export const getEpics = async (boardId: string) => {
  const epics = await requestApiPaginated<EpicApi>(
    `/rest/agile/1.0/board/${boardId}/epic`,
  );

  const mappedEpics: Epic[] = epics.map((epicApi) => {
    const epic: Epic = {
      id: epicApi.id,
      name: epicApi.name,
      summary: epicApi.summary,
      done: epicApi.done,
      color: epicApi.color.key,
    };

    return epic;
  });

  return mappedEpics;
};

export type SearchParams = {
  project?: Project | null;
  board?: Board | null;
  sprint?: Sprint | null;
  issueTypes?: IssueType[] | null;
  statuses?: Status[] | null;
  filter?: Filter | null;
  epics?: Epic[] | null;
};

const getJQLFromSearchParams = (searchParams: SearchParams) => {
  const jql: string[] = [];

  if (searchParams?.project?.id) {
    jql.push(`project = ${searchParams.project.id}`);
  }
  if (searchParams?.sprint?.id) {
    jql.push(`sprint = ${searchParams.sprint.id}`);
  }

  if (searchParams?.issueTypes && searchParams.issueTypes.length) {
    jql.push(
      `( ${searchParams.issueTypes
        .map((issueType) => `issuetype = '${issueType.name}'`)
        .join(' OR ')} )`,
    );
  }

  if (searchParams?.epics && searchParams.epics.length) {
    jql.push(
      `( ${searchParams.epics
        .map((epic) => `parent = ${epic.id}`)
        .join(' OR ')} )`,
    );
  }

  if (searchParams?.statuses && searchParams.statuses.length) {
    jql.push(
      `( ${searchParams.statuses
        .map((status) => `status = '${status.name}'`)
        .join(' OR ')} )`,
    );
  }

  const jqlString = searchParams?.filter
    ? searchParams.filter.jql
    : `${jql.join(' AND ')} Order by RANK`;

  return jqlString;
};

export type Field = {
  name: string;
  untranslatedName: string;
  key: string;
  id: string;
  schema: {
    type:
      | 'datetime'
      | 'issuetype'
      | 'number'
      | 'string'
      | 'project'
      | 'array'
      | 'sd-feedback'
      | 'sd-approvals'
      | 'resolution'
      | 'issuerestriction'
      | 'watches'
      | 'date'
      | 'priority'
      | 'any'
      | 'user'
      | 'status'
      | 'sd-customerrequesttype'
      | 'option'
      | 'timetracking'
      | 'securitylevel'
      | 'progress'
      | 'team'
      | 'comments-page'
      | 'votes';
  };
};

export const getFields = async () => {
  const fields = await requestApi<Field[]>(`/rest/api/3/field`);

  return fields;
};

export const searchIssues = async ({
  searchParams,
  startAt,
  jql,
  storyPointsField,
}: {
  searchParams?: SearchParams | null;
  startAt?: number | null;
  jql?: string;
  storyPointsField?: Field | null;
}): Promise<{
  issues: IssueJira[];
  nextStartAt: number | null;
  total: number;
}> => {
  const jqlResolved = jql
    ? jql
    : searchParams
      ? getJQLFromSearchParams(searchParams)
      : 'Order by RANK';

  const fields = [
    'id',
    'key',
    'summary',
    'issuetype',
    'status',
    'assignee',
    'creator',
    'parent',
  ];

  if (storyPointsField?.id) {
    fields.push(storyPointsField.id);
  }

  const body = {
    // expand: ['timetracking'],
    fields,
    // fields: ['*all'],
    // fieldsByKeys: false,
    jql: jqlResolved,
    maxResults: ROWS_PER_PAGE,
    startAt: startAt || 0,
  };

  const response = await requestApi<{
    issues?: IssueApi[];
    startAt: number;
    total: number;
  }>(`/rest/api/3/search`, {
    type: 'POST',
    bodyString: JSON.stringify(body),
  });

  const responseIssues = response.issues || [];

  const issues = responseIssues.map((issueApi: IssueApi) => {
    const mappedIssue: IssueJira = mapIssueApiToIssue(
      issueApi,
      storyPointsField || null,
    );

    return mappedIssue;
  });

  const nextStartAt =
    response.startAt + responseIssues.length < response.total
      ? response.startAt + responseIssues.length
      : null;

  return {
    issues,
    nextStartAt,
    total: response.total,
  };
};

export const getIssueByKey = async (
  key: string,
  storyPointsField: Field | null,
): Promise<IssueJira | null> => {
  const issueApi = await requestApi<IssueApi>(`/rest/api/3/issue/${key}`);

  return issueApi ? mapIssueApiToIssue(issueApi, storyPointsField) : null;
};

export const updateIssueStoryPointsInJira = async (
  issueId: string,
  storyPoints: string | number | null,
  storyPointsField: Field | null,
) => {
  if (!storyPointsField?.id) {
    throw new Error('No Story Points field found in game settings.');
  }

  const fieldType = storyPointsField.schema?.type;

  function getStoryPointsValue() {
    switch (fieldType) {
      case 'string':
        const storyPointsString =
          storyPoints === null ? null : storyPoints.toString();

        return storyPointsString;
      case 'option':
        const storyPointsOption =
          storyPoints === null ? null : storyPoints.toString();

        return { value: storyPointsOption };
      case 'number':
      default:
        if (storyPoints === null) {
          return null;
        }

        if (storyPoints === '½') {
          return 0.5;
        }
        const storyPointsNumber = Number(storyPoints);

        if (Number.isNaN(storyPointsNumber)) {
          const err = new Error(
            `Story points is not a valid number. Got: ${storyPoints} (${typeof storyPoints}) Parsed value:${storyPointsNumber}`,
          );
          Sentry.withScope((scope) => {
            scope.setFingerprint(['Story points is not a valid number']);
            Sentry.captureException(err);
          });
          console.error(err);

          throw new Error(
            `Story points is not a valid number, got: ${storyPoints}.`,
          );
        }

        return storyPointsNumber;
    }
  }

  const fields = {
    [storyPointsField.id]: getStoryPointsValue(),
  };

  try {
    await requestApi<null>(`/rest/api/3/issue/${issueId}`, {
      type: 'PUT',
      bodyString: JSON.stringify({
        fields,
      }),
    });
  } catch (e: any) {
    Sentry.withScope((scope) => {
      scope.setFingerprint(["Couldn't update story points in Jira"]);
      Sentry.captureException(e);
    });
    console.error(e);

    const message = (e?.message as string) || '';

    if (message.includes('is not on the appropriate screen')) {
      throw new Error(
        `The field "${storyPointsField.name} (${storyPointsField.key})" is not found on this issue screen. Please select another one at game settings.`,
      );
    }

    throw new Error(e.message);
  }
};
