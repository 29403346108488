import { SplitTest } from '@we-agile-you/types-planning-poker';

type Config = {
  env: 'production' | 'staging' | 'development';
  hostingBaseUrl: string;
  functionsBaseUrl: string;
  splitTest: SplitTest;
  linearClientId: string;
};

const getConfig = (): Config => {
  const CONFIG = {
    splitTest: (process.env.GATSBY_BRANCH as SplitTest) || 'master',
  };

  switch (process.env.GATSBY_ENV) {
    case 'production':
      return {
        ...CONFIG,
        env: 'production',
        hostingBaseUrl: 'https://planningpokeronline.com',
        functionsBaseUrl:
          'https://us-central1-planning-poker-429a4.cloudfunctions.net/',
        linearClientId: '11f45453e090195639e6bf73504c5166',
      };

    case 'staging':
      return {
        ...CONFIG,
        env: 'staging',
        hostingBaseUrl: 'https://staging--planningpokeronline2.netlify.app',
        functionsBaseUrl:
          'https://us-central1-planning-poker-staging.cloudfunctions.net/',
        linearClientId: 'ee3d96fd212cad0053aee88231c2c79a',
      };

    default:
      return {
        ...CONFIG,
        env: 'development',
        hostingBaseUrl: 'http://localhost:8000',
        functionsBaseUrl:
          'http://localhost:5001/planning-poker-staging/us-central1/',
        linearClientId: 'e2247f691011d7374c2832d8091068f3',
      };
  }
};

export const CONFIG: Config = getConfig();
