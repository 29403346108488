import { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../../auth/AuthContext';
import { GameContext } from '../../GameContext';
import { Game } from '../../components/Game/Game';
import { useGameSubscription } from '../../hooks/useGameSubscription';
import { useProjectiles } from '../../../projectiles/hooks/useProjectiles';
import { GameHeader } from '../../components/GameHeader/GameHeader';
import {
  IsOpenState,
  SidebarLayoutWrapperContext,
} from '../../components/SidebarLayoutWrapper/SidebarLayoutWrapperContext';
import { SpotlightManager } from '@atlaskit/onboarding';

export const GameTemplate = ({ gameId }: { gameId: string }) => {
  const auth = useContext(AuthContext);
  const issuesSidebar = useState<IsOpenState>(
    window.innerWidth > 1024 ? true : false,
  );
  const settingsSidebar = useState<IsOpenState>(false);
  const manageIssuesPanel = useState<IsOpenState>(false);
  const {
    projectiles,
    handleProjectileThrowComplete,
    handleProjectilesChange,
  } = useProjectiles();
  const {
    game,
    players,
    playersAll,
    currentIssueIndex,
    isVoting,
    isOnCountdown,
    currentPlayer,
    deckCards,
  } = useGameSubscription({
    gameId,
    appId: auth && auth.appId,
    uid: auth && auth.uid,
    onProjectilesChange: handleProjectilesChange,
  });

  useEffect(() => {
    window.AP.jira.initJQLEditor();
  }, []);

  return (
    <GameContext.Provider
      value={{
        game,
        players,
        playersAll,
        currentPlayer,
        currentIssueIndex,
        isVoting,
        isOnCountdown,
        projectiles,
        handleProjectileThrowComplete,
        handleProjectilesChange,
        deckCards,
      }}
    >
      <SpotlightManager blanketIsTinted={false}>
        <SidebarLayoutWrapperContext.Provider
          value={{ issuesSidebar, settingsSidebar, manageIssuesPanel }}
        >
          <GameHeader />
          <Game />
        </SidebarLayoutWrapperContext.Provider>
      </SpotlightManager>
    </GameContext.Provider>
  );
};
