import { Inline } from '@atlaskit/primitives';
import { useCallback, useState } from 'react';
import {
  Board,
  Epic,
  Field,
  Filter,
  IssueType,
  Project,
  SearchParams,
  Sprint,
  Status,
} from '../../../../../jira/api';
import { ProjectPicker } from './pickers/ProjectPicker';
import { BoardPicker } from './pickers/BoardPicker';
import { SprintPicker } from './pickers/SprintPicker';
import { useAuthContext } from '../../../../../auth/useAuthContext';
import { updateCurrentUser } from '../../../../../auth/data';
import { IssueFilter } from '../../types';
import { MoreFiltersPicker } from './pickers/MoreFiltersPicker';
import { TypePicker } from './pickers/TypePicker';
import { StatusPicker } from './pickers/StatusPicker';
import { FilterPicker } from './pickers/FilterPicker';
import { EpicPicker } from './pickers/EpicPicker';

export const Pickers = ({
  searchParams,
  onUpdateSearchParams,
  projects,
  boards,
}: {
  searchParams: SearchParams | null;
  projects: Project[] | null;
  boards: Board[] | null;
  onUpdateSearchParams: ({
    params,
    avoidRefresh,
    overwrite,
  }: {
    params: Partial<SearchParams>;
    avoidRefresh?: boolean;
    overwrite?: boolean;
    clearFilterOnChange?: boolean;
  }) => void;
}) => {
  const [activeFilters, setActiveFilters] = useState<IssueFilter[]>([
    'project',
    'board',
    'sprint',
  ]);

  const authContext = useAuthContext();
  const appId = authContext && authContext.appId;
  const user = authContext && authContext.user;

  const handleProjectChange = useCallback(
    (project: Project | null) => {
      if (!appId || !user) {
        return;
      }
      updateCurrentUser(appId, {
        lastSelectedProjectIdForSearch: project?.id || null,
      });
      onUpdateSearchParams({
        params: {
          project,
        },
        clearFilterOnChange: true,
      });
    },
    [onUpdateSearchParams, appId],
  );

  const handleBoardChange = useCallback(
    (board: Board | null) => {
      onUpdateSearchParams({
        params: { board },
        avoidRefresh: true,
      });
    },
    [onUpdateSearchParams],
  );

  const handleSprintChange = useCallback(
    (sprint: Sprint | null) => {
      onUpdateSearchParams({ params: { sprint }, clearFilterOnChange: true });
    },
    [onUpdateSearchParams],
  );

  const handleTypeChange = useCallback(
    (issueTypes: IssueType[] | null) => {
      onUpdateSearchParams({
        params: { issueTypes },
        clearFilterOnChange: true,
      });
    },
    [onUpdateSearchParams],
  );

  const handleEpicChange = useCallback(
    (epics: Epic[] | null) => {
      onUpdateSearchParams({
        params: { epics },
        clearFilterOnChange: true,
      });
    },
    [onUpdateSearchParams],
  );

  const handleStatusChange = useCallback(
    (statuses: Status[] | null) => {
      onUpdateSearchParams({ params: { statuses }, clearFilterOnChange: true });
    },
    [onUpdateSearchParams],
  );

  const handleFilterChange = useCallback(
    (filter: Filter | null) => {
      onUpdateSearchParams({ params: { filter }, overwrite: true });
    },
    [onUpdateSearchParams],
  );

  return (
    <Inline space="space.050" shouldWrap>
      {activeFilters.map((filter) => {
        if (filter === 'project') {
          return (
            <ProjectPicker
              projectId={searchParams?.project?.id}
              onChange={handleProjectChange}
              projects={projects}
            />
          );
        }
        if (filter === 'board') {
          return (
            <BoardPicker
              projectId={searchParams?.project?.id}
              boards={boards || []}
              onChange={handleBoardChange}
            />
          );
        }
        if (filter === 'sprint') {
          return (
            <SprintPicker
              projectId={searchParams?.project?.id}
              boardId={searchParams?.board?.id}
              sprintId={searchParams?.sprint?.id}
              onChange={handleSprintChange}
            />
          );
        }
        if (filter === 'type') {
          return (
            <TypePicker
              onChange={handleTypeChange}
              selectedIssueTypes={searchParams?.issueTypes}
            />
          );
        }
        if (filter === 'status') {
          return (
            <StatusPicker
              projectId={searchParams?.project?.id || null}
              onChange={handleStatusChange}
              selectedStatuses={searchParams?.statuses}
            />
          );
        }
        if (filter === 'filter') {
          return (
            <FilterPicker
              onChange={handleFilterChange}
              selectedFilter={searchParams?.filter}
            />
          );
        }
        if (filter === 'epic') {
          return (
            <EpicPicker
              selectedEpics={searchParams?.epics}
              projectId={searchParams?.project?.id}
              boardId={searchParams?.board?.id}
              onChange={handleEpicChange}
            />
          );
        }
      })}
      <MoreFiltersPicker
        onAddFilter={(filter) =>
          setActiveFilters((filters) => [...filters, filter])
        }
        activeFilters={activeFilters}
      />
    </Inline>
  );
};
