import cx from 'classnames';
import React from 'react';
import Async from 'react-select/async';

import styles from './Select.module.scss';

export interface OptionType {
  label: string;
  value: string;
}

//@ts-ignore
export type SelectValue<T> = ValueType<T>;
export type SelectOptions = any;

interface SelectProps<T> {
  value?: SelectValue<T>;
  options?: SelectOptions;
  id?: string;
  required?: boolean;
  isMulti?: boolean;
  isSearchable?: boolean;
  autoComplete?: string;
  placeholder?: string;
  maxLength?: number;
  isBlock?: boolean;
  readOnly?: boolean;
  name?: string;
  isSmallDrodpwon?: boolean;
  loadOptions: (inputValue: string) => void;
  onChange?: (value: SelectValue<T>) => void;
}

const SelectFC: React.ForwardRefRenderFunction<any, SelectProps<OptionType>> = (
  { isSmallDrodpwon, ...props },
  ref,
) => {
  const className = cx(
    styles.select,
    isSmallDrodpwon && styles['select--small-dropdown'],
  );

  return (
    //@ts-ignore
    <Async
      ref={ref}
      className={className}
      classNamePrefix="select"
      {...props}
    />
  );
};

export const SelectAsync = React.forwardRef(SelectFC);
