import Avatar from '@atlaskit/avatar';
import { IssueJira } from '../../../../../types';
import Image from '@atlaskit/image';
import Button from '@atlaskit/button';
import { Inline } from '@atlaskit/primitives';
import Tooltip from '@atlaskit/tooltip';
import { openIssueModal } from '../../../../../jira/actions';
import Lozenge from '@atlaskit/lozenge';
import { Checkbox } from '@atlaskit/checkbox';
import styles from './Results.module.scss';
import { ReactNode } from 'react';
import Badge from '@atlaskit/badge';

export type Column =
  | 'select'
  | 'key'
  | 'summary'
  | 'epic'
  | 'assignee'
  | 'creator'
  | 'add'
  | 'estimate';

export const ALL_COLUMNS: Column[] = [
  'select',
  'key',
  'summary',
  'epic',
  'estimate',
  'assignee',
  'creator',
  'add',
];
export const EDITABLE_COLUMNS: Column[] = [
  'key',
  'summary',
  'epic',
  'estimate',
  'assignee',
  'creator',
];

type HeadCell = { content: ReactNode; key: Column; isSortable: boolean };

export const tableHead = ({
  settingsContent,
  selectedColumns,
}: {
  selectedColumns: Column[];
  settingsContent: ReactNode;
}): {
  cells: HeadCell[];
} => {
  const headCells: HeadCell[] = [
    {
      content: 'Select',
      key: 'select',
      isSortable: false,
    },
    {
      content: 'Key',
      key: 'key',
      isSortable: true,
    },
    {
      content: 'Summary',
      key: 'summary',
      isSortable: true,
    },
    {
      content: 'Epic',
      key: 'epic',
      isSortable: true,
    },
    {
      content: 'Estimate',
      key: 'estimate',
      isSortable: true,
    },
    {
      content: 'Assignee',
      key: 'assignee',
      isSortable: true,
    },
    {
      content: 'Creator',
      key: 'creator',
      isSortable: true,
    },
    {
      content: settingsContent,
      key: 'add',
      isSortable: false,
    },
  ];

  return {
    cells: headCells.filter((cell) => selectedColumns.includes(cell.key)),
  };
};

type RowCell = { content: ReactNode; column: Column; key: string };

const mapIssueToCells = ({
  issue,
  isSelected,
  onChangeCheckbox,
  onAdd,
  selectedColumns,
}: {
  issue: IssueJira;
  isSelected: boolean;
  onChangeCheckbox: () => void;
  onAdd: () => void;
  selectedColumns: Column[];
}) => {
  const rowCells: RowCell[] = [
    {
      key: `table-cell-checkbox-${issue.id}`,
      column: 'select',
      content: (
        <div className={styles['cell-checkbox-container']}>
          <Checkbox
            name={`checkbox-search-result-${issue.id}`}
            isChecked={isSelected}
            onChange={onChangeCheckbox}
            value={`checkbox-search-result-${issue.id}`}
            size="large"
          />
        </div>
      ),
    },
    {
      key: issue.key,
      column: 'key',
      content: (
        <Inline alignBlock="center" space="space.050">
          <div style={{ flexShrink: 0 }}>
            {issue.issueType ? (
              <Tooltip content={issue.issueType.name}>
                {(tooltipProps) => (
                  <div {...tooltipProps}>
                    <Image
                      src={issue.issueType?.iconUrl}
                      alt={issue.issueType?.name}
                      width={16}
                      height={16}
                    />
                  </div>
                )}
              </Tooltip>
            ) : null}
          </div>
          <div style={{ flexShrink: 0 }}>
            <Button
              appearance="link"
              onClick={() => openIssueModal(issue.key)}
              spacing="none"
            >
              {issue.key}
            </Button>
          </div>
        </Inline>
      ),
    },
    {
      key: issue.summary.replace(/\s/g, '').toLowerCase(),
      column: 'summary',
      content: issue.summary,
    },
    {
      key: issue.epic?.name.replace(/\s/g, '').toLowerCase() || '',
      column: 'epic',
      content: issue.epic?.name ? (
        <Lozenge appearance="new" isBold maxWidth={90}>
          {issue.epic?.name}
        </Lozenge>
      ) : null,
    },
    {
      key: issue.storyPoints?.toString() || '',
      column: 'estimate',
      content: (
        <Badge>
          {issue.storyPoints || issue.storyPoints === 0
            ? issue.storyPoints
            : null}
        </Badge>
      ),
    },
    {
      key: issue.assignee?.displayName.replace(/\s/g, '').toLowerCase() || '',
      column: 'assignee',
      content: issue.assignee ? (
        <Avatar
          appearance="circle"
          src={issue.assignee.avatar}
          size="small"
          name={issue.assignee.displayName}
        />
      ) : null,
    },
    {
      key: issue.creator?.displayName.replace(/\s/g, '').toLowerCase() || '',
      column: 'creator',
      content: issue.creator ? (
        <Avatar
          appearance="circle"
          src={issue.creator.avatar}
          size="small"
          name={issue.creator.displayName}
        />
      ) : null,
    },
    {
      key: 'add',
      column: 'add',
      content: (
        <Inline alignInline="end" grow="fill">
          <Button onClick={onAdd}>Add</Button>
        </Inline>
      ),
    },
  ];

  return rowCells.filter((cell) => selectedColumns.includes(cell.column));
};

export const mapIssuesToRows = ({
  issues,
  selectedIssues,
  onChangeSelectedIssues,
  onAddIssues,
  selectedColumns,
}: {
  issues: IssueJira[] | null;
  selectedIssues: IssueJira[] | null;
  onChangeSelectedIssues: React.Dispatch<
    React.SetStateAction<IssueJira[] | null>
  >;
  onAddIssues: (issues: IssueJira[]) => void;
  selectedColumns: Column[];
}) => {
  return issues?.map((issue) => {
    const isSelected = !!selectedIssues?.find(
      (_issue) => issue.id === _issue.id,
    );

    return {
      row: issue.id,
      cells: mapIssueToCells({
        issue,
        isSelected,
        selectedColumns,
        onChangeCheckbox: () =>
          onChangeSelectedIssues((selectedIssues) =>
            isSelected && selectedIssues
              ? selectedIssues.filter((_issue) => _issue.id !== issue.id)
              : [...(selectedIssues || []), issue],
          ),
        onAdd: () => onAddIssues([issue]),
      }),
    };
  });
};

const resolveValue = (issue: IssueJira, key: keyof IssueJira): string => {
  const getStringValue = (): string => {
    const value = issue[key];

    if (!value) {
      return '';
    }

    if (typeof value === 'string') {
      return value;
    }

    if (typeof value === 'number') {
      return value.toString();
    }

    if (typeof (value as { name: string }).name === 'string') {
      return (value as { name: string }).name;
    }

    if (typeof (value as { displayName: string }).displayName === 'string') {
      return (value as { displayName: string }).displayName;
    }

    return '';
  };

  return getStringValue().replace(/\s/g, '').toLowerCase();
};

export const sortIssues = (
  issues: IssueJira[],
  key: keyof IssueJira,
  sortOrder: 'ASC' | 'DESC',
) => {
  return issues.sort((issueA, issueB) => {
    const valueA = resolveValue(issueA, key);
    const valueB = resolveValue(issueB, key);

    const compare = valueA.localeCompare(valueB);

    return sortOrder === 'ASC' ? compare : compare * -1;
  });
};
