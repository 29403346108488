import styles from './SettingsSidebar.module.scss';
import cx from 'classnames';
import { Inline } from '@atlaskit/primitives';
import CrossIcon from '@atlaskit/icon/glyph/cross';
import { useSidebarLayoutContext } from '../SidebarLayoutWrapper/useSidebarLayoutContext';
import { ButtonIcon } from '../../../../components/ButtonIcon/ButtonIcon';
import SettingsForm, {
  getDeckOption,
  SettingsFormValues,
} from '../SettingsForm/SettingsForm';
import { updateGame } from '../../data/actions';
import { useAuthContext } from '../../../auth/useAuthContext';
import { useGameContext } from '../../hooks/useGameContext';
import { Game } from '../../../types';

export const SettingsSidebar = () => {
  const { settingsSidebar } = useSidebarLayoutContext();
  const gameContext = useGameContext();
  const authContext = useAuthContext();
  const appId = authContext && authContext.appId;
  const gameId = gameContext && gameContext.game?.id;

  const isOpen = !!settingsSidebar[0];

  const handleClose = () => settingsSidebar[1](false);

  const handleValuesChanged = (game: Partial<Game>) => {
    if (appId && gameId) {
      updateGame(appId, gameId, game);
    }
  };

  const defaultFormValues: Partial<SettingsFormValues> | null =
    gameContext?.game
      ? {
          name: gameContext.game.name,
          deck: getDeckOption(gameContext.game.deck),
          whoCanShowCards: gameContext.game.whoCanShowCards,
          whoCanEditIssues: gameContext.game.whoCanEditIssues,
          autoShowCards: gameContext.game.autoShowCards,
          enableProjectiles: gameContext.game.enableProjectiles,
          storyPointsField: gameContext.game.storyPointsField,
          showAverageInResults:
            typeof gameContext.game.hideAverageInResults === 'undefined' ||
            !gameContext.game.hideAverageInResults,
        }
      : null;

  return (
    <>
      {isOpen && <div className={styles['blanket']} onClick={handleClose} />}
      <div className={cx(styles.sidebar, isOpen && styles['sidebar--open'])}>
        <div className={styles['sidebar-header']}>
          <Inline shouldWrap={false} spread="space-between" alignBlock="center">
            <span>Game Settings</span>
            <ButtonIcon
              icon={<CrossIcon label="Close" />}
              onClick={handleClose}
            />
          </Inline>
        </div>
        <div className={styles['sidebar-content']}>
          {defaultFormValues && isOpen && (
            <SettingsForm
              formMode="game-settings"
              defaultFormValues={defaultFormValues}
              onValuesChanged={handleValuesChanged}
              onSubmit={handleClose}
            />
          )}
        </div>
      </div>
    </>
  );
};
