import React, { useEffect, useState } from 'react';
import styles from './ProjectileSelector.module.scss';
import paperBall from './paperBall.png';
import { ProjectileType } from '@we-agile-you/types-planning-poker';
import { ButtonFloating } from '../../../../components/ButtonFloating/ButtonFloating';
import { EmojiPicker } from '../../../../components/EmojiPicker/EmojiPicker';

export const ProjectileSelector = ({
  onShootProjectile,
  onIsEmojiPickerOpenChange,
}: {
  onShootProjectile: (projectileType: ProjectileType, emoji?: string) => void;
  onIsEmojiPickerOpenChange: (isOpen: boolean) => void;
}) => {
  const [recentProjectile, setRecentProjectile] = useState<string | null>(null);

  useEffect(() => {
    const localStorageProjectile = localStorage.getItem(
      'user-recent-projectile',
    );

    if (!localStorageProjectile) {
      localStorage.setItem('user-recent-projectile', '❤️');
      setRecentProjectile('❤️');

      return;
    }

    setRecentProjectile(localStorage.getItem('user-recent-projectile'));
  }, []);

  const handleShoot = (projectileType: ProjectileType, emoji?: string) => {
    onShootProjectile(projectileType, emoji);
    if (emoji && emoji !== recentProjectile) {
      localStorage.setItem('user-recent-projectile', emoji);
      setRecentProjectile(emoji);
    }
  };

  return (
    <>
      <ButtonFloating
        size="s"
        buttonStyle="tooltip"
        onClick={() => handleShoot('paperBall')}
      >
        <img src={paperBall} className={styles.paperBallIcon} />
      </ButtonFloating>
      <ButtonFloating
        size="s"
        buttonStyle="tooltip"
        onClick={() => handleShoot('nerfDart')}
      >
        🎯
      </ButtonFloating>

      {recentProjectile && (
        <ButtonFloating
          size="s"
          buttonStyle="tooltip"
          onClick={() => handleShoot('emoji', recentProjectile)}
        >
          {recentProjectile}
        </ButtonFloating>
      )}
      <EmojiPicker
        onEmojiSelect={(emoji) => handleShoot('emoji', emoji)}
        buttonColor="tooltip"
        hideOnSelect
        onIsOpenChange={onIsEmojiPickerOpenChange}
      />
    </>
  );
};
