import { RefObject, useEffect } from 'react';

export const useHandleClickOuside = (
  containerRefs: RefObject<HTMLElement>[],
  cb: () => void,
  ignore?: boolean,
) => {
  useEffect(() => {
    const handleDocumentClick = (event: Event) => {
      const isClickOutsideAllRefs = containerRefs.every((element) => {
        if (ignore && !element.current) {
          return true; // Element is not in dom
        }

        const isClickOutsideRef =
          element.current && !element.current.contains(event.target as Node);

        return isClickOutsideRef;
      });

      if (isClickOutsideAllRefs) {
        cb();
      }
    };

    document.addEventListener('click', handleDocumentClick);

    return () => {
      document.removeEventListener('click', handleDocumentClick);
    };
  }, [cb, containerRefs]);
};
