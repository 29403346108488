import { ReactNode } from 'react';
import { useNavigation } from '../../spaces/navigation/useNavigaton';

export const Link = ({
  children,
  href,
  className,
}: {
  children: ReactNode;
  href: string;
  className?: string;
}) => {
  const navigation = useNavigation();
  const navigate = navigation.navigate;

  return (
    <a
      href={href}
      onClick={(e) => {
        e.preventDefault();
        navigate(href);
      }}
      className={className}
    >
      {children}
    </a>
  );
};
