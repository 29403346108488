import { DefaultDeck } from './types';

export const JIRA_CONNECT_APPS_DATA_COLLECTION = 'jira-connect-apps-data';

export const DECKS: DefaultDeck[] = [
  {
    name: 'Fibonacci ( 0, 1, 2, 3, 5, 8, 13, 21, 34, 55, 89, ?, ☕ )',
    value: '0,1,2,3,5,8,13,21,34,55,89,?,☕',
  },
  {
    name: 'Modified Fibonacci ( 0, ½, 1, 2, 3, 5, 8, 13, 20, 40, 100, ?, ☕ )',
    value: '0,½,1,2,3,5,8,13,20,40,100,?,☕',
  },
  {
    name: 'Powers of 2 ( 0, 1, 2, 4, 8, 16, 32, 64, ?, ☕ )',
    value: '0,1,2,4,8,16,32,64,?,☕',
  },
  {
    name: 'T-shirts (XS, S, M, L, XL, ?, ☕ )',
    value: 'XS,S,M,L,XL,?,☕',
  },
];

export const DEFAULT_DECK = DECKS[0];
export const CUSTOM_DECK_VALUE = 'CUSTOM_DECK_VALUE';

export const NON_STORY_POINTS_VALUES = ['?', '☕'];
