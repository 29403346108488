import cx from 'classnames';
import React, { useRef } from 'react';
import { CardValueSide } from '../Card/CardValueSide/CardValueSide';
import {
  Spotlight,
  SpotlightManager,
  SpotlightTarget,
  SpotlightTransition,
} from '@atlaskit/onboarding';

import styles from './CardSelector.module.scss';
import { CardSelectorTooltip } from './CardSelectorTooltip';

interface CardSelectorProps {
  cards: string[];
  value?: string | null;
  isDisabled?: boolean;
  onChange?: (card: string | null) => void;
}

export const CardSelector: React.FC<CardSelectorProps> = ({
  cards,
  onChange,
  value,
  isDisabled,
}) => {
  const cardListRef = useRef<HTMLUListElement>(null);
  function renderCardItem(card: string, key: string) {
    const isActive = card === value;

    function handleCardClick(card: string) {
      if (isDisabled) return;

      if (onChange) {
        onChange(card !== value ? card : null);
      }
    }

    const className = cx(styles['card'], {
      [styles['disabled']]: isDisabled,
      [styles['selected']]: isActive,
    });

    return (
      <li key={key} className={className}>
        <CardValueSide
          size="l"
          card={card}
          isButton
          isSelected={isActive}
          isDisabled={isDisabled}
          onClick={() => handleCardClick(card)}
        />
      </li>
    );
  }

  const className = cx(styles['container']);

  return (
    <>
      <SpotlightTarget name="card-selector">
        <div className={className}>
          <ul
            data-test="list-card-selector"
            ref={cardListRef}
            className={cx(styles['card-list'])}
          >
            {cards.map((card, i) => renderCardItem(card, i.toString()))}
          </ul>
        </div>
      </SpotlightTarget>
      <CardSelectorTooltip />
    </>
  );
};
