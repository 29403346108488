import { CONFIG } from "@we-agile-you/config";

const STRIPE_PRICES_IDS =
  CONFIG.env === "production"
    ? {
        yearly: "price_1HWN2PA3Gc0cJsLh2CrFMtEG",
        yearly_eu: "price_1NGf1RA3Gc0cJsLhxyy5YoDW",
        monthly: "price_1HWN2PA3Gc0cJsLhaCQV8v3U",
        monthly_eu: "price_1NGf1pA3Gc0cJsLhbR2zJ0YE",
        montlhyDeprecated: "plan_H7OyEsj01TG8fV",
      }
    : {
        yearly: "price_1HVAwxA3Gc0cJsLhyF5YwTZd", // weekly
        yearly_eu: "price_1MwMI2A3Gc0cJsLhXGfk5A3z", // weekly
        monthly: "price_1HVAwxA3Gc0cJsLhdAyn1YPu", // daily
        monthly_eu: "price_1MwMJcA3Gc0cJsLhSX226nw0", // daily
        montlhyDeprecated: "plan_H2KOmxnTUzhLcj", // daily
      };

export const isYearlyPrice = (priceId: string) => {
  return (
    priceId === STRIPE_PRICES_IDS.yearly ||
    priceId === STRIPE_PRICES_IDS.yearly_eu
  );
};
export const isDeprecatedPrice = (priceId: string) => {
  return priceId === STRIPE_PRICES_IDS.montlhyDeprecated;
};
