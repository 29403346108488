import classnames from 'classnames';
import React, { useRef, useState } from 'react';
import ReactDom from 'react-dom';
import { usePopover } from 'react-use-popover';
import { Tooltip } from '../Tooltip/Tooltip';

import styles from './ButtonFloating.module.scss';

export interface ButtonFloatingProps {
  /** Content of the button */

  children: React.ReactNode;
  /** General style of the button */
  buttonStyle?: 'primary' | 'primary-opacity' | 'secondary' | 'tooltip';
  /** button size */
  size?: 'xs' | 's' | 'm';
  /** Text hint to show on over  */
  tooltip?: React.ReactNode;
  /** Optional change the tooltip position  */
  tooltipPosition?:
    | 'bottom-left'
    | 'bottom-right'
    | 'top-right'
    | 'right'
    | 'top'
    | 'left';
  /** Disable button interaction */
  isDisabled?: boolean;
  /** Active state usually usually used to show that something else is activated by the button (like a dropdown) */
  isActive?: boolean;
  /** Extra css class */
  className?: string;
  /** Whether you want to hide tooltip on small devices */
  isDontHideTooltipOnSmallScreen?: boolean;
  /** Tooltip is always visible (not only on over)  */
  pinTooltip?: boolean;
  /** avoid auto translation */
  translate?: 'no';
  /** For e2e testing */
  'data-test'?: string;
  /** Click event handler */
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

// -- Static attributes

const defaultProps: Partial<ButtonFloatingProps> = {
  buttonStyle: 'primary',
};

// -- Component
const ButtonFC: React.ForwardRefRenderFunction<
  HTMLButtonElement,
  ButtonFloatingProps
> = (props, ref) => {
  const resolvedProps = {
    ...defaultProps,
    ...props,
  };
  const {
    children,
    buttonStyle,
    isDisabled,
    isActive,
    className,
    translate,
    tooltip,
    tooltipPosition,
    onClick,
    isDontHideTooltipOnSmallScreen,
    pinTooltip,
    size,
  } = resolvedProps;

  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const [isMouseOver, setIsMouseOver] = useState<boolean | null>(null);
  const [tooltipElement, setTooltipElement] = useState<HTMLDivElement | null>(
    null,
  );

  const { dropdownStyle } = usePopover({
    anchorRef: ref ? (ref as React.RefObject<HTMLElement>) : buttonRef,
    dropdownElement: tooltipElement,
    position: tooltipPosition || 'bottom',
  });

  const handleMouseEnter = () => {
    setIsMouseOver(true);
  };

  const handleMouseLeave = () => {
    setIsMouseOver(false);
  };

  const handleOnClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (onClick) {
      onClick(event);
    }
  };

  const resolvedClassName = classnames(
    className,
    styles['button-floating'],
    buttonStyle && styles[`button-floating--style-${buttonStyle}`],
    size && styles[`button-floating--size-${size}`],
    {
      [styles['is-disabled']]: isDisabled,
      [styles['is-active']]: isActive,
      [styles[`tooltip--${tooltipPosition}`]]: tooltipPosition,
    },
    'is-clickable',
  );

  return (
    <>
      <button
        className={resolvedClassName}
        onClick={handleOnClick}
        disabled={isDisabled}
        type="button"
        translate={translate}
        ref={ref || buttonRef}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        data-test={resolvedProps['data-test']}
      >
        {children}
      </button>

      {tooltip &&
        (isMouseOver || pinTooltip) &&
        ReactDom.createPortal(
          <div
            ref={setTooltipElement}
            style={{ ...dropdownStyle, zIndex: 999 }}
          >
            <Tooltip isShowOnSmallScreen={isDontHideTooltipOnSmallScreen}>
              {tooltip}
            </Tooltip>
          </div>,
          document.body,
        )}
    </>
  );
};

export const ButtonFloating = React.forwardRef(ButtonFC);
