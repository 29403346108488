import { ButtonGroup, LoadingButton } from '@atlaskit/button';
import Form, {
  ErrorMessage,
  Field,
  FormFooter,
  HelperMessage,
} from '@atlaskit/form';
import TextField from '@atlaskit/textfield';
import { useCallback, useEffect, useState, useRef } from 'react';
import { addCustomDeckToApp } from '../../../app/data';
import ButtonDefault from '@atlaskit/button/standard-button';
import { Paragraph } from '../../../../components/Paragraph/Paragraph';
import { VerticalSpacing } from '../../../../components/spacings/VerticalSpacing/VerticalSpacing';
import { CardSelector } from '../CardSelector/CardSelector';
import { useAuthContext } from '../../../auth/useAuthContext';
import { CustomDeck } from '../../../types';
import { ModalFooter } from '@atlaskit/modal-dialog';

type CreateDeckFormData = {
  name: string;
  value: string;
};
interface CreateDeckFormProps {
  onCancel: () => void;
  onCreated: (deck: CustomDeck) => void;
}

const getDeckFromValue = (value: string) =>
  value.split(',').filter((value) => !!value && value.length < 4);

const DEFAULT_FORM_VALUES = {
  name: 'Custom Fibonacci deck',
  value: '1,2,3,5,8,13',
};

export const CreateDeckForm = ({
  onCancel,
  onCreated,
}: CreateDeckFormProps) => {
  const auth = useAuthContext();
  const user = auth && auth.user;
  const uid = auth && auth.uid;
  const appId = auth && auth.appId;
  const [selectedCard, setSelectedCard] = useState<string | null>('3');
  const [deck, setDeck] = useState<string[]>(
    DEFAULT_FORM_VALUES.value.split(','),
  );
  const deckNameInputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (deckNameInputRef && deckNameInputRef.current) {
      deckNameInputRef.current.focus();
      deckNameInputRef.current.setSelectionRange(
        0,
        deckNameInputRef.current.value.length,
      );
    }
  }, []);

  const handleInputChange = useCallback(
    (value: string) => {
      const deck = getDeckFromValue(value);
      setDeck(deck);

      if (selectedCard && !deck.find((value) => value === selectedCard)) {
        setSelectedCard(null);
      }
    },
    [selectedCard],
  );

  const handleSubmit = async ({ name, value }: CreateDeckFormData) => {
    try {
      if (!user || !appId) {
        return { name: 'Unexpected error: User not logged in' };
      }
      if (!name) {
        return { name: 'Please set a name to the deck' };
      }
      if (!value || value.length <= 0) {
        return { value: 'Deck must have at least one card' };
      }

      if (value.length > 20) {
        return { value: 'Deck must have less than 20 elements' };
      }

      // Ensure the value has a nice format
      const formattedValue = value.replace(/\s/g, '');

      await addCustomDeckToApp(appId, {
        name,
        value: formattedValue,
        creatorName: user.displayName,
        creatorId: user.uid,
        creatorAvatar: user.avatar,
      });

      const customDeck: CustomDeck = {
        name,
        value,
        creatorId: uid,
        creatorAvatar: user?.avatar || '',
        creatorName: user?.displayName || '',
      };

      onCreated(customDeck);
    } catch (e: any) {
      return { name: e?.message || 'An error happened saving your deck' };
    }
  };

  return (
    <div>
      <Form<CreateDeckFormData> onSubmit={handleSubmit}>
        {({ formProps, submitting }) => (
          <form
            {...formProps}
            onSubmit={(e) => {
              e.stopPropagation();
              formProps.onSubmit(e);
            }}
          >
            <Field
              name="name"
              label="Deck's name"
              defaultValue={DEFAULT_FORM_VALUES.name}
            >
              {({ fieldProps, error }) => (
                <>
                  <TextField
                    autoComplete="off"
                    maxLength={60}
                    ref={deckNameInputRef}
                    {...fieldProps}
                  />
                  {!error && (
                    <HelperMessage>
                      Enter card values separated by commas. (max 3 chars per
                      card)
                    </HelperMessage>
                  )}
                  {error && <ErrorMessage>{error}</ErrorMessage>}
                </>
              )}
            </Field>
            <Field
              name="value"
              label="Deck values"
              defaultValue={DEFAULT_FORM_VALUES.value}
            >
              {({ fieldProps: { onChange, ...fieldProps }, error }) => (
                <>
                  <TextField
                    autoComplete="off"
                    maxLength={60}
                    placeholder="Set an optional game name"
                    onChange={(event) => {
                      onChange(event);
                      handleInputChange(event.currentTarget.value);
                    }}
                    {...fieldProps}
                  />
                  {error && <ErrorMessage>{error}</ErrorMessage>}
                </>
              )}
            </Field>
            <VerticalSpacing spacing="spacing-l" />
            <Paragraph fontWeight="bold" color="grey600">
              Preview
            </Paragraph>
            <Paragraph color="grey600">
              This is a preview of how your deck will look like.
            </Paragraph>
            <VerticalSpacing spacing="spacing-m" />
            <CardSelector
              cards={deck}
              onChange={setSelectedCard}
              value={selectedCard}
            />
            <VerticalSpacing spacing="spacing-xl" />
            <ModalFooter>
              <ButtonGroup>
                <ButtonDefault appearance="subtle" onClick={onCancel}>
                  Cancel
                </ButtonDefault>
                <LoadingButton
                  type="submit"
                  appearance="primary"
                  isLoading={submitting}
                >
                  Create deck
                </LoadingButton>
              </ButtonGroup>
            </ModalFooter>
          </form>
        )}
      </Form>
    </div>
  );
};
