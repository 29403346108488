import { useEffect, useState } from 'react';

import { AppData, subscribeToAppData } from '../app/data';
import { SignInResult } from './useSignin';

export const useSubscribeToAppData = (auth: SignInResult) => {
  const [appData, setAppData] = useState<null | AppData>(null);

  useEffect(() => {
    if (!auth?.isAuthenticated) {
      return;
    }

    return subscribeToAppData(auth.appId, (appData) => setAppData(appData));
  }, [auth]);

  return appData;
};
