import React from 'react';

export const SidebarFill = () => (
  <svg
    width="20"
    height="16"
    viewBox="0 0 20 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 0C1.34315 0 0 1.34315 0 3V13C0 14.6569 1.34315 16 3 16H17C18.6569 16 20 14.6569 20 13V3C20 1.34315 18.6569 0 17 0H3ZM2 3C2 2.44772 2.44772 2 3 2H13V14H3C2.44772 14 2 13.5523 2 13V3Z"
      fill="currentColor"
    />
  </svg>
);
