import { Stack } from '@atlaskit/primitives';
import { Modal } from '../../../../components/Modal/Modal';

import screen from './screen.png';
import screen2 from './screen2.png';

interface StoryPointsHelpModalProps {
  onClose: () => void;
  onBack?: () => void;
}

export const StoryPointsHelpModal = ({
  onClose,
  onBack,
}: StoryPointsHelpModalProps) => {
  return (
    <Modal
      onClose={onClose}
      showBackButton={!!onBack}
      onClickBackButton={onBack}
      width="big"
    >
      <Stack space="space.250">
        <h1>Story points estimate synchronization</h1>
        <div>
          <h2>How does it work?</h2>
          <p>
            Every issue in your Jira system comes with various fields (such as
            summary, status, assignee, etc.). Among these, one field is
            designated for logging your story point estimates.
          </p>
          <p>
            Typically, we identify this field automatically. However, there are
            instances where this isn't possible, and you'll need to select it
            manually.
          </p>
          <p>
            Once we determine which field you're utilizing for story points, we
            can record the story points for every voting session.
          </p>
          <h2>
            How to identify the field for story points in your Jira instance?
          </h2>
          <p>
            Simply open any issue within your project and search for the field
            that holds the story point estimates. It's often labeled as "Story
            point estimate" or "T-shirt size."
          </p>
          <img src={screen} style={{ maxWidth: '100%' }} alt="Field" />
          <p>
            If you're unable to locate it, this might indicate it's never been
            used in this Jira instance. In such cases, you have the option to
            configure the active fields from the same issue view or seek
            assistance from your Jira administrator:
          </p>
          <img src={screen2} style={{ maxWidth: '100%' }} alt="Config" />
          <h2>Is synchronization not functioning just for some Issues?</h2>
          <p>
            Different types of issues may feature varied sets of fields. For
            example, a "bug" might have different fields compared to a "story".
          </p>
          <p>
            It's necessary to incorporate your story point field into the
            configurations for all issue types you wish to save.
          </p>
          <p>
            This adjustment can be made from the issue view for the specific
            issue type you are adjusting (or seek assistance from your Jira
            administrator):
          </p>
          <img src={screen2} style={{ maxWidth: '100%' }} alt="Config" />
          <h2>
            Story points field is correctly selected but saving still fails?
          </h2>
          <p>
            Maybe you are using a special field that is still not supported by
            our app.
          </p>
          <p>
            Please send us a contact at info@weagileyou.com with a link to a
            game where there is an issue that is failing. And we help you :)
          </p>
        </div>
      </Stack>
    </Modal>
  );
};
