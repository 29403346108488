import { useEffect } from 'react';
import { useGameContext } from '../../hooks/useGameContext';
import { useAuthContext } from '../../../auth/useAuthContext';
import { Spotlight, SpotlightTransition } from '@atlaskit/onboarding';
import { useOneTimeUserAction } from '../../../auth/useOneTimeUserAction';

export const CardSelectorTooltip = () => {
  const [isDone, setIsDone] = useOneTimeUserAction('cardSelectorTooltip');
  const gameContext = useGameContext();
  const auth = useAuthContext();
  const game = gameContext?.game;
  const user = auth.user;

  const isLoading = !game || !user;
  const isOwner = game?.ownerId === user?.uid;
  const isVoting = !!gameContext?.isVoting;

  const isOpen = !isLoading && isDone === false && isOwner && !isVoting;

  useEffect(() => {
    if (isDone === false && isVoting && isOwner) {
      setIsDone();
    }
  }, [isDone, isOwner, isVoting, setIsDone]);

  return (
    <SpotlightTransition>
      {isOpen && (
        <Spotlight
          actions={[
            {
              onClick: setIsDone,
              text: 'OK',
            },
          ]}
          heading="Pick a card"
          target="card-selector"
          key="card-selector"
          targetOnClick={setIsDone}
        >
          Your choice is not visible to your teammates yet.
        </Spotlight>
      )}
    </SpotlightTransition>
  );
};
