import { Player } from './../../types';
import { firestore, database } from './../../../firebase';
import {
  doc,
  setDoc,
  serverTimestamp as serverTimestampFirestore,
} from 'firebase/firestore';

import {
  ref,
  onDisconnect,
  set,
  serverTimestamp as serverTimestampDatabase,
  DataSnapshot,
  onValue,
} from 'firebase/database';

const getPlayerPath = (appId: string, uid: string, gameId: string) => {
  const playerId = `${uid}_${gameId}`;
  return `/jira-connect-apps-data/${appId}/players/${playerId}`;
};

export const buildPresenceInPokerGame = (
  appId: string,
  uid: string,
  gameId: string,
) => {
  const playerPath = getPlayerPath(appId, uid, gameId);
  const playerDatabaseRef = ref(database, playerPath);
  const playerFirestoreRef = doc(firestore, playerPath);
  const isOfflineForDatabase = {
    state: 'offline',
    last_changed: serverTimestampDatabase(),
  };
  const isOnlineForDatabase = {
    state: 'online',
    last_changed: serverTimestampDatabase(),
  };
  const isOfflineForFirestore = {
    state: 'offline',
    last_changed: serverTimestampFirestore(),
  };
  const isOnlineForFirestore = {
    state: 'online',
    last_changed: serverTimestampFirestore(),
  };
  const onConnexionSatusChange = (snapshot: DataSnapshot) => {
    // eslint-disable-next-line eqeqeq
    if (snapshot.val() == false) {
      // we'll also set Firestore's state
      // to 'offline'. This ensures that our Firestore cache is aware
      // of the switch to 'offline.'
      setDoc(playerFirestoreRef, isOfflineForFirestore, { merge: true });
      return;
    }

    onDisconnect(playerDatabaseRef)
      .set(isOfflineForDatabase)
      .then(function () {
        set(playerDatabaseRef, isOnlineForDatabase);

        // We'll also add Firestore set here for when we come online.
        setDoc(playerFirestoreRef, isOnlineForFirestore, { merge: true });
      });
  };

  const connectedRef = ref(database, '.info/connected');
  const unsubscribe = onValue(connectedRef, onConnexionSatusChange);

  return function removePresenceInPokerGame() {
    unsubscribe();

    set(playerDatabaseRef, isOfflineForDatabase);
  };
};

export const updatePlayerInfo = (
  appId: string,
  uid: string,
  player: Partial<Player>,
  gameId: string,
) => {
  const playerPath = getPlayerPath(appId, uid, gameId);

  return setDoc(doc(firestore, playerPath), player, {
    merge: true,
  });
};
