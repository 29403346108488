import { useGameContext } from './useGameContext';

import { useEffect } from 'react';
import { useAuthContext } from '../../auth/useAuthContext';
import {
  buildPresenceInPokerGame,
  updatePlayerInfo,
} from '../data/gamePresence';

export const useBuildPresence = (): void => {
  const auth = useAuthContext();
  const game = useGameContext()?.game;

  const uid = auth && auth.uid;
  const user = auth && auth.user;
  const appId = auth && auth.appId;

  const isUserFetched = !!user;
  const gameId = game?.id;
  const gameName = game?.name || '';
  const displayName = (user && user.displayName) || '';
  const avatar = (user && user.avatar) || null;
  const isSpectator = (!!user && user.isSpectator) || false;

  useEffect(() => {
    if (!uid || !gameId || !appId) return;

    return buildPresenceInPokerGame(appId, uid, gameId);
  }, [appId, uid, gameId]);

  useEffect(() => {
    if (!uid || !isUserFetched || !gameId || !appId) return;

    updatePlayerInfo(
      appId,
      uid,
      {
        uid,
        gameId,
        displayName,
        avatar,
        gameName,
        isSpectator,
      },
      gameId,
    );
  }, [
    appId,
    uid,
    isUserFetched,
    gameId,
    displayName,
    avatar,
    gameName,
    isSpectator,
  ]);
};
