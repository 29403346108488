import React from 'react';
import cx from 'classnames';

import styles from './Card.module.scss';
import { CardValueSide } from './CardValueSide/CardValueSide';
import { CardPictureSide } from './CardPictureSide/CardPictureSide';

interface CardProps {
  card?: string;
  isCardsUp?: boolean;
  isTwoFaces?: boolean;
  size?: 'm' | 'l' | 'xl';
  onClick?: () => void;
  color?: 'default' | 'alternative';
  valueComponent?: React.ReactNode;
}

const Card: React.FC<CardProps> = ({
  card,
  isCardsUp,
  isTwoFaces,
  size,
  onClick,
  color,
  valueComponent,
}) => {
  function handleOnClick() {
    if (onClick) {
      onClick();
    }
  }

  const className = cx(styles['card'], size && [styles[size]], {
    [styles['downwards']]: !isCardsUp,
  });

  return (
    <div className={className}>
      <div className={styles['value-side']}>
        {valueComponent ? (
          valueComponent
        ) : (
          <CardValueSide
            size={size}
            card={isCardsUp ? card || '' : ''}
            onClick={handleOnClick}
            color={color}
          />
        )}
      </div>
      <div className={styles['picture-side']}>
        {isTwoFaces ? (
          <CardValueSide
            size={size}
            card={card || ''}
            isButton
            onClick={handleOnClick}
            isValueSideStyle
            color={color}
          />
        ) : (
          <CardPictureSide size={size} />
        )}
      </div>
    </div>
  );
};

export default Card;
