import { ReactNode, useEffect, useState } from 'react';
import { useAuthContext } from '../../auth/useAuthContext';
import { guessStoryPointsField } from './utils';
import { Field, getFields } from '../api';
import { FieldsContext } from './FieldsContext';
import { useGameContext } from '../../game/hooks/useGameContext';
import { updateGame } from '../../game/data/actions';

export const FieldsProvider = ({
  children,
  gameId,
}: {
  children: ReactNode;
  gameId?: string;
}) => {
  const { user, appId } = useAuthContext();
  const game = useGameContext()?.game;
  const storyPointsField = game?.storyPointsField;

  const [fields, setFields] = useState<Field[] | null>(null);
  const [error, setError] = useState<Error | null>(null);
  const [guessedStoryPointsField, setGuessedStoryPointsField] = useState<
    Field | null | false
  >(null);

  useEffect(() => {
    if (!user) {
      return;
    }

    console.log('Getting fields again...');
    setError(null);

    getFields()
      .then((fields) => {
        setFields(fields);
        setGuessedStoryPointsField(
          guessStoryPointsField(user, fields) || false,
        );
      })
      .catch((e) => {
        console.error(e);

        setError(e);
        setFields([]);
      });
  }, [user]);

  useEffect(() => {
    if (!storyPointsField && guessedStoryPointsField && gameId) {
      // Fallback for all games without storyPointsField
      updateGame(appId, gameId, { storyPointsField: guessedStoryPointsField });
    }
  }, [gameId, storyPointsField, appId, guessedStoryPointsField]);

  return (
    <FieldsContext.Provider
      value={{
        fields,
        guessedStoryPointsField: guessedStoryPointsField || null,
        error,
      }}
    >
      {children}
    </FieldsContext.Provider>
  );
};
