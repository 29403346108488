import { useBuildPresence } from '../../hooks/useBuildPresence';
import Room from '../Room/Room';
import { SidebarLayoutWrapper } from '../SidebarLayoutWrapper/SidebarLayoutWrapper';
import styles from './Game.module.scss';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

export const Game = () => {
  useBuildPresence();

  return (
    <div className={styles.wrapper}>
      <DndProvider backend={HTML5Backend}>
        <SidebarLayoutWrapper>
          <Room />
        </SidebarLayoutWrapper>
      </DndProvider>
    </div>
  );
};
