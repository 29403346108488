import { Inline, Stack } from '@atlaskit/primitives';
import { ReactNode, useCallback, useEffect, useState } from 'react';
import * as Sentry from '@sentry/react';
import CrossIcon from '@atlaskit/icon/glyph/cross';
import {
  Board,
  Field,
  getBoards,
  getProjects,
  Project,
  searchIssues,
  SearchParams,
} from '../../../jira/api';
import { IssueJira } from '../../../types';
import Tooltip from '@atlaskit/tooltip';

import { useAuthContext } from '../../../auth/useAuthContext';
import { IconButton } from '@atlaskit/button/new';
import { Pickers } from './components/Pickers/Pickers';
import { Results } from './components/Results/Results';
import { JqlPicker } from './components/JqlPicker/JqlPicker';
import { openJQLSearch, showNotification } from '../../../jira/actions';
import { useGameContext } from '../../hooks/useGameContext';
import SectionMessage, {
  SectionMessageAction,
} from '@atlaskit/section-message';
import { useSidebarLayoutContext } from '../SidebarLayoutWrapper/useSidebarLayoutContext';
import { sortIssues } from './components/Results/utils';
import { SpotlightTarget } from '@atlaskit/onboarding';
import { SearchIssuesTooltip } from './SearchIssuesTooltip';
import { FeedbackButton } from '../FeedbackButton/FeedbackButton';
import Button from '@atlaskit/button';

import styles from './SearchPanel.module.scss';
import { updateCurrentUser } from '../../../auth/data';
import { useFieldsContext } from '../../../jira/fields/useFieldsContext';

const NONE = 'none';

type UpdateSearchParamsParams = {
  params: Partial<SearchParams>;
  avoidRefresh?: boolean;
  overwrite?: boolean;
  clearFilterOnChange?: boolean;
  storyPointsField: Field | null;
};

type RefreshIssuesParams = {
  searchParams?: SearchParams;
  jql?: string;
  fields?: Field[];
  storyPointsField: Field | null;
};

export const SearchPanel = ({
  onIssueAdded,
  errorMessage,
}: {
  onIssueAdded: () => void;
  errorMessage: ReactNode;
}) => {
  const [searchParams, setSearchParams] = useState<SearchParams | null>(null);
  const [jql, setJql] = useState<string>('Order by RANK');
  const [isIdle, setIsIdle] = useState(true);
  const [issues, setIssues] = useState<IssueJira[] | null>(null);
  const [issuesTotal, setIssuesTotal] = useState<number | null>(null);
  const [nextIssuesPageStartAt, setNextIssuesPageStartAt] = useState<
    number | null
  >(null);
  const [selectedIssues, setSelectedIssues] = useState<IssueJira[] | null>(
    null,
  );
  const [isLoadingIssues, setIsLoadingIssues] = useState(false);
  const [isLoadingMoreIssues, setIsLoadingMoreIssues] = useState(false);
  const [projects, setProjects] = useState<Project[] | null>(null);
  const [boards, setBoards] = useState<Board[] | null>(null);
  const [error, setError] = useState<Error | null>(null);

  const authContext = useAuthContext();
  const gameContext = useGameContext();
  const fieldsContext = useFieldsContext();
  const guessedStoryPointsField = fieldsContext?.guessedStoryPointsField;
  const game = gameContext && gameContext.game;
  const lastSelectedProjectId =
    authContext && authContext.user
      ? authContext.user.lastSelectedProjectIdForSearch
        ? authContext.user.lastSelectedProjectIdForSearch
        : NONE
      : null;
  const uid = authContext && authContext.uid;
  const appId = authContext && authContext.appId;
  const lastSelectedSearchMode =
    authContext && authContext.user?.lastSelectedSearchMode;
  const storyPointsField =
    gameContext?.game?.storyPointsField || guessedStoryPointsField || null;

  const [mode, setMode] = useState<'pickers' | 'jql'>(
    lastSelectedSearchMode ? lastSelectedSearchMode : 'pickers',
  );

  const setIsSettingsSidebarOpen = useSidebarLayoutContext().settingsSidebar[1];
  const setIsIssuesSidebarOpen = useSidebarLayoutContext().issuesSidebar[1];
  const setIsManageIssuesPanelOpen =
    useSidebarLayoutContext().manageIssuesPanel[1];

  const currentUserCanEditIssues = !!(
    !game?.whoCanEditIssues ||
    (uid && game.whoCanEditIssues.find((playerId) => playerId === uid))
  );

  const refreshIssues = useCallback(
    async (refreshIssuesParams: RefreshIssuesParams) => {
      setSelectedIssues(null);
      setIssues(null);
      setIssuesTotal(null);
      setNextIssuesPageStartAt(null);
      setIsLoadingIssues(true);
      setError(null);

      try {
        const { issues, nextStartAt, total } =
          await searchIssues(refreshIssuesParams);
        setIssues(issues);
        setIssuesTotal(total);
        setNextIssuesPageStartAt(nextStartAt);
        setSelectedIssues(issues);
        setIsLoadingIssues(false);
      } catch (e: any) {
        console.error(e);
        Sentry.withScope((scope) => {
          scope.setFingerprint(["Couldn't load issues"]);
          Sentry.captureException(e);
        });

        setError(e);
        setIsLoadingIssues(false);
      }
    },
    [],
  );

  const handleLoadMoreIssues = useCallback(async () => {
    setIsLoadingMoreIssues(true);

    try {
      const { issues, nextStartAt, total } = await searchIssues(
        mode === 'jql'
          ? { jql: jql || '', startAt: nextIssuesPageStartAt, storyPointsField }
          : {
              searchParams,
              startAt: nextIssuesPageStartAt,
              storyPointsField,
            },
      );

      setIssues((currentIssues) => {
        const newIssues = currentIssues
          ? [...currentIssues, ...issues]
          : issues;

        if (selectedIssues?.length === currentIssues?.length) {
          setSelectedIssues(newIssues);
        }

        return newIssues;
      });
      setIssuesTotal(total);
      setNextIssuesPageStartAt(nextStartAt);
    } catch (e: any) {
      console.error("Couldn't load more issues.", e);
      Sentry.withScope((scope) => {
        scope.setFingerprint(["Couldn't load more issues"]);
        Sentry.captureException(e);
      });

      setError(e);
    }

    setIsLoadingMoreIssues(false);
  }, [searchParams, nextIssuesPageStartAt, selectedIssues, mode, jql]);

  const updateSearchParams = useCallback(
    ({
      params,
      avoidRefresh,
      overwrite,
      clearFilterOnChange,
      storyPointsField,
    }: UpdateSearchParamsParams) => {
      setSearchParams((currentParams) => {
        const newParams =
          overwrite || !currentParams
            ? params
            : { ...currentParams, ...params };

        if (clearFilterOnChange) {
          newParams.filter = null;
        }

        if (!avoidRefresh) {
          refreshIssues({ searchParams: newParams, storyPointsField });
        }

        return newParams;
      });
    },
    [refreshIssues],
  );

  const handlePanelOpen = useCallback(
    async ({
      lastSelectedProjectId,
      mode,
      jql,
      updateSearchParams,
      refreshIssues,
      storyPointsField,
    }: {
      lastSelectedProjectId: string;
      mode: 'jql' | 'pickers';
      jql: string;
      updateSearchParams: (params: UpdateSearchParamsParams) => void;
      refreshIssues: (params: RefreshIssuesParams) => void;
      storyPointsField: Field | null;
    }) => {
      try {
        const [projectsApi, boards] = await Promise.all([
          getProjects().catch((e) => {
            console.error('Error getting projects at handlePanelOpen');
            console.error(e);

            return [];
          }),
          getBoards().catch((e) => {
            console.error('Error getting boards at handlePanelOpen');
            console.error(e);

            return [];
          }),
        ]);

        const projects: Project[] = projectsApi.map((project) => {
          return {
            ...project,
          };
        });

        setProjects(projects);
        setBoards(boards);

        const params: SearchParams = {};

        if (lastSelectedProjectId !== NONE) {
          params.project =
            (lastSelectedProjectId !== NONE &&
              projects.find(
                (project) => project.id === lastSelectedProjectId,
              )) ||
            null;

          params.board = params.project?.id
            ? boards.find(
                (board) =>
                  params.project?.id && board.projectId === params.project?.id,
              )
            : null;
        }

        if (mode === 'pickers') {
          await updateSearchParams({
            params,
            storyPointsField,
          });
        } else {
          refreshIssues({ jql, storyPointsField });
        }
      } catch (e: any) {
        console.error(e);
        showNotification({
          title: 'Unknown error loading search panel',
          type: 'error',
          body: `We have just released this app, Please note that we are actively working on it. Error message: ${e?.message}`,
        });
      }
    },
    [],
  );

  useEffect(() => {
    if (!lastSelectedProjectId || !isIdle) {
      return;
    }

    setIsIdle(false);
    handlePanelOpen({
      lastSelectedProjectId,
      mode,
      jql,
      updateSearchParams,
      refreshIssues,
      storyPointsField,
    });
  }, [
    handlePanelOpen,
    lastSelectedProjectId,
    isIdle,
    mode,
    jql,
    updateSearchParams,
    refreshIssues,
    storyPointsField,
  ]);

  const handleJqlSubmit = useCallback(() => {
    refreshIssues({ jql, storyPointsField });
  }, [refreshIssues, jql, storyPointsField]);

  const openJQLEditor = useCallback(() => {
    openJQLSearch({
      jql: jql,
      header: 'Search with JQL',
      onSubmit: ({ jql }) => {
        setJql(jql);

        refreshIssues({ jql, storyPointsField });
      },
      submitText: 'Submit',
      cancelText: 'Cancel',
    });
  }, [jql, refreshIssues]);

  const handleModeChange = () => {
    if (mode === 'pickers') {
      setMode('jql');
      updateCurrentUser(appId, { lastSelectedSearchMode: 'jql' });
      openJQLEditor();
    } else {
      setMode('pickers');
      updateCurrentUser(appId, { lastSelectedSearchMode: 'pickers' });

      refreshIssues(
        searchParams
          ? { searchParams, storyPointsField }
          : { storyPointsField },
      );
    }
  };

  const handleSorting = useCallback(
    (sorting: { key: keyof IssueJira; sortOrder: 'ASC' | 'DESC' }) => {
      if (sorting && selectedIssues && issues) {
        setSelectedIssues(
          sortIssues(selectedIssues, sorting.key, sorting.sortOrder),
        );
        setIssues(sortIssues(issues, sorting.key, sorting.sortOrder));
      }
    },
    [selectedIssues, issues],
  );

  if (!currentUserCanEditIssues) {
    return (
      <Stack space="space.200">
        <div />
        <SectionMessage
          title="You don't have permission to manage issues."
          actions={[
            <SectionMessageAction
              href="#"
              onClick={() => {
                setIsSettingsSidebarOpen(true);
                setIsIssuesSidebarOpen(false);
                setIsManageIssuesPanelOpen(false);
              }}
            >
              Change game settings
            </SectionMessageAction>,
          ]}
        >
          <p>
            Managing issues is only available to the players defined in the game
            settings. Select "all players" or add yourself to the list of
            players who can manage issues.
          </p>
        </SectionMessage>
      </Stack>
    );
  }

  return (
    <>
      <div className={styles['panel-header']}>
        <Inline shouldWrap={false} spread="space-between" alignBlock="center">
          <span>Add Issues</span>

          <Inline space="space.100">
            <FeedbackButton />
            <Button onClick={handleModeChange}>
              {mode === 'pickers' ? 'Switch to JQL' : 'Switch to Basic'}
            </Button>

            <Tooltip content="Done">
              {(tooltipProps) => (
                <div {...tooltipProps}>
                  <IconButton
                    label="Close"
                    onClick={() => setIsManageIssuesPanelOpen(false)}
                    icon={CrossIcon}
                    shape="circle"
                  />
                </div>
              )}
            </Tooltip>
          </Inline>
        </Inline>
      </div>
      <Stack space="space.250" alignInline="stretch">
        <SpotlightTarget name="search-pickers">
          {mode === 'pickers' ? (
            <Pickers
              searchParams={searchParams}
              onUpdateSearchParams={(params) =>
                updateSearchParams({ ...params, storyPointsField })
              }
              projects={projects}
              boards={boards}
            />
          ) : (
            <Stack space="space.200">
              <div />
              <JqlPicker
                jql={jql}
                onSubmit={handleJqlSubmit}
                onChange={setJql}
                onOpenJqlEditor={openJQLEditor}
              />
              <div />
            </Stack>
          )}
        </SpotlightTarget>

        <SearchIssuesTooltip />

        {errorMessage}

        <Results
          issues={issues}
          isLoadingIssues={isLoadingIssues}
          isLoadingMoreIssues={isLoadingMoreIssues}
          error={error}
          onError={setError}
          selectedIssues={selectedIssues}
          onChangeSelectedIssues={setSelectedIssues}
          hasMoreIssues={typeof nextIssuesPageStartAt === 'number'}
          issuesTotal={issuesTotal}
          onLoadMoreIssues={handleLoadMoreIssues}
          onIssueAdded={onIssueAdded}
          onSort={handleSorting}
        />
      </Stack>
    </>
  );
};
