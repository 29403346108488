import { Field, HelperMessage, Label } from '@atlaskit/form';
import { Inline, Stack } from '@atlaskit/primitives';
import Toggle from '@atlaskit/toggle';

import styles from './ToggleField.module.scss';

export const ToggleField = ({
  label,
  helperMessage,
  name,
  defaultValue,
  id,
  onChanged,
}: {
  label: string;
  helperMessage: string;
  name: string;
  id: string;
  defaultValue: boolean;
  onChanged: (value: boolean) => void;
}) => {
  return (
    <Inline spread="space-between" alignBlock="center">
      <div className={styles.left}>
        <Label htmlFor={id}>{label}</Label>
        <HelperMessage>{helperMessage}</HelperMessage>
      </div>
      <Field name={name} defaultValue={defaultValue}>
        {({ fieldProps: { value, onChange, ...fieldProps } }) => (
          <Toggle
            {...fieldProps}
            onChange={(val) => {
              onChange(val);
              onChanged(val.currentTarget.checked);
            }}
            id={id}
            isChecked={value}
          />
        )}
      </Field>
    </Inline>
  );
};
