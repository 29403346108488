import React from 'react';

import { Switch } from '../../atoms/Switch/Switch';
import { FlexBox } from '../../atoms/FlexBox/FlexBox';
import { Paragraph } from '../../atoms/text/Paragraph/Paragraph';
import { VerticalSpacing } from '../../atoms/spacings/VerticalSpacing/VerticalSpacing';
import { HoritzontalSpacing } from '../../atoms/spacings/HoritzontalSpacing/HoritzontalSpacing';

export interface FormSwitchProps {
  isActive: boolean;
  label: string;
  hint?: string;
  onChange?: (value: boolean) => void;
  align?: 'start' | 'center' | 'space-between';
}

export const FormSwitch = ({
  label,
  isActive,
  onChange,
  align,
  hint,
}: FormSwitchProps) => {
  const handleChange = () => {
    if (onChange) {
      onChange(!isActive);
    }
  };

  return (
    <FlexBox
      justifyContent={align}
      direction={align === 'space-between' ? 'row-reverse' : 'row'}
    >
      <div style={{ flexWrap: 'nowrap' }}>
        <Switch onChange={handleChange} isActive={isActive} />
      </div>

      <HoritzontalSpacing spacing="spacing-s" />

      <div>
        <Paragraph onClick={handleChange} style={{ cursor: 'default' }}>
          {label}
        </Paragraph>
        {hint && (
          <>
            <VerticalSpacing spacing="spacing-xxs" />
            <Paragraph
              onClick={handleChange}
              color="grey500"
              style={{ cursor: 'default' }}
              size="small"
            >
              {hint}
            </Paragraph>
          </>
        )}
      </div>
    </FlexBox>
  );
};
