import { Sidebar } from '../Sidebar/Sidebar';

import styles from './SidebarLayoutWrapper.module.scss';
import { SettingsSidebar } from '../SettingsSidebar/SettingsSidebar';
import { useSidebarLayoutContext } from './useSidebarLayoutContext';
import cx from 'classnames';

interface SidebarLayoutWrapperProps {
  children: React.ReactNode;
}

export const SidebarLayoutWrapper = ({
  children,
}: SidebarLayoutWrapperProps) => {
  const sidebarContext = useSidebarLayoutContext();

  const isSidebarOpen = sidebarContext.issuesSidebar[0];

  return (
    <>
      <div
        className={cx(
          styles['main'],
          !isSidebarOpen && styles['main--sidebar-closed'],
        )}
      >
        {children}
      </div>
      <Sidebar />
      <SettingsSidebar />
    </>
  );
};
