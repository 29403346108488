import classnames from 'classnames';
import React, { useRef, useState } from 'react';
import ReactDom from 'react-dom';
import { usePopover } from 'react-use-popover';
import { Tooltip } from '../Tooltip/Tooltip';

import styles from './ButtonIcon.module.scss';

export interface ButtonIconProps {
  /** Content of the button */

  icon: React.ReactNode;
  /** Actual color of the button */
  buttonColor?: 'primary' | 'secondary' | 'light' | 'danger';
  /** button size */
  size?: 's' | 'sm' | 'm';
  /** Text hint to show on over  */
  tooltip?: React.ReactNode;
  /** Optional change the tooltip position  */
  tooltipPosition?:
    | 'bottom-left'
    | 'bottom-right'
    | 'top-right'
    | 'right'
    | 'top'
    | 'left';
  /** Disable button interaction */
  isDisabled?: boolean;
  /** Active state usually usually used to show that something else is activated by the button (like a dropdown) */
  isActive?: boolean;
  /** Special style for when it is a floating button like the timer, TODO: extract it into a new component, maybe? */
  isFloatingButton?: boolean;
  /** When is floating, choose the opacity of inner content */
  isNoOpactity?: boolean;
  /** Extra css class */
  className?: string;
  /** Whether you want to hide tooltip on small devices */
  isDontHideTooltipOnSmallScreen?: boolean;
  /** Stick tooltip always open  */
  pinTooltip?: boolean;
  /** When button is placed on non white backgrounds, used to visually fix the active outline effect */
  containerBackgroundColor?:
    | 'grey200'
    | 'primary-white'
    | 'black'
    | 'black-opacity';
  /** avoid auto translation */
  translate?: 'no';

  /** For e2e testing */
  'data-test'?: string;
  /** Click event handler */
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

// -- Static attributes

const defaultProps: Partial<ButtonIconProps> = {
  buttonColor: 'secondary',
};

// -- Component
const ButtonFC: React.ForwardRefRenderFunction<
  HTMLButtonElement,
  ButtonIconProps
> = (props, ref) => {
  const resolvedProps = {
    ...defaultProps,
    ...props,
  };
  const {
    buttonColor,
    isDisabled,
    isActive,
    className,
    translate,
    icon,
    tooltip,
    tooltipPosition,
    onClick,
    containerBackgroundColor,
    isFloatingButton,
    isDontHideTooltipOnSmallScreen,
    pinTooltip,
    isNoOpactity,
    size,
  } = resolvedProps;

  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const [isMouseOver, setIsMouseOver] = useState<boolean | null>(null);
  const [tooltipElement, setTooltipElement] = useState<HTMLDivElement | null>(
    null,
  );

  const { dropdownStyle } = usePopover({
    anchorRef: ref ? (ref as React.RefObject<HTMLElement>) : buttonRef,
    dropdownElement: tooltipElement,
    position: tooltipPosition || 'bottom',
  });

  const handleMouseEnter = () => {
    setIsMouseOver(true);
  };

  const handleMouseLeave = () => {
    setIsMouseOver(false);
  };

  const handleOnClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (onClick) {
      onClick(event);
    }
  };

  const resolvedClassName = classnames(
    className,
    styles['button-icon'],
    styles[`color-${buttonColor || 'primary'}`],
    size && styles[`size-${size}`],
    containerBackgroundColor &&
      styles[`color-container-${containerBackgroundColor}`],
    {
      [styles['is-disabled']]: isDisabled,
      [styles['is-floating']]: isFloatingButton,
      [styles['is-no-opacity']]: isNoOpactity,
      [styles['is-active']]: isActive,
      [styles[`tooltip--${tooltipPosition}`]]: tooltipPosition,
    },
    'is-clickable',
  );

  return (
    <>
      <button
        className={resolvedClassName}
        onClick={handleOnClick}
        disabled={isDisabled}
        type="button"
        translate={translate}
        ref={ref || buttonRef}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        data-test={resolvedProps['data-test']}
      >
        {icon}
      </button>

      {tooltip &&
        (isMouseOver || pinTooltip) &&
        ReactDom.createPortal(
          <div
            ref={setTooltipElement}
            style={{ ...dropdownStyle, zIndex: 999 }}
          >
            <Tooltip isShowOnSmallScreen={isDontHideTooltipOnSmallScreen}>
              {tooltip}
            </Tooltip>
          </div>,
          document.body,
        )}
    </>
  );
};

export const ButtonIcon = React.forwardRef(ButtonFC);
