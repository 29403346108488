import Modal, {
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  ModalTransition,
} from '@atlaskit/modal-dialog';
import Form, { FormFooter, HelperMessage } from '@atlaskit/form';
import SectionMessage, {
  SectionMessageAction,
} from '@atlaskit/section-message';

import { useAuthContext } from '../../../auth/useAuthContext';
import { StoryPointsFieldPicker } from '../../../game/components/SearchPanel/components/StoryPointsFieldPicker/StoryPointsFieldPicker';
import { updateGame } from '../../../game/data/actions';
import { useGameContext } from '../../../game/hooks/useGameContext';
import { Field as FieldApi } from '../../api';
import ButtonGroup from '@atlaskit/button/button-group';
import LoadingButton from '@atlaskit/button/loading-button';
import ButtonDefault from '@atlaskit/button/standard-button';
import { useState } from 'react';
import { StoryPointsHelpModal } from '../StoryPointsHelpModal/StoryPointsHelpModal';
import { Stack } from '@atlaskit/primitives';

export const SelectStoryPointsModal = ({
  isOpen,
  onClose,
  onSubmitted,
  onSkipped,
}: {
  isOpen: boolean;
  onClose: () => void;
  onSubmitted: () => void;
  onSkipped: () => void;
}) => {
  const gameContext = useGameContext();
  const authContext = useAuthContext();
  const appId = authContext && authContext.appId;
  const gameId = gameContext && gameContext.game?.id;

  const [isHelpModalOpen, setIsHelpModalOpen] = useState(false);

  const handleSubmit = async ({
    storyPointsField,
  }: {
    storyPointsField?: FieldApi | null;
  }) => {
    if (!appId || !gameId) {
      return;
    }

    await updateGame(appId, gameId, {
      storyPointsField: storyPointsField || null,
    });

    onSubmitted();
  };

  const handleSkip = () => {
    onSkipped();
  };

  if (isHelpModalOpen) {
    return (
      <StoryPointsHelpModal
        onClose={() => {
          setIsHelpModalOpen(false);
          onClose();
        }}
        onBack={() => setIsHelpModalOpen(false)}
      />
    );
  }

  return (
    <ModalTransition>
      {isOpen && (
        <Modal onClose={onClose} autoFocus={false}>
          <ModalHeader>
            <ModalTitle>Select story points estimate field</ModalTitle>
          </ModalHeader>
          <ModalBody>
            <Form<{ storyPointsField?: FieldApi | null }>
              onSubmit={handleSubmit}
            >
              {({ submitting, formProps }) => (
                <form {...formProps}>
                  <Stack space="space.200">
                    <div>
                      <StoryPointsFieldPicker
                        defaultValue={null}
                        onChanged={() => {}}
                        message={
                          <HelperMessage>
                            You can change this later at game settings.
                          </HelperMessage>
                        }
                        isRequired
                      />{' '}
                    </div>
                    <SectionMessage
                      title="Why do you need to select the Story Points Field?"
                      appearance="discovery"
                      actions={
                        <SectionMessageAction
                          href="#"
                          onClick={() => setIsHelpModalOpen(true)}
                        >
                          Learn more
                        </SectionMessageAction>
                      }
                    >
                      <p>
                        We need to identify the field you use in your Jira to
                        track story point estimates so we can record the
                        outcomes of each voting round.
                      </p>
                    </SectionMessage>
                  </Stack>
                  <ModalFooter>
                    <FormFooter>
                      <ButtonGroup>
                        <ButtonDefault appearance="subtle" onClick={handleSkip}>
                          Skip
                        </ButtonDefault>
                        <LoadingButton
                          type="submit"
                          appearance="primary"
                          isLoading={submitting}
                        >
                          Confirm
                        </LoadingButton>
                      </ButtonGroup>
                    </FormFooter>
                  </ModalFooter>
                </form>
              )}
            </Form>
          </ModalBody>
        </Modal>
      )}
    </ModalTransition>
  );
};
