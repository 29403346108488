import React, { useState } from 'react';

import styles from './EmojiPicker.module.scss';
import { ButtonDropdown } from '../ButtonDropdown/ButtonDropdown';

import data from '@emoji-mart/data';
import Picker from '@emoji-mart/react';
import { Icon } from '../../atoms/Icon/Icon';

export interface EmojiPickerProps {
  onEmojiSelect: (emoji: string) => void;
  hideOnSelect?: boolean;
  onIsOpenChange?: (isOpen: boolean) => void;
  buttonColor?: 'secondary' | 'tooltip';
  buttonSize?: 'xs' | 's';
}

export const EmojiPicker = ({
  onEmojiSelect,
  hideOnSelect,
  buttonColor,
  onIsOpenChange,
  buttonSize,
}: EmojiPickerProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const handeIsOpenChange = (isOpen: boolean) => {
    if (onIsOpenChange) {
      onIsOpenChange(isOpen);
    }

    setIsOpen(isOpen);
  };

  const handleEmojiSelect = (emoji: any) => {
    onEmojiSelect(emoji.native);

    if (hideOnSelect) {
      handeIsOpenChange(false);
    }
  };

  return (
    <ButtonDropdown
      buttonComponent="floating"
      isOpen={isOpen}
      buttonSize={buttonSize || 's'}
      buttonStyle={buttonColor || 'secondary'}
      onIsOpenChange={() => handeIsOpenChange(!isOpen)}
      dropdown={
        <div className={styles['picker-wrapper']}>
          <Picker
            data={data}
            onEmojiSelect={handleEmojiSelect}
            autoFocus
            dynamicWidth
            navPosition="none"
            previewPosition="none"
            maxFrequentRows={2}
          />
        </div>
      }
    >
      <Icon icon="face" />
    </ButtonDropdown>
  );
};
