import { setDoc, doc } from 'firebase/firestore';
import { firestore } from '@we-agile-you/firebase';
import {
  WhoCanShowCardsType,
  WhoCanEditIssuesType,
} from '@we-agile-you/types-planning-poker';

interface Settings {
  deck: string[];
  name: string;
  whoCanShowCards: WhoCanShowCardsType;
  whoCanEditIssues: WhoCanEditIssuesType;
  ownerId: string;
  enableProjectiles: boolean;
  hideAverageInResults: boolean;
  autoShowCards: boolean;
}

export const changeSettings = (tableId: string, settings: Settings) =>
  setDoc(
    doc(firestore, 'poker-tables', tableId),
    {
      ...settings,
    },
    { merge: true },
  );
