import { Field } from '@atlaskit/form';
import Select from '@atlaskit/select';
import { useEffect, useState } from 'react';
import { Filter, getFilters } from '../../../../../../jira/api';

import styles from '../Pickers.module.scss';
export const FilterPicker = ({
  onChange,
  selectedFilter,
}: {
  onChange: (filter: Filter | null) => void;
  selectedFilter?: Filter | null;
}) => {
  const [filters, setFilters] = useState<Filter[] | null>(null);
  const [error, setError] = useState<Error | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);

    getFilters()
      .then((filters) => {
        setFilters(filters);
      })
      .catch((e) => {
        console.error('filters error', e);
        setError(e);
        setFilters(null);
      })
      .finally(() => setIsLoading(false));
  }, []);

  const filtersOptions = filters?.map((filter) => ({
    value: filter,
    label: filter.name,
  }));

  const resolveNoOptionsMessage = () => {
    if (error) {
      return error.message;
    }

    return 'There are no filters owned by your user';
  };

  return (
    <div className={styles['filter-select']}>
      <Field aria-required={true} name="filter" label="My filters">
        {() => (
          <Select
            placeholder="Filter"
            options={filtersOptions || []}
            isLoading={isLoading}
            onChange={(option) => {
              const value = option?.value;
              onChange(value || null);
            }}
            value={
              filtersOptions?.find(
                (option) => selectedFilter?.id === option.value.id,
              ) || null
            }
            noOptionsMessage={resolveNoOptionsMessage}
            isClearable
          />
        )}
      </Field>
    </div>
  );
};
