import Modal, {
  ModalBody,
  ModalHeader,
  ModalTitle,
} from '@atlaskit/modal-dialog';
import { CustomDeck } from '../../../types';
import { CreateDeckForm } from '../CreateDeckForm/CreateDeckForm';

interface ImportFromFileModalProps {
  onClose: () => void;
  onCreated: (deck: CustomDeck) => void;
}
export const CreateDeckModal = ({
  onClose,
  onCreated,
}: ImportFromFileModalProps) => {
  return (
    <Modal onClose={onClose} width="big">
      <ModalHeader>
        <ModalTitle>Create custom deck</ModalTitle>
      </ModalHeader>
      <ModalBody>
        <CreateDeckForm onCancel={onClose} onCreated={onCreated} />
      </ModalBody>
    </Modal>
  );
};
