import { useEffect } from 'react';
import { useGameContext } from '../../hooks/useGameContext';
import { useAuthContext } from '../../../auth/useAuthContext';
import { Spotlight, SpotlightTransition } from '@atlaskit/onboarding';
import { useOneTimeUserAction } from '../../../auth/useOneTimeUserAction';
import { N0 } from '@atlaskit/theme/colors';

export const InviteTooltip = () => {
  const [isNewGameTooltipDone] = useOneTimeUserAction('startNewGameTooltip');
  const [isDone, setIsDone] = useOneTimeUserAction('inviteTooltip');
  const gameContext = useGameContext();
  const auth = useAuthContext();
  const game = gameContext?.game;
  const user = auth.user;

  const isLoading = !game || !user;
  const isOwner = game?.ownerId === user?.uid;
  const playersLength = gameContext?.players?.length;
  const isCardsUp = game?.cardsUp;

  const isOpen =
    !isLoading &&
    isDone === false &&
    isOwner &&
    isNewGameTooltipDone &&
    isCardsUp === false;

  useEffect(() => {
    if (
      isDone === false &&
      typeof playersLength === 'number' &&
      playersLength > 1 &&
      isOwner
    ) {
      setIsDone();
    }
  }, [playersLength, isDone, setIsDone, isOwner]);

  return (
    <SpotlightTransition>
      {isOpen && (
        <Spotlight
          actions={[
            {
              onClick: setIsDone,
              text: 'OK',
            },
          ]}
          heading="All set!"
          target="invite-button"
          key="invite"
          targetOnClick={setIsDone}
          targetRadius={3}
          targetBgColor={N0}
          dialogPlacement="bottom center"
        >
          Share this game's url with your teammates to start estimating.
        </Spotlight>
      )}
    </SpotlightTransition>
  );
};
