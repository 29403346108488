import classnames from 'classnames';
import React, { useRef, useState } from 'react';
import ReactDom from 'react-dom';
import { usePopover } from 'react-use-popover';
import { Spinner } from '../../components/Spinner/Spinner';
import { Tooltip } from '../Tooltip/Tooltip';

import styles from './Button.module.scss';

export interface ButtonProps {
  /** Content of the button */
  children?: React.ReactNode;
  /** General style of the button */
  buttonStyle?: 'primary' | 'secondary' | 'tertiary';
  /** Actual color of the button */
  buttonColor?: 'primary' | 'secondary' | 'light' | 'danger';
  /** button size */
  size?: 'body' | 'small';
  /** The html type attribute */
  buttonType?: 'button' | 'submit' | 'reset';
  /** Include an Icon  */
  icon?: React.ReactNode;
  /** Text hint to show on over  */
  tooltip?: React.ReactNode;
  /** Loading state that also disables the button */
  isLoading?: boolean;
  /** Disable button interaction */
  isDisabled?: boolean;
  /** Full width */
  isBlock?: boolean;
  /** Active state usually usually used to show that something else is activated by the button */
  isActive?: boolean;
  /** Extra css class */
  className?: string;
  /** To trigger external form submission when type is submit */
  form?: string;
  /** Optional change the tooltip position when default position doesn't look good  */
  tooltipPosition?: 'bottom-left';
  /** For e2e testing */
  'data-test'?: string;
  /** When button is placed on non white backgrounds, used to visually fix the active outline effect */
  containerBackgroundColor?:
    | 'grey200'
    | 'grey100'
    | 'primary-white'
    | 'primary-whiter';
  /** avoid auto translation */
  translate?: 'no';
  /** Click event handler */
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

// -- Static attributes

const defaultProps: Partial<ButtonProps> = {
  buttonStyle: 'primary',
  buttonColor: 'primary',
  buttonType: 'button',
};

// -- Component
const ButtonFC: React.ForwardRefRenderFunction<
  HTMLButtonElement,
  ButtonProps
> = (props, ref) => {
  const resolvedProps = {
    ...defaultProps,
    ...props,
  };
  const {
    buttonStyle,
    buttonColor,
    buttonType,
    isLoading,
    isDisabled,
    isActive,
    isBlock,
    children,
    className,
    translate,
    icon,
    tooltip,
    tooltipPosition,
    containerBackgroundColor,
    size,
    form,
    onClick,
  } = resolvedProps;

  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const [isMouseOver, setIsMouseOver] = useState<boolean | null>(null);
  const [tooltipElement, setTooltipElement] = useState<HTMLDivElement | null>(
    null,
  );

  const { dropdownStyle: tooltipStyle } = usePopover({
    anchorRef: ref ? (ref as React.RefObject<HTMLElement>) : buttonRef,
    dropdownElement: tooltipElement,
    position: tooltipPosition,
  });

  const handleMouseEnter = () => {
    setIsMouseOver(true);
  };

  const handleMouseLeave = () => {
    setIsMouseOver(false);
  };

  const handleOnClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (!isLoading && !isDisabled && onClick) {
      onClick(event);
    }
  };

  const resolvedClassName = classnames(
    className,
    styles['button'],
    styles[`style-${buttonStyle}`],
    styles[`color-${buttonColor}`],
    containerBackgroundColor &&
      styles[`over-background-color-${containerBackgroundColor}`],
    size && styles[`size-${size}`],
    {
      [styles['is-loading']]: isLoading,
      [styles['is-disabled']]: isDisabled,
      [styles['is-active']]: isActive,
      [styles['is-block']]: isBlock,
      [styles[`tooltip--${tooltipPosition}`]]: tooltipPosition,
    },
    'is-clickable',
  );

  return (
    <button
      className={resolvedClassName}
      onClick={handleOnClick}
      disabled={isLoading || isDisabled}
      type={buttonType}
      translate={translate}
      form={form}
      ref={ref || buttonRef}
      data-test={resolvedProps['data-test']}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {isLoading && (
        <span className={styles['spinner-wrapper']}>
          <Spinner className={styles['spinner']} size="medium" />
        </span>
      )}
      <span className={classnames(styles['content'], 'is-clickable')}>
        {icon && (
          <span className={classnames(styles['icon-elemnent'], 'is-clickable')}>
            {icon}
          </span>
        )}
        {children && (
          <span
            className={classnames(
              styles['label'],
              icon && styles['label--icon'],
              'is-clickable',
            )}
          >
            {children}
          </span>
        )}
      </span>

      {isMouseOver &&
        tooltip &&
        ReactDom.createPortal(
          <div ref={setTooltipElement} style={{ ...tooltipStyle, zIndex: 999 }}>
            <Tooltip className={styles.tooltip}>{tooltip}</Tooltip>
          </div>,
          document.body,
        )}
    </button>
  );
};

export const Button = React.forwardRef(ButtonFC);
