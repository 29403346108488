import Avatar from '@atlaskit/avatar';
import Heading from '@atlaskit/heading';
import Modal, {
  ModalBody,
  ModalHeader,
  ModalTitle,
} from '@atlaskit/modal-dialog';
import { Inline, Stack } from '@atlaskit/primitives';
import Button, { IconButton } from '@atlaskit/button/new';
import { useState } from 'react';
import { useAuthContext } from '../../../auth/useAuthContext';
import { CreateDeckForm } from '../CreateDeckForm/CreateDeckForm';
import MoreIcon from '@atlaskit/icon/glyph/more';
import DropdownMenu, {
  DropdownItem,
  DropdownItemGroup,
} from '@atlaskit/dropdown-menu';
import { CustomDeck } from '../../../types';
import { deleteCustomDeckFromApp } from '../../../app/data';

interface ImportFromFileModalProps {
  onClose: () => void;
}
export const ManageDecksModal = ({ onClose }: ImportFromFileModalProps) => {
  const authContext = useAuthContext();
  const customDecks = authContext.appData?.customDecks;
  const uid = authContext.uid;
  const appId = authContext.appId;
  const [step, setStep] = useState<'create' | 'manage'>('manage');

  const handleDelete = (deck: CustomDeck) => {
    if (!appId) {
      return;
    }

    if (confirm('Are you sure you want to delete this deck?')) {
      deleteCustomDeckFromApp(appId, deck);
    }
  };

  const isEmpty = !customDecks || customDecks.length === 0;

  return (
    <Modal onClose={onClose} width="medium">
      <ModalBody>
        {step === 'manage' && (
          <Stack space="space.200">
            <div />
            <Inline spread="space-between" alignBlock="center">
              <ModalTitle>Manage decks</ModalTitle>
              {!isEmpty && (
                <Button appearance="primary" onClick={() => setStep('create')}>
                  Create deck
                </Button>
              )}
            </Inline>
            {isEmpty ? (
              <Stack space="space.250">
                <div />
                <div />
                <Stack space="space.100">
                  <Inline alignInline="center">
                    <p style={{ margin: 0, padding: 0 }}>
                      No decks created yet
                    </p>
                  </Inline>
                  <Inline alignInline="center">
                    <p style={{ margin: 0, padding: 0 }}>
                      Create a deck and define your custom set of cards
                    </p>
                  </Inline>
                </Stack>
                <Inline alignInline="center">
                  <Button
                    appearance="primary"
                    onClick={() => setStep('create')}
                  >
                    Create deck
                  </Button>
                </Inline>
                <div />
                <div />
              </Stack>
            ) : (
              <Stack space="space.100">
                {customDecks.map((deck) => (
                  <div key={`${deck.creatorId}_${deck.name}_${deck.value}`}>
                    <Inline spread="space-between" alignBlock="center">
                      <Stack>
                        <div>{`${deck.name} (${deck.value})`} </div>
                        <div>
                          <Inline alignBlock="center">
                            <Avatar size="xsmall" src={deck.creatorAvatar} />
                            <Heading level="h100">{deck.creatorName}</Heading>
                          </Inline>
                        </div>
                      </Stack>
                      {deck.creatorId === uid ? (
                        <DropdownMenu<HTMLButtonElement>
                          trigger={({ triggerRef, ...props }) => (
                            <IconButton
                              {...props}
                              icon={MoreIcon}
                              label="more"
                              ref={triggerRef}
                            />
                          )}
                        >
                          <DropdownItemGroup>
                            {deck.creatorId === uid && (
                              <DropdownItem onClick={() => handleDelete(deck)}>
                                Delete
                              </DropdownItem>
                            )}
                          </DropdownItemGroup>
                        </DropdownMenu>
                      ) : (
                        <div />
                      )}
                    </Inline>
                  </div>
                ))}
              </Stack>
            )}

            <div />
            <div />
          </Stack>
        )}
        {step === 'create' && (
          <div>
            <ModalHeader>
              <ModalTitle>Create custom deck</ModalTitle>
            </ModalHeader>
            <CreateDeckForm
              onCancel={() => setStep('manage')}
              onCreated={() => setStep('manage')}
            />
          </div>
        )}
      </ModalBody>
    </Modal>
  );
};
