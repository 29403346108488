import { useEffect, useState } from 'react';
import { Game, Player } from '../../types';
import {
  query,
  collection,
  where,
  doc,
  onSnapshot,
  Timestamp,
} from 'firebase/firestore';
import { firestore } from '../../../firebase';
import { JIRA_CONNECT_APPS_DATA_COLLECTION } from '../../constants';
import { Projectile } from '@we-agile-you/types-planning-poker';

type GameServer = Game & { lastShowCards: Timestamp };

export const useGameSubscription = ({
  gameId,
  appId,
  uid,
  onProjectilesChange,
}: {
  gameId: string;
  appId: string | false | null;
  uid: string | false | null;
  onProjectilesChange: (projectiles: Projectile[]) => void;
}) => {
  const [game, setGame] = useState<null | Game>(null);
  const [players, setPlayers] = useState<null | Player[]>(null);
  const [playersAll, setPlayersAll] = useState<null | Player[]>(null);

  useEffect(() => {
    if (!appId || !gameId) {
      return;
    }

    const gameRef = doc(
      collection(
        firestore,
        `${JIRA_CONNECT_APPS_DATA_COLLECTION}/${appId}/games`,
      ),
      gameId,
    );

    const playersRef = query(
      collection(
        firestore,
        `${JIRA_CONNECT_APPS_DATA_COLLECTION}/${appId}/players`,
      ),
      where('gameId', '==', gameId),
    );

    let unsubscribeToGame = [
      onSnapshot(
        gameRef,
        function (doc) {
          const data = doc.data() as Partial<GameServer>;

          const game: Game = {
            id: gameRef.id,
            ...data,
            lastShowCards: data?.lastShowCards?.toDate(),
            // timerStartedAt: data.timerStartedAt?.toDate(),
          } as Game;

          setGame(game);
        },
        (e) => {
          console.error('Error subscribing to game');
          console.error(e);
        },
      ),
      onSnapshot(playersRef, function (querySnapshot) {
        const players: Player[] = [];

        querySnapshot.forEach(function (doc) {
          const data = doc.data() as Player;

          players.push(data);
        });

        querySnapshot.docChanges().forEach((change) => {
          if (change.type === 'added') {
            const newData = change.doc.data() as Player;
            if (newData.projectilesShot && newData.projectilesShot.length > 0) {
              onProjectilesChange(
                newData.projectilesShot.map((projectile) => ({
                  ...projectile,
                  isThrown: true,
                })),
              );
            }
          }
          if (change.type === 'modified') {
            const newData = change.doc.data() as Player;
            if (newData.projectilesShot && newData.projectilesShot.length > 0) {
              onProjectilesChange(newData.projectilesShot);
            }
          }
        });

        setPlayersAll(players);
        setPlayers(
          players.filter((player) => {
            if (player.state === 'online') return true;

            if (
              typeof player.vote === 'string' ||
              typeof player.vote === 'number'
            ) {
              return true;
            }

            return false;
          }),
        );
      }),
    ];

    return function unsubscribeFromPokerGame() {
      unsubscribeToGame.forEach((unsubscribe) => unsubscribe());

      unsubscribeToGame = [];
    };
  }, [gameId, appId]);

  const currentPlayer =
    (!!uid && !!players && players.find((player) => player.uid === uid)) ||
    null;

  const currentIssueIndexTemp: number | null | undefined =
    game?.issues && game?.issues?.findIndex((issue) => issue.isVotingNow);
  const currentIssueIndex: number | null =
    typeof currentIssueIndexTemp !== 'number' || currentIssueIndexTemp < 0
      ? null
      : currentIssueIndexTemp;

  const isVoting = players
    ? !!players.some((player) => typeof player.vote === 'string')
    : null;

  const isOnCountdown = !!game?.cardsUpCountdown;

  // Backwards compatibility
  const deckCards: string[] =
    (Array.isArray(game?.deck)
      ? (game?.deck as string[])
      : game?.deck?.value
          .split(',')
          .map((card: string) =>
            typeof card === 'string' ? card.trim() : card,
          )) || [];

  return {
    game,
    players,
    playersAll,
    currentPlayer,
    currentIssueIndex,
    isVoting,
    isOnCountdown,
    deckCards,
  };
};
