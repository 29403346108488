import { useGameContext } from '../../hooks/useGameContext';
import { useAuthContext } from '../../../auth/useAuthContext';
import { Spotlight, SpotlightTransition } from '@atlaskit/onboarding';
import { useOneTimeUserAction } from '../../../auth/useOneTimeUserAction';
import { N0 } from '@atlaskit/theme/colors';
import { useSidebarLayoutContext } from '../SidebarLayoutWrapper/useSidebarLayoutContext';
import { useEffect, useState } from 'react';

export const SearchIssuesTooltip = () => {
  const [isDone, setIsDone] = useOneTimeUserAction('searchIssuesTooltip');
  const [isOpen, setIsOpen] = useState(false);
  const gameContext = useGameContext();
  const auth = useAuthContext();
  const sidebarContext = useSidebarLayoutContext();
  const isSearchPanelOpen = sidebarContext.manageIssuesPanel[0];
  const game = gameContext?.game;
  const user = auth.user;

  const isLoading = !game || !user;
  const isOwner = game?.ownerId === user?.uid;

  const isOpenTemp =
    !isLoading && isDone === false && isOwner && isSearchPanelOpen;

  useEffect(() => {
    if (isOpenTemp) {
      window.setTimeout(() => {
        setIsOpen(true);
      }, 1000);
    } else {
      setIsOpen(false);
    }
  }, [isOpenTemp]);

  return (
    <SpotlightTransition>
      {isOpen && (
        <Spotlight
          actions={[
            {
              onClick: setIsDone,
              text: 'OK',
            },
          ]}
          target="search-pickers"
          key="add-issues"
          targetOnClick={setIsDone}
          targetRadius={3}
          targetBgColor={N0}
          dialogPlacement="left middle"
        >
          Use this panel to find the issues that you want to add to the game
        </Spotlight>
      )}
    </SpotlightTransition>
  );
};
