import { TIME_FOR_NEW_MEETING_MILIS } from '@we-agile-you/constants-planning-poker';
import { Timestamp } from 'firebase/firestore';
import { getObjectWithoutUndefinedValues, uuidv4 } from '@we-agile-you/js-base';
import { ProjectileType } from '@we-agile-you/types-planning-poker';
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDocs,
  query,
  serverTimestamp,
  updateDoc,
  where,
  getDoc,
} from 'firebase/firestore';
import { auth, firestore } from '../../../firebase';
import {
  DEFAULT_DECK,
  JIRA_CONNECT_APPS_DATA_COLLECTION,
  NON_STORY_POINTS_VALUES,
} from '../../constants';
import {
  Game,
  GameServer,
  Issue,
  Player,
  Results,
  SyncErrorMessage,
  User,
} from '../../types';
import {
  getAverageFromResults,
  getCardWithMoreVotes,
  getClosestCardToAverage,
  mapPlayersToResults,
} from './utils';
import { Field, updateIssueStoryPointsInJira } from '../../jira/api';

export const createGame = async (
  appId: string,
  game: Partial<Game>,
  ownerUser: User,
) => {
  const signedInUser = auth.currentUser;

  if (!signedInUser) {
    throw new Error('No valid user is signed in.');
  }

  const newGame: Partial<GameServer> = {
    createdAt: serverTimestamp(),
    ownerId: signedInUser.uid,
    ownerName: ownerUser.displayName,
    ownerAvatar: ownerUser.avatar,
    storyPointsField: game.storyPointsField || null,
    deck: DEFAULT_DECK,
    ...game,
  };

  const docRef = await addDoc(
    collection(
      firestore,
      `${JIRA_CONNECT_APPS_DATA_COLLECTION}/${appId}/games`,
    ),
    newGame,
  );

  return docRef.id;
};

export const deleteGame = (appId: string, gameId: string) => {
  const signedInUser = auth.currentUser;

  if (!signedInUser) {
    return Promise.reject();
  }

  return deleteDoc(
    doc(
      collection(
        firestore,
        `${JIRA_CONNECT_APPS_DATA_COLLECTION}/${appId}/games`,
      ),
      gameId,
    ),
  );
};

export const updateGame = (
  appId: string,
  gameId: string,
  game: Partial<Game>,
) => {
  return updateDoc(
    doc(
      collection(
        firestore,
        `${JIRA_CONNECT_APPS_DATA_COLLECTION}/${appId}/games`,
      ),
      gameId,
    ),
    game,
  );
};

/**
 * playerVote: updates the card selected by the currently signed in player
 * @param gameId table at what the player is voting
 * @param vote the card the player has voted
 */
export const playerVote = (
  appId: string,
  gameId: string,
  vote: string | null,
) => {
  const signedInUser = auth.currentUser;

  if (!signedInUser) {
    return Promise.reject();
  }

  const playerId = `${signedInUser.uid}_${gameId}`;

  return updateDoc(
    doc(
      collection(
        firestore,
        `${JIRA_CONNECT_APPS_DATA_COLLECTION}/${appId}/players`,
      ),
      playerId,
    ),
    {
      vote,
    },
  );
};

const updatePlayersVotes = async (
  appId: string,
  gameId: string,
  vote = null,
) => {
  const querySnapshot = await getDocs(
    query(
      collection(
        firestore,
        `${JIRA_CONNECT_APPS_DATA_COLLECTION}/${appId}/players`,
      ),
      where('gameId', '==', gameId),
    ),
  );

  await Promise.all([
    querySnapshot.docs.map((doc) =>
      updateDoc(doc.ref, {
        vote,
      }),
    ),
  ]);
};
export const hideCards = async (appId: string, gameId: string) => {
  const results: Results = {
    total: 0,
    votes: [],
  };

  await Promise.all([
    updateDoc(
      doc(
        collection(
          firestore,
          `${JIRA_CONNECT_APPS_DATA_COLLECTION}/${appId}/games`,
        ),
        gameId,
      ),
      {
        cardsUp: false,
        results,
      },
    ),
    updatePlayersVotes(appId, gameId),
  ]);
};

export const updateIssueStoryPoints = async ({
  appId,
  gameId,
  issueId,
  storyPoints,
  storyPointsField,
}: {
  appId: string;
  gameId: string;
  issueId: string;
  storyPoints: string | null;
  storyPointsField: Field | null;
}) => {
  const updateGameIssue = async ({
    syncStatus,
    syncErrorMessage,
  }: {
    syncStatus: 'idle' | 'synced' | 'syncing' | 'notSynced' | 'error';
    syncErrorMessage?: SyncErrorMessage;
  }) => {
    const game = (
      await getDoc(
        doc(
          firestore,
          `${JIRA_CONNECT_APPS_DATA_COLLECTION}/${appId}/games/${gameId}`,
        ),
      )
    ).data() as Game;

    if (!game.issues) {
      console.error(
        "No issues found in the game. Can't update issue story points.",
      );
      return;
    }

    const synchingIssues: Issue[] = game.issues.map((issue) =>
      issue.id === issueId
        ? { ...issue, syncStatus, syncErrorMessage: syncErrorMessage || null }
        : issue,
    );

    await updateGame(appId, gameId, {
      issues: synchingIssues,
    });
  };

  if (storyPoints && NON_STORY_POINTS_VALUES.includes(storyPoints)) {
    await updateGameIssue({ syncStatus: 'idle' });

    return;
  }

  try {
    await updateGameIssue({ syncStatus: 'syncing' });

    await updateIssueStoryPointsInJira(issueId, storyPoints, storyPointsField);

    await updateGameIssue({ syncStatus: 'synced' });
  } catch (error: any) {
    await updateGameIssue({
      syncStatus: 'error',
      syncErrorMessage: error?.message || 'UNKNOWN',
    });
  }
};

const updateUnanimousVotings = async ({
  appId,
  gameId,
  results,
  lastShowCardsDate,
  unanimousVotingsForGame,
}: {
  appId: string;
  gameId: string;
  results: Results;
  lastShowCardsDate: Date | null;
  unanimousVotingsForGame?: number;
}) => {
  const votingDuration = lastShowCardsDate
    ? Date.now() - lastShowCardsDate.getTime()
    : null;
  const isNewMeeting =
    !votingDuration || votingDuration > TIME_FOR_NEW_MEETING_MILIS;
  const isFullConsensus = results?.votes?.length === 1;

  if (isFullConsensus && isNewMeeting) {
    updateGame(appId, gameId, {
      unanimousVotingsForCurrentMeeting: 1,
    });
  } else if (isFullConsensus && !isNewMeeting) {
    updateGame(appId, gameId, {
      unanimousVotingsForCurrentMeeting: (unanimousVotingsForGame || 0) + 1,
    });
  } else if (!isFullConsensus && isNewMeeting) {
    updateGame(appId, gameId, {
      unanimousVotingsForCurrentMeeting: 0,
    });
  }
};

export const showCardsData = (
  appId: string,
  game: Game,
  results: Results,
  issues?: Issue[] | null,
) => {
  const update: Partial<Game> = {
    cardsUp: true,
    cardsUpCountdown: 0,
    results,
  };

  const hasMoreThan1Player = results?.total && results?.total > 1;

  if (issues) {
    update.issues = issues;
  }

  if (issues && hasMoreThan1Player) {
    update.issuesVotedCount = game.issuesVotedCount
      ? game.issuesVotedCount + 1
      : 1;
  }

  const date = game.lastShowCards || null;

  updateUnanimousVotings({
    appId,
    gameId: game.id,
    results,
    lastShowCardsDate: date,
    unanimousVotingsForGame: game.unanimousVotingsForCurrentMeeting,
  }).catch((e) => console.error(e));

  return updateGame(appId, game.id, getObjectWithoutUndefinedValues(update));
};
const waitOneSecond = () => new Promise((res) => window.setTimeout(res, 600));
export const showCards = async ({
  appId,
  game,
  players,
  showNow,
  currentIssueIndex,
  deckCards,
}: {
  appId: string;
  game: Game;
  players: Player[];
  showNow?: boolean;
  currentIssueIndex: number | null;
  deckCards: string[];
}) => {
  if (!showNow) {
    updateGame(appId, game.id, {
      cardsUpCountdown: 2,
    });

    await waitOneSecond();

    updateGame(appId, game.id, {
      cardsUpCountdown: 1,
    });

    await waitOneSecond();
  }

  const results = mapPlayersToResults(players);

  const isVotingIssue =
    currentIssueIndex !== null &&
    currentIssueIndex >= 0 &&
    results.votes.length > 0 &&
    game.issues?.length;

  if (!isVotingIssue || !game.issues || currentIssueIndex === null) {
    return showCardsData(appId, game, results, null);
  }

  const average = getAverageFromResults(results);
  const closestCardToAverage = getClosestCardToAverage(average, deckCards);

  const mostVotedCard =
    closestCardToAverage !== null
      ? closestCardToAverage
      : getCardWithMoreVotes(results);

  const hasToUpdateIssue =
    mostVotedCard !== '☕' && game.issues[currentIssueIndex];

  if (hasToUpdateIssue) {
    game.issues[currentIssueIndex].storyPoints = mostVotedCard;
    game.issues[currentIssueIndex].syncStatus = 'notSynced';
  }

  await showCardsData(appId, game, results, game.issues);

  if (hasToUpdateIssue) {
    updateIssueStoryPoints({
      appId,
      issueId: game.issues[currentIssueIndex].id,
      storyPoints: mostVotedCard,
      gameId: game.id,
      storyPointsField: game.storyPointsField || null,
    });
  }
};

export const playerShootProjectile = async (
  appId: string,
  gameId: string,
  targetPlayerId: string,
  projectileType: ProjectileType,
  emoji?: string,
) => {
  const signedInUser = auth.currentUser;

  if (!signedInUser) {
    return Promise.reject();
  }
  const playerId = `${signedInUser.uid}_${gameId}`;
  const playerRef = doc(
    collection(
      firestore,
      `${JIRA_CONNECT_APPS_DATA_COLLECTION}/${appId}/players`,
    ),
    playerId,
  );
  const existingPlayer = await getDoc(playerRef).then(
    (doc) => doc.data() as Player,
  );

  if (existingPlayer) {
    const updatedProjectiles = (existingPlayer.projectilesShot || []).slice(
      0,
      19,
    );
    updatedProjectiles.push({
      id: uuidv4(),
      shooterPlayerId: signedInUser.uid,
      targetPlayerId,
      projectileType,
      emoji: emoji || null,
      createdAt: Timestamp.now(),
    });
    return updateDoc(playerRef, {
      projectilesShot: updatedProjectiles,
    });
  }
  return null;
};

export const deletePlayer = (
  appId: string,
  playerUid: string,
  gameId: string,
) => {
  const signedInUser = auth.currentUser;

  if (!signedInUser) {
    return Promise.reject();
  }

  const playerId = `${playerUid}_${gameId}`;

  const playerRef = doc(
    collection(
      firestore,
      `${JIRA_CONNECT_APPS_DATA_COLLECTION}/${appId}/players`,
    ),
    playerId,
  );

  return deleteDoc(playerRef);
};
