import { auth, firestore } from '@we-agile-you/firebase';
import {
  serverTimestamp,
  Timestamp,
  addDoc,
  collection,
  updateDoc,
  doc,
  deleteField,
  deleteDoc,
  where,
  getDocs,
  query,
  orderBy,
  limit,
  documentId,
  getDoc,
} from 'firebase/firestore';
import { getObjectWithoutUndefinedValues, uuidv4 } from '@we-agile-you/js-base';

import {
  User,
  PokerTable,
  PokerTableResults,
  VotingForUpdate,
  Issue,
  PokerTableSummary,
  VotingServer,
  Voting,
  ProjectileType,
  Player,
} from '@we-agile-you/types-planning-poker';
import { DEFAULT_CARD_LIST } from '../constants';
import { TIME_FOR_NEW_MEETING_MILIS } from '@we-agile-you/constants-planning-poker';

export const POKER_GAMES_COLLECTION = 'poker-tables';
export const PLAYERS_COLLECTION = 'players';
export const VOTINGS_COLLECTION = 'votings';

/**
 * createPokerGame
 * @param table default game attributes for the newly created game
 * @param firebase set another firebase project namespace
 */
export const createPokerGame = (game: Partial<PokerTable>, user: User) => {
  const signedInUser = auth.currentUser;

  if (!signedInUser) {
    return Promise.reject('No valid user is signed in.');
  }

  const newGame = {
    createdAt: serverTimestamp(),
    ownerId: signedInUser.uid,
    deck: DEFAULT_CARD_LIST,
    subscriptionCurrentPeriodEnd: user.subscriptionCurrentPeriodEnd
      ? Timestamp.fromDate(user.subscriptionCurrentPeriodEnd)
      : null,
    ...game,
  };

  return addDoc(collection(firestore, POKER_GAMES_COLLECTION), newGame).then(
    (pokerTableDocRef) => pokerTableDocRef.id,
  );
};

/**
 * startTimerForGame
 * @param tableId
 * @param timerDurationMinutes
 */
export const startTimerForGame = (
  gameId: string,
  timerDurationMinutes: number,
) => {
  const signedInUser = auth.currentUser;

  if (!gameId) return Promise.reject();
  if (!signedInUser) {
    return Promise.reject('No valid user is signed in.');
  }
  return updateDoc(doc(collection(firestore, POKER_GAMES_COLLECTION), gameId), {
    timerStartedAt: serverTimestamp(),
    timerDurationMinutes,
    timerCurrentDurationMinutes: timerDurationMinutes,
  });
};

/**
 * cancelTimerForGame
 * @param tableId
 */
export const cancelTimerForGame = (gameId: string) => {
  const signedInUser = auth.currentUser;

  if (!gameId) return Promise.reject();

  if (!signedInUser) {
    return Promise.reject('No valid user is signed in.');
  }

  return updateDoc(doc(collection(firestore, POKER_GAMES_COLLECTION), gameId), {
    timerStartedAt: deleteField(),
  });
};

/**
 * updateTimer
 * @param gameId
 * @param timerDurationMinutes
 */
export const updateCurrentTimerForGame = (
  gameId: string,
  timerCurrentDurationMinutes: number,
) => {
  const signedInUser = auth.currentUser;

  if (!gameId) return Promise.reject();

  if (!signedInUser) {
    return Promise.reject('No valid user is signed in.');
  }

  return updateDoc(doc(collection(firestore, POKER_GAMES_COLLECTION), gameId), {
    timerCurrentDurationMinutes,
  });
};
/**
 * updateTimerAutoRestart
 * @param gameId
 * @param timerAutoRestart
 */
export const updateTimerAutoRestart = (
  gameId: string,
  timerAutoRestart: boolean,
) => {
  const signedInUser = auth.currentUser;

  if (!gameId) return Promise.reject();

  if (!signedInUser) {
    return Promise.reject('No valid user is signed in.');
  }
  return updateDoc(doc(collection(firestore, POKER_GAMES_COLLECTION), gameId), {
    timerAutoRestart,
  });
};

/**
 * deletePokerGame
 * @param gameid id of the game to delete
 */
export const deletePokerGame = (gameId: string) => {
  const signedInUser = auth.currentUser;

  if (!signedInUser) {
    return Promise.reject();
  }

  return deleteDoc(doc(collection(firestore, POKER_GAMES_COLLECTION), gameId));
};

/**
 * playerVote: updates the card selected by the currently signed in player
 * @param tableId table at what the player is voting
 * @param vote the card the player has voted
 */
export const playerVote = (gameId: string, vote: string | null) => {
  const signedInUser = auth.currentUser;

  if (!signedInUser) {
    return Promise.reject();
  }

  const playerId = `${signedInUser.uid}_${gameId}`;

  return updateDoc(doc(collection(firestore, PLAYERS_COLLECTION), playerId), {
    vote,
  });
};

export const deletePlayer = (playerUid: string, gameId: string) => {
  const signedInUser = auth.currentUser;

  if (!signedInUser) {
    return Promise.reject();
  }

  const playerId = `${playerUid}_${gameId}`;

  return deleteDoc(doc(collection(firestore, PLAYERS_COLLECTION), playerId));
};

export const playerShootProjectile = async (
  gameId: string,
  targetPlayerId: string,
  projectileType: ProjectileType,
  emoji?: string,
) => {
  const signedInUser = auth.currentUser;

  if (!signedInUser) {
    return Promise.reject();
  }
  const playerId = `${signedInUser.uid}_${gameId}`;
  const playerRef = doc(collection(firestore, PLAYERS_COLLECTION), playerId);
  const existingPlayer = await getDoc(playerRef).then(
    (doc) => doc.data() as Player,
  );

  if (existingPlayer) {
    const updatedProjectiles = (existingPlayer.projectilesShot || []).slice(
      0,
      19,
    );
    updatedProjectiles.push({
      id: uuidv4(),
      shooterPlayerId: signedInUser.uid,
      targetPlayerId,
      projectileType,
      emoji: emoji || null,
      createdAt: Timestamp.now(),
    });
    return updateDoc(playerRef, {
      projectilesShot: updatedProjectiles,
    });
  }
  return null;
};

export const hideCards = async (tableId: string) => {
  const results: PokerTableResults = {
    total: 0,
    votes: [],
  };

  await updateDoc(doc(collection(firestore, POKER_GAMES_COLLECTION), tableId), {
    cardsUp: false,
    results,
  });

  await getDocs(
    query(
      collection(firestore, PLAYERS_COLLECTION),
      where('tableId', '==', tableId),
    ),
  ).then((querySnapshot) =>
    querySnapshot.docs.forEach((doc) =>
      updateDoc(doc.ref, {
        vote: null,
      }),
    ),
  );
};

const getPreviousVoting = async () => {
  const snapshot = await getDocs(
    query(
      collection(firestore, VOTINGS_COLLECTION),
      orderBy('timestamp', 'desc'),
      limit(1),
    ),
  );

  const votings: any = [];
  snapshot.docs.forEach((doc) => {
    votings.push({ ...doc.data(), timestamp: doc.data().timestamp.toDate() });
  });

  return votings[0];
};

const updateUnanimousVotings = async (
  voting: VotingForUpdate,
  game: PokerTable,
) => {
  const previousVoting = await getPreviousVoting();
  const isFullConsensus = voting.results?.votes?.length === 1;

  const duration =
    Date.now() - (previousVoting ? previousVoting.timestamp.getTime() : 0);

  const isNewMeeting = duration > TIME_FOR_NEW_MEETING_MILIS;
  const prevUnanimousVotingsForCurrentMeeting = isNewMeeting
    ? 0
    : game.unanimousVotingsForCurrentMeeting || 0;

  const unanimousVotingsForCurrentMeeting =
    prevUnanimousVotingsForCurrentMeeting + (isFullConsensus ? 1 : 0);

  if (!game.id) return;

  updateDoc(doc(collection(firestore, `${POKER_GAMES_COLLECTION}`), game.id), {
    unanimousVotingsForCurrentMeeting,
  });
};

const createVoting = async (
  table: PokerTable,
  results: PokerTableResults | null,
  issues: Issue[] | null,
  eventData?: ShowCardsEvent,
) => {
  const voting: VotingForUpdate = {
    timestamp: serverTimestamp(),
    tableId: table.id,
    tableOwnerId: table.ownerId,
    tableCreatedAt: table.createdAt?.toString
      ? table.createdAt?.toString()
      : '',
    tableVotingCount: table.votingCount,
    tableVotingCountdown: table.votingCountdown,
    tablePlayersAll: table.playersAll.map((player) => player.uid),
    tablePlayersAllTotal: table.playersAll.length,
    tableWhoCanShowCards: table.whoCanShowCards,
    tableDeck: table.deck,
    tableIsPremium: table.isPremium,
    milisecondsPassedSinceLastShowCards: table.lastShowCards
      ? Date.now() - table.lastShowCards?.getTime()
      : null,
    playersOnline: table.players.map((player) => player.uid),
    playersOnlineTotal: table.players.length,
    playersVoted: table.players
      .filter((player) => !!player.vote)
      .map((player) => player.uid),
    playersVotedTotal: table.players.filter((player) => !!player.vote).length,
    results,
    issues: issues || null,
    ...eventData,
  };

  type VotingKey = keyof VotingForUpdate;
  Object.keys(voting).forEach((key) => {
    const resolvedKay = key as VotingKey;
    voting[resolvedKay] === undefined && delete voting[resolvedKay];
  });
  await updateUnanimousVotings(voting, table);

  await addDoc(collection(firestore, VOTINGS_COLLECTION), voting);
};
export const getVotingHistory = (gameId: string) =>
  getDocs(
    query(
      collection(firestore, VOTINGS_COLLECTION),
      where('tableId', '==', gameId),
      orderBy('timestamp', 'asc'),
    ),
  ).then((snapshot) => {
    const votings: Voting[] = [];
    snapshot.docs.forEach((doc) => {
      const data = doc.data() as VotingServer;

      votings.push({
        ...data,
        timestamp: data.timestamp.toDate(),
      });
    });

    return votings;
  });
export type ShowCardsEvent = {
  isSidebarOpen: boolean;
};

export const showCards = (
  table: PokerTable,
  results: PokerTableResults,
  issues?: Issue[] | null,
  eventData?: ShowCardsEvent,
) => {
  if (!table.id) return;

  try {
    createVoting(table, results, issues || null, eventData);
  } catch (e) {
    console.error(e);
  }

  const update: Partial<PokerTable> = {
    cardsUp: true,
    cardsUpCountdown: 0,
    results,
  };

  const hasMoreThan1Player = results?.total && results?.total > 1;

  if (issues) {
    update.issues = issues;
  }

  if (issues && hasMoreThan1Player && !table.isPremium) {
    update.issuesVotedCount = table.issuesVotedCount
      ? table.issuesVotedCount + 1
      : 1;
  }

  return updateDoc(
    doc(collection(firestore, POKER_GAMES_COLLECTION), table.id),
    getObjectWithoutUndefinedValues(update),
  );
};

export const updatePokerTable = (
  tableId: string,
  table: Partial<PokerTable>,
) => {
  if (!tableId) return Promise.reject();

  return updateDoc(
    doc(collection(firestore, POKER_GAMES_COLLECTION), tableId),
    table,
  );
};

export const getPokerTableSummariesByIds = (gameIds: string[]) => {
  if (!gameIds?.length) return Promise.resolve([]);

  if (gameIds.length >= 10) return Promise.reject(new Error('Maximum 10 ids'));

  return getDocs(
    query(
      collection(firestore, POKER_GAMES_COLLECTION),
      where(documentId(), 'in', gameIds),
    ),
  ).then((snapshot) => {
    const games: PokerTableSummary[] = [];
    snapshot.docs.forEach((doc) => {
      const data = doc.data();

      games.push({
        id: doc.id || '',
        name: data.name || '',
        createdAt: new Date((data.createdAt?.seconds || 0) * 1000),
      });
    });

    return games;
  });
};
