import { ReactNode } from 'react';
import { InvitePlayersButton } from '../../InvitePlayersButton/InvitePlayersButton';
import { Stack, Inline } from '@atlaskit/primitives';

export const TopLane = ({
  children,
  isUserLonely,
}: {
  children: ReactNode;
  isUserLonely: boolean;
}) => {
  if (isUserLonely) {
    return (
      <Stack space="space.100">
        <Inline space="space.050" alignBlock="center">
          <span>Feeling lonely?</span>
          <span role="img" aria-label="sleeping">
            😴
          </span>
        </Inline>
        <div>
          <InvitePlayersButton isTableButton />
        </div>
      </Stack>
    );
  }

  return <>{children}</>;
};
