import { User } from './../types';
import { useEffect, useState } from 'react';

import { AppUser, subscribeToAppUsers } from '../app/data';
import { SignInResult } from './useSignin';

export const useSubscribeToAppUsers = (
  currentUser: User | null,
  auth: SignInResult,
) => {
  const [appUsers, setAppUsers] = useState<null | AppUser[]>(null);
  const [appUsersWithCurrentUserFirst, setAppUsersWithCurrentUserFirst] =
    useState<null | AppUser[]>(null);

  useEffect(() => {
    if (!auth?.isAuthenticated) {
      return;
    }

    return subscribeToAppUsers(auth.appId, (appUsers) => {
      setAppUsers(appUsers);
    });
  }, [auth]);

  useEffect(() => {
    if (!appUsers) {
      return;
    }

    const appCurrentUser = currentUser?.uid
      ? {
          displayName: currentUser.displayName,
          uid: currentUser.uid,
          avatar: currentUser.avatar,
          lastOnline: null, // this params are hardcoded for current user. for now they are not used so it is ok
          isOnline: true,
          lastGameId: null,
          lastGameName: null,
        }
      : null;

    setAppUsersWithCurrentUserFirst(
      appCurrentUser
        ? [
            appCurrentUser,
            ...appUsers.filter((user) => user.uid !== appCurrentUser.uid),
          ]
        : appUsers,
    );
  }, [appUsers, currentUser]);

  return appUsersWithCurrentUserFirst;
};
