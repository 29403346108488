import Select, { ValueType } from '@atlaskit/select';
import { ErrorMessage, Field, HelperMessage } from '@atlaskit/form';
import { ReactNode } from 'react';
import { useAuthContext } from '../../../auth/useAuthContext';
import { Inline } from '@atlaskit/primitives';
import Avatar from '@atlaskit/avatar';

export type UserOption = {
  label: ReactNode;
  value: string;
  name: string;
};

export const SelectWhoCan = ({
  name,
  label,
  helperMessage,
  onChanged,
  defaultValue,
}: {
  name: string;
  label: string;
  helperMessage: string;
  onChanged: (userIds: string[] | null) => void;
  defaultValue: string[] | null;
}) => {
  const auth = useAuthContext();

  const userOptions: UserOption[] | null = auth.appUsers
    ? auth.appUsers.map((user) => ({
        value: user.uid,
        label: (
          <Inline space="space.100" alignBlock="center">
            <Avatar name={user.displayName} src={user.avatar} />
            <div>{`${user.displayName}${
              auth.uid === user.uid ? ' (me)' : ''
            }`}</div>
          </Inline>
        ),
        name: user.displayName,
      }))
    : null;

  if (!userOptions) {
    return null;
  }

  const defaultValueOptions = defaultValue
    ? userOptions.filter((option) =>
        defaultValue.find((val) => val === option.value),
      )
    : [];

  return (
    <Field<ValueType<UserOption, true>> name={name} label={label}>
      {({ fieldProps: { id, onChange: onChangeForm, ...rest }, error }) => (
        <>
          <Select
            inputId={id}
            {...rest}
            isMulti
            placeholder="All Players"
            onChange={(multiValue) => {
              if (multiValue.find((value) => value.value === 'all')) {
                onChangeForm([]);
                onChanged(null);

                return;
              }

              onChangeForm(multiValue);
              onChanged(multiValue.map((option) => option.value));
            }}
            options={[
              { label: 'All players', value: 'all', name: 'all' },
              ...userOptions,
            ]}
            filterOption={(option, value) => {
              return (
                !!option.data.name &&
                option.data.name.toLowerCase().indexOf(value.toLowerCase()) >= 0
              );
            }}
            defaultValue={defaultValueOptions}
          />
          <HelperMessage>{helperMessage}</HelperMessage>
          {error && <ErrorMessage>{error}</ErrorMessage>}
        </>
      )}
    </Field>
  );
};
