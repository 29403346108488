import { ErrorMessage, Field, HelperMessage } from '@atlaskit/form';
import Select from '@atlaskit/select';
import ErrorIcon from '@atlaskit/icon/glyph/error';
import { Field as JiraField, getFields } from '../../../../../jira/api';
import Spinner from '@atlaskit/spinner';
import InlineMessage from '@atlaskit/inline-message';
import { Inline, Stack } from '@atlaskit/primitives';
import { useFieldsContext } from '../../../../../jira/fields/useFieldsContext';
import { useGameContext } from '../../../../hooks/useGameContext';
import { LinkButton } from '@atlaskit/button/new';
import { StoryPointsHelpModal } from '../../../../../jira/components/StoryPointsHelpModal/StoryPointsHelpModal';
import { ReactNode, useState } from 'react';
import { useIssueActions } from '../../../../hooks/useIssueActions';

const MAX_ERROR_MESSAGES = 4;

export const StoryPointsFieldPicker = ({
  onChanged,
  defaultValue,
  message,
  isRequired,
}: {
  onChanged: (field: JiraField | null) => void;
  defaultValue: JiraField | null;
  message?: ReactNode;
  isRequired?: boolean;
}) => {
  const { fields, error } = useFieldsContext();
  const gameContext = useGameContext();
  const [isHelpModalOpen, setIsHelpModalOpen] = useState(false);
  const [isSavingFailedIssues, setIsSavingFailedIssues] = useState(false);
  const { saveAllFailedIssues } = useIssueActions();
  const isMissingStoryPoints =
    gameContext?.game?.id && !gameContext.game.storyPointsField;
  const issuesFailedSaving = !!gameContext?.game?.issues
    ? gameContext.game.issues.filter(
        (issue) =>
          issue.syncStatus === 'error' ||
          issue.syncStatus === 'syncing' ||
          issue.syncStatus === 'notSynced',
      )
    : null;

  if (error) {
    return (
      <InlineMessage title="Could not load story points field picker">
        {error?.message}
      </InlineMessage>
    );
  }

  const fieldOptions =
    fields &&
    fields
      .sort((fieldA, fieldB) => {
        const nameA = fieldA.name.replace(/\s/g, '').toLowerCase();
        const nameB = fieldB.name.replace(/\s/g, '').toLowerCase();

        return nameA.localeCompare(nameB);
      })
      .map((field) => ({
        value: field,
        label: field.name,
      }));

  if (!fieldOptions) {
    return (
      <Inline space="space.100">
        <Spinner />
        <div>Loading story points picker</div>
      </Inline>
    );
  }

  const handleSaveAllFailedIssues = async (field: JiraField) => {
    setIsSavingFailedIssues(true);

    try {
      await saveAllFailedIssues(field);
    } catch (error) {
      console.error(error);
    }

    setIsSavingFailedIssues(false);
  };

  const renderMessage = () => {
    if (!gameContext?.game) {
      return null;
    }

    if (isSavingFailedIssues) {
      return (
        <Inline alignBlock="center" space="space.100">
          <Spinner size="small" />
          <HelperMessage>Saving failed issues...</HelperMessage>
        </Inline>
      );
    }

    if (isMissingStoryPoints) {
      return (
        <ErrorMessage>
          Synchronization won't work unless you select a story points field
        </ErrorMessage>
      );
    }

    if (issuesFailedSaving?.length) {
      return (
        <div style={{ paddingTop: 5 }}>
          <InlineMessage
            appearance="error"
            iconLabel="Some issues failed to save."
            secondaryText="Some issues failed to save"
          >
            <Stack space="space.100">
              <div style={{ fontSize: '12px' }}>
                <p key="title" style={{ margin: '0 0 0 0' }}>
                  <strong>Saving errors</strong>
                </p>
                {issuesFailedSaving
                  .slice(0, MAX_ERROR_MESSAGES)
                  .map((issue) => (
                    <p key={issue.id} style={{ margin: '8px 0 0 0' }}>
                      <strong>{issue.key}: </strong>
                      <span>{issue.syncErrorMessage}</span>
                    </p>
                  ))}
                {issuesFailedSaving.length > MAX_ERROR_MESSAGES && (
                  <p style={{ margin: '0 0 0 0' }}>...</p>
                )}
              </div>
            </Stack>
          </InlineMessage>
        </div>
      );
    }

    return (
      <HelperMessage>
        The field used in your Jira instance to store the estimates
      </HelperMessage>
    );
  };

  return (
    <>
      <Field<JiraField | null>
        name="storyPointsField"
        label="Story Points Field"
        defaultValue={defaultValue}
      >
        {({ fieldProps: { value, onChange: onChangeForm, ...rest } }) => {
          const options = fieldOptions || [];
          const fieldId = value?.id;

          const selectedOption = fieldId
            ? fieldOptions?.find((option) => option.value?.id === fieldId)
            : null;

          return (
            <>
              <Select
                {...rest}
                placeholder="Select Field"
                options={options}
                isSearchable
                isLoading={!fieldOptions}
                value={selectedOption}
                minMenuHeight={200}
                required={isRequired}
                shouldScrollInViewport
                onChange={(value) => {
                  const field = value?.value || null;
                  onChangeForm(field);
                  onChanged(field);

                  if (issuesFailedSaving?.length && field) {
                    handleSaveAllFailedIssues(field);
                  }
                }}
              />
              {message ? (
                message
              ) : (
                <Inline alignBlock="center">
                  {renderMessage()}
                  {!isSavingFailedIssues && (
                    <div style={{ marginTop: 3 }}>
                      <LinkButton
                        appearance="link"
                        href="#"
                        onClick={(e) => {
                          e.preventDefault();
                          setIsHelpModalOpen(true);
                        }}
                      >
                        Learn more
                      </LinkButton>
                    </div>
                  )}
                </Inline>
              )}
            </>
          );
        }}
      </Field>
      {isHelpModalOpen && (
        <StoryPointsHelpModal onClose={() => setIsHelpModalOpen(false)} />
      )}
    </>
  );
};
