import {
  Button,
  ButtonLink,
  FormInput,
  FormSelect,
  FormSwitch,
  InlineAlert,
  Paragraph,
  SelectValue,
  Span,
  VerticalSpacing,
} from '@we-agile-you/react-base';
import {
  CustomDeck,
  PokerTable,
  WhoCanEditIssuesType,
  WhoCanShowCardsType,
} from '@we-agile-you/types-planning-poker';
import React, {
  FormEvent,
  forwardRef,
  ReactNode,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateCurrentUser } from '@we-agile-you/auth';
import { useCurrentUser } from '@we-agile-you/auth';
import { createPokerTable } from '../../../spaces/poker-table/actions/creation';
import { changeSettings } from '../../../spaces/poker-table/actions/settings';
import { DEFAULT_CARD_LIST } from '../../../spaces/poker-table/constants';
import { AppState } from '../../../spaces/state/appState';

import { SelectFacilitator } from './SelectFacilitator';
import { SelectWhoCanEditIssues } from './SelectWhoCanEditIssues';
import { SelectWhoCanShowCards } from './SelectWhoCanShowCards';
import styles from './SettingsForm.module.scss';

const CUSTOM_DECK = 'CUSTOM_DECK';

interface SettingsProps {
  isCreateForm?: boolean;
  onCreated?: (pokerTableId: string) => void;
  onSelectCustomDeck: () => void;
  onManageDecksClick?: () => void;
  onUpdated?: () => void;
  defaults?: {
    whoCanShowCards?: 'just-me' | 'all';
    whoCanEditIssues?: 'just-me' | 'all';
    autoShowCards?: boolean;
    enableProjectiles?: boolean;
    hideAverageInResults?: boolean;
  };
}

interface DeckOption {
  label: ReactNode;
  value: string;
}

const SettingsForm = forwardRef(function SettingsForm(
  {
    onCreated,
    onSelectCustomDeck,
    onManageDecksClick,
    onUpdated,
    isCreateForm,
    defaults,
  }: SettingsProps,
  ref,
) {
  const dispatch = useDispatch();
  const pokerTable: PokerTable = useSelector(
    (state: AppState) => state.pokerTable,
  );
  const [selectedDeck, setSelectedDeck] =
    useState<SelectValue<DeckOption>>(null);

  const getInitialValues = () => {
    if (pokerTable.id) {
      return {
        whoCanShowCards: pokerTable.whoCanShowCards || null,
        whoCanEditIssues: pokerTable.whoCanEditIssues || null,
        autoShowCards: pokerTable.autoShowCards || false,
        enableProjectiles:
          typeof pokerTable.enableProjectiles !== 'undefined'
            ? pokerTable.enableProjectiles
            : true,
        hideAverageInResults: !!pokerTable.hideAverageInResults,
      };
    }

    return {
      whoCanShowCards: defaults?.whoCanShowCards || 'all',
      whoCanEditIssues: defaults?.whoCanEditIssues || 'all',
      autoShowCards: !!defaults?.autoShowCards,
      enableProjectiles:
        typeof defaults?.enableProjectiles !== 'undefined'
          ? defaults.enableProjectiles
          : true,
      hideAverageInResults: !!defaults?.hideAverageInResults,
    };
  };

  const initialValues = getInitialValues();

  const [name, setName] = useState<string>(pokerTable.name || '');
  const [enableProjectiles, setEnableProjectiles] = useState(
    initialValues.enableProjectiles,
  );
  const [error, setError] = useState<Error | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [facilitator, setFacilitator] = useState<string | null>(null);
  const [isShowMoreSettings, setIsShowMoreSettings] = useState(false);

  const textInput = useRef<HTMLInputElement | null>(null);

  const [whoCanShowCards, setWhoCanShowCards] = useState(
    initialValues.whoCanShowCards,
  );
  const [isHideAverageInResults, setIsHideAverageInResults] = useState(
    initialValues.hideAverageInResults,
  );
  const [isAutoShowCards, setIsAutoShowCards] = useState(
    initialValues.autoShowCards,
  );

  const [whoCanEditIssues, setWhoCanEditIssues] = useState(
    initialValues.whoCanEditIssues,
  );

  const user = useCurrentUser();

  const customDecks = useMemo(
    () =>
      user.user?.customDecks
        ? user.user?.customDecks.map((deck) => ({
            label: `${deck.name} (${deck.value})`,
            value: deck.value,
          }))
        : [],
    [user.user?.customDecks],
  );

  const deckOptions: DeckOption[] = useMemo(
    () => [
      {
        label: 'Fibonacci ( 0, 1, 2, 3, 5, 8, 13, 21, 34, 55, 89, ?, ☕ )',
        value: '0, 1, 2, 3, 5, 8, 13, 21, 34, 55, 89, ?, ☕',
      },
      {
        label:
          'Modified Fibonacci ( 0, ½, 1, 2, 3, 5, 8, 13, 20, 40, 100, ?, ☕ )',
        value: '0, ½, 1, 2, 3, 5, 8, 13, 20, 40, 100, ?, ☕',
      },
      {
        label: 'T-shirts (XS, S, M, L, XL, ?, ☕ )',
        value: 'XS, S, M, L, XL, ?, ☕',
      },
      {
        label: 'Powers of 2 ( 0, 1, 2, 4, 8, 16, 32, 64, ?, ☕ )',
        value: '0, 1, 2, 4, 8, 16, 32, 64, ?, ☕',
      },
      ...customDecks,
      {
        label: (
          <Span spanStyle="bold" color="primary">
            Create custom deck...
          </Span>
        ),
        value: CUSTOM_DECK,
      },
    ],
    [customDecks],
  );

  useEffect(() => {
    if (textInput && textInput.current && isCreateForm) {
      window.setTimeout(() => {
        textInput?.current?.focus();
      }, 0);
    }
  }, [isCreateForm]);

  useImperativeHandle(ref, () => ({
    setSelectedDeck: (deck: CustomDeck) => {
      const selectedOption = deckOptions.find(
        (option) =>
          deck.value.replace(/\s/g, '') === option.value.replace(/\s/g, ''),
      );
      setSelectedDeck(selectedOption);
    },
  }));

  const lastSelectedDeck = user.user ? user.user.lastSelectedDeck : null;

  useEffect(() => {
    if (!isCreateForm || lastSelectedDeck === null) return;

    const deck =
      lastSelectedDeck && lastSelectedDeck.length
        ? deckOptions.find(
            (option) =>
              lastSelectedDeck.join(',').replace(/\s/g, '') ===
              option.value.replace(/\s/g, ''),
          )
        : deckOptions[0];

    setSelectedDeck(deck || deckOptions[0]);
  }, [isCreateForm, lastSelectedDeck]);

  useEffect(() => {
    if (isCreateForm) {
      return;
    }

    const selectedOption =
      (pokerTable &&
        deckOptions.find(
          (option) =>
            pokerTable.deck.join(',').replace(/\s/g, '') ===
            option.value.replace(/\s/g, ''),
        )) ||
      deckOptions[0];

    setSelectedDeck(selectedOption);

    const name = pokerTable.name;

    if (name) {
      setName(name);
    }
  }, [pokerTable, isCreateForm]);

  const handleDeckChange = (selectedOption: SelectValue<DeckOption>) => {
    const selectedOptionO =
      Array.isArray(selectedOption) && selectedOption.length > 0
        ? selectedOption[0]
        : selectedOption;

    const value = selectedOptionO && selectedOptionO.value;

    if (value === CUSTOM_DECK) {
      onSelectCustomDeck();

      return;
    }

    setSelectedDeck(selectedOption);
  };

  const handleNameChange = (value: string) => {
    setName(value);
  };

  const handleWhoCanShowCardsChange = (value: WhoCanShowCardsType) => {
    setWhoCanShowCards(value);
  };

  const handleWhoCanEditIssuesChange = (value: WhoCanEditIssuesType) => {
    setWhoCanEditIssues(value);
  };

  const handleEnableProjectilesChange = (value: boolean) => {
    setEnableProjectiles(value);
  };

  function handleFormSubmit(event: FormEvent) {
    event.preventDefault();
    setError(null);

    setIsLoading(true);

    if (!user.user) {
      setIsLoading(false);
      setError(new Error('No valid user is loged in.'));

      return;
    }

    const selectedOption =
      Array.isArray(selectedDeck) && selectedDeck.length > 0
        ? selectedDeck[0]
        : selectedDeck;

    const deck = selectedOption
      ? selectedOption.value
          .split(',')
          .map((card: string) =>
            typeof card === 'string' ? card.trim() : card,
          )
      : DEFAULT_CARD_LIST;

    const lastSelectedWhoCanShowCards =
      whoCanShowCards === 'just-me' ||
      (whoCanShowCards?.length === 1 &&
        user.uid &&
        whoCanShowCards[0] === user.uid)
        ? 'just-me'
        : 'all';
    const lastSelectedWhoCanEditIssues =
      whoCanEditIssues === 'just-me' ||
      (whoCanEditIssues?.length === 1 &&
        user.uid &&
        whoCanEditIssues[0] === user.uid)
        ? 'just-me'
        : 'all';

    updateCurrentUser({
      lastSelectedDeck: deck,
      lastSelectedEnableProjectiles: enableProjectiles,
      lastSelectedWhoCanShowCards,
      lastSelectedWhoCanEditIssues,
      lastSelectedHideAverage: isHideAverageInResults,
      lastSelectedShowCards: isAutoShowCards,
    });

    const getWhoCanShowCards = (): WhoCanShowCardsType => {
      if (whoCanShowCards === 'all') {
        return null;
      }

      if (whoCanShowCards === 'just-me') {
        return user.uid ? [user.uid] : null;
      }

      return whoCanShowCards;
    };

    const getWhoCanEditIssues = (): WhoCanShowCardsType => {
      if (whoCanEditIssues === 'all') {
        return null;
      }

      if (whoCanEditIssues === 'just-me') {
        return user.uid ? [user.uid] : null;
      }

      return whoCanEditIssues;
    };

    if (isCreateForm) {
      createPokerTable(
        {
          name,
          deck,
          whoCanShowCards: getWhoCanShowCards(),
          whoCanEditIssues: getWhoCanEditIssues(),
          enableProjectiles,
          hideAverageInResults: isHideAverageInResults,
          autoShowCards: isAutoShowCards,
        },
        user.user,
        dispatch,
      )
        .then((pokerTableId) => {
          if (onCreated) {
            setIsLoading(false);
            onCreated(pokerTableId);
          }
        })
        .catch((error) => {
          console.error(error);
          setIsLoading(false);
          setError(
            error.message
              ? error
              : { message: 'Unexpected error happened when creating game.' },
          );
        });
    } else if (pokerTable && pokerTable.id && pokerTable.ownerId) {
      changeSettings(pokerTable.id, {
        name,
        deck,
        whoCanShowCards: getWhoCanShowCards(),
        whoCanEditIssues: getWhoCanEditIssues(),
        ownerId: facilitator || pokerTable.ownerId,
        enableProjectiles,
        hideAverageInResults: isHideAverageInResults,
        autoShowCards: isAutoShowCards,
      }).then(() => {
        if (onUpdated) {
          setIsLoading(false);
          onUpdated();
        }
      });
    }
  }

  const submitLabel = isCreateForm ? 'Create game' : 'Update game settings';

  const showManageDecks = !isCreateForm && !!customDecks?.length;

  return (
    <div className={styles['settings']}>
      <form onSubmit={handleFormSubmit}>
        {!isCreateForm && pokerTable.ownerId && (
          <SelectFacilitator
            playersAll={pokerTable.playersAll}
            onChange={(uid) => setFacilitator(uid)}
            value={pokerTable.ownerId}
          />
        )}
        <FormInput
          label="Game's name"
          value={name}
          onChange={handleNameChange}
          maxLength={60}
          ref={textInput}
          data-test="input-game-name"
        />

        <VerticalSpacing spacing="spacing-l" />
        <FormSelect
          label="Voting system"
          // eslint-disable-next-line
          // @ts-ignore
          options={deckOptions}
          onChange={handleDeckChange}
          value={selectedDeck}
          id="deck"
          isSearchable={false}
          data-test="select-deck"
          menuPortalTarget={document.body}
          isNoMargin={showManageDecks}
        />
        {showManageDecks && (
          <>
            <VerticalSpacing spacing="spacing-xs" />
            <Paragraph align="right">
              <ButtonLink onClick={onManageDecksClick}>
                Manage custom decks
              </ButtonLink>
            </Paragraph>
            <VerticalSpacing spacing="spacing-l" />
          </>
        )}
        {isCreateForm && !isShowMoreSettings && (
          <div>
            <ButtonLink
              onClick={() => setIsShowMoreSettings(true)}
              fontWeight="normal"
            >
              Show advanced settings...
            </ButtonLink>
          </div>
        )}
        {(!isCreateForm || isShowMoreSettings) && (
          <>
            <SelectWhoCanShowCards
              onChange={handleWhoCanShowCardsChange}
              isCreateForm={!!isCreateForm}
              defaultValue={whoCanShowCards}
            />
            <VerticalSpacing spacing="spacing-l" />
            <SelectWhoCanEditIssues
              onChange={handleWhoCanEditIssuesChange}
              isCreateForm={!!isCreateForm}
              defaultValue={whoCanEditIssues}
            />
            <VerticalSpacing spacing="spacing-l" />
            <FormSwitch
              label="Auto-reveal cards"
              hint="Show cards automatically after everyone voted."
              isActive={isAutoShowCards}
              onChange={setIsAutoShowCards}
              data-test="checkbox-auto-show"
              align="space-between"
            />
            <VerticalSpacing spacing="spacing-l" />
            <FormSwitch
              label="Enable fun features"
              hint="Allow players throw projectiles to each other in this game."
              isActive={enableProjectiles}
              onChange={handleEnableProjectilesChange}
              data-test="checkbox-disable-projectiles"
              align="space-between"
            />
            <VerticalSpacing spacing="spacing-l" />
            <FormSwitch
              label="Show average in the results"
              hint="Include the average value in the results of the voting."
              isActive={!isHideAverageInResults}
              onChange={(value) => setIsHideAverageInResults(!value)}
              data-test="checkbox-hide-average"
              align="space-between"
            />
          </>
        )}
        {error?.message && (
          <InlineAlert
            title={error.message}
            content="Please sign out, sign in and try again."
          />
        )}
        <VerticalSpacing spacing="spacing-xxl" />
        <Button
          data-test="button-submit-settings-form"
          buttonType="submit"
          isLoading={isLoading}
          isBlock
        >
          {submitLabel}
        </Button>
      </form>
    </div>
  );
});

export default SettingsForm;
