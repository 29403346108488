import TextField from '@atlaskit/textfield';
import { Inline } from '@atlaskit/primitives';
import Form from '@atlaskit/form';
import Button, { LoadingButton } from '@atlaskit/button';

import styles from './JqlPicker.module.scss';
export type JqlPickerFormData = { jql: string };

export const JqlPicker = ({
  jql,
  onSubmit,
  onChange,
  onOpenJqlEditor,
}: {
  jql: string;
  onSubmit: (data: JqlPickerFormData) => void;
  onChange: (jql: string) => void;
  onOpenJqlEditor: () => void;
}) => {
  return (
    <Form<JqlPickerFormData> onSubmit={onSubmit}>
      {({ formProps }) => (
        <form {...formProps} className={styles.form}>
          <Inline space="space.100" alignBlock="center">
            <TextField
              placeholder="JQL expression"
              name="jql"
              value={jql}
              onChange={(e) => onChange(e.currentTarget.value)}
            />
            <Button onClick={onOpenJqlEditor}>Open JQL editor</Button>
            <LoadingButton type="submit" appearance="primary">
              Search
            </LoadingButton>
          </Inline>
        </form>
      )}
    </Form>
  );
};
