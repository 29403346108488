import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './index.scss';
import * as Sentry from '@sentry/react';

import { NoContextMessage } from './components/NoContextMessage/NoContextMessage';

const MODE = import.meta.env.MODE as 'development' | 'staging' | 'production';

if (MODE !== 'development') {
  Sentry.init({
    dsn: 'https://449cc5e40e799bf937b971d75baa249c@o4504848352018432.ingest.sentry.io/4506785191886848',
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [
      /^https:\/\/planningpokeronline-jira\.com/,
      /^https:\/\/planningpokeronline-jira\.com\/.*$/,
      'https://planningpokeronline-jira.com/index.html',
      'https://staging--jira-connect-app.netlify.app',
    ],
    // Session Replay
    replaysSessionSampleRate: 0.5, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

if (!window) {
  console.log('Not in Browser');
} else if (!window.AP.user) {
  console.log(`window.AP.user not found`);

  try {
    console.log(`Is top iframe: ${window.parent === window}`);
  } catch (e) {
    console.log(e);
  }

  ReactDOM.createRoot(document.getElementById('root')!).render(
    <React.StrictMode>
      <NoContextMessage />
    </React.StrictMode>,
  );
} else {
  ReactDOM.createRoot(document.getElementById('root')!).render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
  );
}
