// -- Constants

export const REQUEST_CREATE_POKER_TABLE = 'REQUEST_CREATE_POKER_TABLE';
export const RECEIVE_CREATED_POKER_TABLE = 'RECEIVE_CREATED_POKER_TABLE';
export const REQUEST_SUBSCRIBE_TO_POKER_TABLE =
  'REQUEST_SUBSCRIBE_TO_POKER_TABLE';
export const RECEIVE_SUBSCRIBE_TO_POKER_TABLE =
  'RECEIVE_SUBSCRIBE_TO_POKER_TABLE';
export const RECEIVE_POKER_TABLE_PLAYERS_CHANGE =
  'RECEIVE_POKER_TABLE_PLAYERS_CHANGE';
export const RECEIVE_POKER_TABLE_DATA_CHANGE =
  'RECEIVE_POKER_TABLE_DATA_CHANGE';
export const UNSUBSCRIBE_TO_POKER_TABLE = 'UNSUBSCRIBE_TO_POKER_TABLE';

export const RECEIVE_PROJECTILES_CHANGE = 'RECEIVE_PROJECTILES_CHANGE';
export const PROJECTILE_THROW_COMPLETE = 'PROJECTILE_THROW_COMPLETE';

export const DEFAULT_DISPLAY_NAME = 'Anonymous';
export const DEFAULT_GAME_NAME = 'Planning poker game';
export const DEFAULT_CARD_LIST = ['XS', 'S', 'M', 'L', 'XL'];

export const PLUGIN_URL =
  'https://marketplace.atlassian.com/apps/1233326/planning-poker-story-point-estimations-scrum-poker';
