import { useState, useEffect } from 'react';
import { Inline, Stack } from '@atlaskit/primitives';

import { Loader } from '../../../../../../components/Loader/Loader';
import InlineMessage from '@atlaskit/inline-message';
import { LoadingButton } from '@atlaskit/button';
import DynamicTable from '@atlaskit/dynamic-table';
import { Checkbox } from '@atlaskit/checkbox';
import { SpotlightTarget } from '@atlaskit/onboarding';

import { IssueJira } from '../../../../../types';
import { useGameContext } from '../../../../hooks/useGameContext';
import { useIssueActions } from '../../../../hooks/useIssueActions';
import { Column, EDITABLE_COLUMNS, mapIssuesToRows, tableHead } from './utils';
import { IconButton } from '@atlaskit/button/new';
import SettingsIcon from '@atlaskit/icon/glyph/settings';
import DropdownMenu, { DropdownItemCheckbox } from '@atlaskit/dropdown-menu';
import styles from './Results.module.scss';
import { updateCurrentUser } from '../../../../../auth/data';
import { useAuthContext } from '../../../../../auth/useAuthContext';

export const Results = ({
  issues,
  issuesTotal,
  isLoadingIssues,
  isLoadingMoreIssues,
  error,
  onError,
  selectedIssues,
  onChangeSelectedIssues,
  hasMoreIssues,
  onLoadMoreIssues,
  onIssueAdded,
  onSort,
}: {
  issues: IssueJira[] | null;
  issuesTotal: number | null;
  isLoadingIssues: boolean;
  isLoadingMoreIssues: boolean;
  onIssueAdded: () => void;
  error: Error | null;
  onError: (error: Error | null) => void;
  selectedIssues: IssueJira[] | null;
  onChangeSelectedIssues: React.Dispatch<
    React.SetStateAction<IssueJira[] | null>
  >;
  hasMoreIssues: boolean;
  onLoadMoreIssues: () => void;
  onSort: (sorting: {
    key: keyof IssueJira;
    sortOrder: 'ASC' | 'DESC';
  }) => void;
}) => {
  const { addIssues } = useIssueActions();
  const gameContext = useGameContext();
  const auth = useAuthContext();
  const gameIssues = gameContext && gameContext.game?.issues;
  const appId = auth.appId;
  const user = auth.user;
  const [isLoadingAddIssues, setIsLoadingAddIssues] = useState(false);
  const [selectedColumns, setSelectedColumns] = useState<Column[]>(
    user?.searchSelectedColumns || ['key', 'summary', 'epic', 'estimate'],
  );
  const isSelectedAllIssues: boolean =
    !!issues &&
    !!selectedIssues &&
    issues.length === selectedIssues.length &&
    selectedIssues.reduce<boolean>((areTheSame, issue) => {
      return areTheSame && !!issues.find((_issue) => _issue.id === issue.id);
    }, true);
  const [globalCheckboxValue, setGlobalCheckboxValue] =
    useState(isSelectedAllIssues);

  useEffect(() => {
    setGlobalCheckboxValue(isSelectedAllIssues);
  }, [isSelectedAllIssues]);

  useEffect(() => {
    if (!appId) {
      return;
    }

    updateCurrentUser(appId, { searchSelectedColumns: selectedColumns });
  }, [selectedColumns, appId]);

  const handleGlobalCheckboxChanged = () => {
    if (!issues) return;

    if (globalCheckboxValue) {
      onChangeSelectedIssues([]);
    } else {
      onChangeSelectedIssues(issues);
    }

    setGlobalCheckboxValue(!globalCheckboxValue);
  };

  const handleAddIssuesClick = async () => {
    onError(null);

    if (!selectedIssues) {
      return;
    }

    setIsLoadingAddIssues(true);

    try {
      await addIssues(selectedIssues);

      onIssueAdded();
    } catch (e: any) {
      onError(e);
    }

    setIsLoadingAddIssues(false);
  };

  const errorMessage = error
    ? error.message || 'Error loading issues, please try with different filters'
    : null;

  const issuesNotAdded =
    issues &&
    issues.filter((issue) =>
      gameIssues
        ? !gameIssues.find((gameIssue) => gameIssue.key === issue.key)
        : true,
    );

  if (isLoadingIssues) {
    return (
      <Stack space="space.800" alignBlock="center" alignInline="center">
        <div />
        <Loader message="Loading issues..." />;
      </Stack>
    );
  }

  if (errorMessage) {
    return (
      <Stack space="space.800" alignBlock="center" alignInline="center">
        <div />
        <InlineMessage
          title="Search error"
          appearance="error"
          iconLabel={`Error! ${errorMessage}`}
          secondaryText={errorMessage}
        />
      </Stack>
    );
  }

  const loadedIssuesLength = issues?.length || 0;
  const issuesNotAddedLength = issuesNotAdded?.length || 0;
  const issuesAlreadyAddedLength = loadedIssuesLength - issuesNotAddedLength;

  if (!issuesNotAddedLength) {
    if (issuesAlreadyAddedLength) {
      return <div>All issues found already in game</div>;
    }

    return <div>No results matching your query</div>;
  }

  const selectedColumnsResolved: Column[] = [
    'select',
    ...selectedColumns,
    'add',
  ];

  return (
    <Stack alignInline="stretch">
      <Stack space="space.200" alignInline="stretch">
        <Inline spread="space-between" alignBlock="center">
          <Checkbox
            label="Select all"
            isChecked={globalCheckboxValue}
            onChange={handleGlobalCheckboxChanged}
            size="large"
          />
          <LoadingButton
            onClick={handleAddIssuesClick}
            appearance="primary"
            isLoading={isLoadingAddIssues}
          >
            Add selected issues
          </LoadingButton>
        </Inline>
        <div className={styles['table-wrapper']}>
          <div className={styles['table-container']}>
            <DynamicTable
              head={tableHead({
                selectedColumns: selectedColumnsResolved,
                settingsContent: (
                  <Inline alignInline="end" grow="fill">
                    <DropdownMenu
                      trigger={({ triggerRef, ...props }) => (
                        <SpotlightTarget name="search-settings">
                          <IconButton
                            {...props}
                            icon={SettingsIcon}
                            label="Settings"
                            // @ts-ignore
                            ref={triggerRef}
                          />
                        </SpotlightTarget>
                      )}
                      placement="left"
                    >
                      {EDITABLE_COLUMNS.map((column) => (
                        <DropdownItemCheckbox
                          id={column}
                          isSelected={selectedColumns.includes(column)}
                          onClick={() => {
                            setSelectedColumns((selectedColumns) =>
                              selectedColumns.includes(column)
                                ? selectedColumns.filter(
                                    (selectedColumn) =>
                                      selectedColumn !== column,
                                  )
                                : [...selectedColumns, column],
                            );
                          }}
                        >
                          {column}
                        </DropdownItemCheckbox>
                      ))}
                    </DropdownMenu>
                  </Inline>
                ),
              })}
              isLoading={isLoadingMoreIssues}
              rows={mapIssuesToRows({
                selectedColumns: selectedColumnsResolved,
                issues: issuesNotAdded,
                selectedIssues,
                onAddIssues: addIssues,
                onChangeSelectedIssues,
              })}
              onSort={onSort}
            />
          </div>
        </div>
      </Stack>
      <Inline spread="space-between" alignBlock="center">
        <div>
          {!isLoadingMoreIssues && !isLoadingIssues && (
            <div>
              <span>{`${loadedIssuesLength} loaded from ${issuesTotal} total`}</span>
              {issuesAlreadyAddedLength ? (
                <span>{` (${issuesAlreadyAddedLength} already added to game)`}</span>
              ) : null}
            </div>
          )}
        </div>
        {hasMoreIssues && (
          <LoadingButton
            onClick={onLoadMoreIssues}
            isLoading={isLoadingMoreIssues}
          >
            Load more
          </LoadingButton>
        )}
      </Inline>
    </Stack>
  );
};
