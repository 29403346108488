import { uuidv4 } from '@we-agile-you/js-base';
import React, { createContext, useState, ReactNode } from 'react';
import { Notifications } from './Notifications/Notifications';
import { NotifiactionContextType, NotificationType } from './types.d';

export const NotificationsContext = createContext<NotifiactionContextType>({
  notifications: { showNotification: (_notification) => {} },
});

interface NotificationsProviderProps {
  children: ReactNode;
}

export const NotificationsProvider = ({
  children,
}: NotificationsProviderProps) => {
  const [openNotifications, setOpenNotifiactions] = useState<
    NotificationType[]
  >([]);

  const showNotification = (notification: Partial<NotificationType>) => {
    setOpenNotifiactions([
      ...openNotifications,
      {
        style: notification.style || 'success',
        title: notification.title || '',
        content: notification.content || '',
        uuid: uuidv4(),
      },
    ]);
  };

  return (
    <NotificationsContext.Provider
      value={{
        notifications: { showNotification },
      }}
    >
      {children}
      <Notifications
        openNotifications={openNotifications}
        setOpenNotifiactions={setOpenNotifiactions}
      />
    </NotificationsContext.Provider>
  );
};
