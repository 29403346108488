import { getApp, getApps, initializeApp } from 'firebase/app';
import { getFirestore, initializeFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { getStorage } from 'firebase/storage';
import { connectFunctionsEmulator, getFunctions } from 'firebase/functions';
import { getDatabase } from 'firebase/database';

const config =
  process.env.GATSBY_ENV === 'production'
    ? {
        apiKey: 'AIzaSyBifCryvnhD_pkmimbwyn5VSpZp5yR_xMQ',
        authDomain: 'planning-poker-429a4.firebaseapp.com',
        databaseURL: 'https://planning-poker-429a4.firebaseio.com',
        projectId: 'planning-poker-429a4',
        storageBucket: 'planning-poker-429a4.appspot.com',
        messagingSenderId: '733595773818',
        appId: '1:733595773818:web:9741dd87f5c8ac86a984c3',
        measurementId: 'G-M7BW7N48DG',
      }
    : {
        apiKey: 'AIzaSyBgEmosEH13d_XhEajfuNHvHR-CjTpF5_0',
        authDomain: 'planning-poker-staging.firebaseapp.com',
        databaseURL: 'https://planning-poker-staging.firebaseio.com',
        projectId: 'planning-poker-staging',
        storageBucket: 'planning-poker-staging.appspot.com',
        messagingSenderId: '573352993723',
        appId: '1:573352993723:web:ccd2a3175db9ead1f0da9e',
        measurementId: 'G-S0F7V0N7HW',
      };

const appLength = getApps().length;

console.log('package appLength', appLength);

const app = appLength ? getApp() : initializeApp(config);

console.log('done package app');

initializeFirestore(app, {
  experimentalAutoDetectLongPolling: true,
});

export const auth = getAuth();
export const firestore = getFirestore();
export const storage = getStorage();
export const functions = getFunctions();

if (process.env.GATSBY_USE_FUNCTIONS_EMULATOR === 'true') {
  connectFunctionsEmulator(functions, '127.0.0.1', 5001);
}

export const database = getDatabase();
