import React from 'react';

export const Star = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.1399 19.1296L6.71723 27.6288C6.56548 28.1673 6.76421 28.7436 7.21537 29.0733C7.66653 29.4029 8.27475 29.4163 8.7399 29.1068L15.9999 24.2572L23.2599 29.1081C23.7248 29.4193 24.3341 29.4068 24.7859 29.0767C25.2377 28.7465 25.436 28.169 25.2826 27.6301L22.8599 19.131L28.8332 14.3415C29.2427 14.0114 29.4207 13.4712 29.2881 12.9615C29.1554 12.4519 28.7367 12.0677 28.2186 11.9802L20.8892 10.7561L17.1932 3.34604C16.7399 2.43999 15.2612 2.43999 14.8079 3.34604L11.1106 10.7547L3.78123 11.9788C3.26232 12.0653 2.84266 12.4496 2.70987 12.9597C2.57707 13.4699 2.75596 14.0106 3.16656 14.3402L9.1399 19.1296Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.1399 19.1296L6.71723 27.6288C6.56548 28.1673 6.76421 28.7436 7.21537 29.0733C7.66653 29.4029 8.27475 29.4163 8.7399 29.1068L15.9999 24.2572L23.2599 29.1081C23.7248 29.4193 24.3341 29.4068 24.7859 29.0767C25.2377 28.7465 25.436 28.169 25.2826 27.6301L22.8599 19.131L28.8332 14.3415C29.2427 14.0114 29.4207 13.4712 29.2881 12.9615C29.1554 12.4519 28.7367 12.0677 28.2186 11.9802L20.8892 10.7561L17.1932 3.34604C16.7399 2.43999 15.2612 2.43999 14.8079 3.34604L11.1106 10.7547L3.78123 11.9788C3.26232 12.0653 2.84266 12.4496 2.70987 12.9597C2.57707 13.4699 2.75596 14.0106 3.16656 14.3402L9.1399 19.1296Z"
      fill="white"
      fill-opacity="0.2"
    />
  </svg>
);
