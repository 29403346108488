import cx from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import styles from './Projectile.module.scss';
import anime from 'animejs/lib/anime.es.js';
import paperBall from './paperBall.png';
import nerfDart from './nerfDart.png';
import { ProjectileType } from '@we-agile-you/types-planning-poker';

export interface ProjectileEvent {
  id: string;
  projectileType: ProjectileType;
  emoji: string | null;
  trajectoryIndex: number;
  isReflected: boolean;
}

export const getIsReflected = () => Math.random() >= 0.5;
export const getTrajectoryIndex = () =>
  Math.floor(Math.random() * trajectories.length);

export const trajectories = [
  'M0 44.4896C111.5 -16.0104 452.5 5.48965 599.5 44.4896C585 44.4896 591 76.4896 591 83.4897C568.5 50.4898 568.5 83.4897 568.5 99.4896C568.52 94.4896 548.5 91.9896 548.5 99.4896',
  'M1 69.5C209.5 -22.4999 360 1.98967 599.5 44.4896C542.5 22.4897 547 77.4897 547 107.99C537.5 83.4897 519 93.9897 524.5 118.49C514 104.99 497 114.49 498.5 123.99',
  'M0 103C208.5 11.0001 362 -18.5103 599.5 44.4897C550 40.4897 557.5 79.4897 557.5 110.49C548.5 85.4897 530.5 89.4898 522 110.49C511.5 96.9898 502 100.99 496 110.49',
  'M0 44.4896C111.5 -16.0104 452.5 5.48965 599.5 44.4896C585 44.4896 588.5 92.4896 588.5 99.4896C583 81 568.5 83.4897 568.5 99.4896',
  'M1 69.5C209.5 -22.4999 360 1.98968 599.5 44.4896C542.5 22.4897 549 82 549 112.5C539.5 88 517 92 517 112.5',
  'M0 103C208.5 11.0001 362 -18.5103 599.5 44.4897C557.5 17 566.5 79.4897 566.5 110.49C557.5 85.4897 538.5 89.4898 530 110.49C519.5 96.9898 510 100.99 504 110.49',
];

export const Projectile = ({
  event,
  onComplete,
  onClick,
  onMouseEnter,
  onMouseLeave,
}: {
  event: ProjectileEvent;
  onComplete: () => void;
  onClick: () => void;
  onMouseEnter: () => void;
  onMouseLeave: () => void;
}) => {
  const [isAnimationInProgress, setIsAnimationInProgress] = useState(false);
  const pathEl = useRef<SVGPathElement>(null);
  const objectEl = useRef<HTMLDivElement>(null);
  const [boundingRect, setBoundingRect] = useState<DOMRect | null>(null);

  useEffect(() => {
    if (
      boundingRect &&
      pathEl.current &&
      objectEl.current &&
      !isAnimationInProgress
    ) {
      setIsAnimationInProgress(true);
      const path = anime.path(pathEl.current);
      anime({
        targets: objectEl.current,
        opacity: {
          value: 1,
          duration: 10,
        },
        translateX: path('x'),
        translateY: path('y'),
        rotate: () => {
          if (event.projectileType === 'paperBall') {
            return anime.random(-720, 720);
          }
          return path('angle');
        },
        duration: 1500,
        easing: 'easeOutSine',
        autoplay: true,
        complete: () =>
          anime({
            targets: objectEl.current,
            opacity: 0,
            duration: 1000,
            easing: 'linear',
            complete: () => {
              setIsAnimationInProgress(false);
              onComplete();
            },
          }),
      });
    }
  }, [pathEl.current, objectEl.current, boundingRect]);

  return (
    <>
      <div
        ref={(el) => {
          const rect = el?.getBoundingClientRect();

          if (!boundingRect && rect) {
            setBoundingRect(rect);
          }
        }}
        className={styles.wrapper}
      />
      {boundingRect &&
        createPortal(
          <div
            className={cx(styles['throwing-object-scene-container'], {
              [styles.reflected]: event.isReflected,
            })}
            style={{
              top: boundingRect.top,
              left: boundingRect.left,
              width: boundingRect.width,
            }}
            onClick={onClick}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
          >
            <div className={styles['throwing-object-scene']}>
              <svg
                width="600"
                height="128"
                viewBox="0 0 600 128"
                className={cx(styles['throwing-object-trajectory'])}
                stroke="none"
                fill="none"
              >
                <path ref={pathEl} d={trajectories[event.trajectoryIndex]} />
              </svg>
              <div ref={objectEl} className={cx(styles['throwing-object'])}>
                {event.projectileType === 'paperBall' && (
                  <img src={paperBall} />
                )}
                {event.projectileType === 'nerfDart' && <img src={nerfDart} />}
                {event.projectileType === 'emoji' && <span>{event.emoji}</span>}
              </div>
            </div>
          </div>,
          document.body,
        )}
    </>
  );
};
