import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import { CookiesBanner } from '../../containers/CookiesBanner/CookiesBanner';
import { ContactModal } from '../../containers/ContactModal/ContactModal';
import { useLocation } from '@we-agile-you/react-base';
import { NotificationsProvider } from '@we-agile-you/notifications';
import {
  CreateSubscriptionModal,
  RedirectedFromPaymentHandler,
} from '@we-agile-you/payments';
import {
  AuthModal,
  AuthModalIsOpen,
  useCurrentUser,
  updateCurrentUser,
} from '@we-agile-you/auth';
import { MyAccountModal } from '../../containers/my-account/MyAccountModal/MyAccountModal';
import { PricingModal } from '../../containers/premium/PricingModal/PricingModal';
import {
  EditDisplayNameModal,
  EditDisplayNameModalIsOpen,
} from '../../containers/EditDisplayNameModal/EditDisplayNameModal';
import { MyGamesModal } from '../../containers/MyGamesModal/MyGamesModal';
import InvitePlayersModal from '../../containers/poker/InvitePlayersModal/InvitePlayersModal';
import SettingsModal from '../../containers/poker/SettingsModal/SettingsModal';
import {
  CreatePremiumSuccessModal,
  ManageFacilitatorsModal,
  PaymentFailedAlert,
} from '@we-agile-you/payments';
import { useHandleCampaignUrl } from '../tracking/useHandleCampaignUrl';
import { NotAcceptedTermsAlert } from '../../containers/premium/NotAcceptedTermsAlert/NotAcceptedTermsAlert';
import {
  hotjarIdentify,
  HOTJAR_IDENTIFY_KEYS,
} from '../../vendors/hotjar/identify';
import { VotingHistoryModal } from '../../containers/VotingHistoryModal/VotingHistoryModal';
import { CONFIG } from '@we-agile-you/config';
import { sendEvent } from '@we-agile-you/events';
import { BlackFridayModal } from '../../containers/BlackFridayModal/BlackFridayModal';
import { VerifyEmailBanner } from '../../containers/VerifyEmailBanner/VerifyEmailBanner';
import { GeoLocalizationProvider } from '@we-agile-you/geo-localization';
import { CookiesSettingsModal } from '../../containers/CookiesSettingsModal/CookiesSettingsModal';

interface AppWrapperProps {
  children: ReactNode;
}

type AppContextType = {
  contactModal: [
    boolean | null,
    React.Dispatch<React.SetStateAction<boolean | null>>,
  ];
  authModal: [
    AuthModalIsOpen,
    React.Dispatch<React.SetStateAction<AuthModalIsOpen>>,
  ];
  myAccountModal: [
    boolean | null,
    React.Dispatch<React.SetStateAction<boolean | null>>,
  ];
  manageFacilitatorsModal: [
    boolean | null,
    React.Dispatch<React.SetStateAction<boolean | null>>,
  ];
  pricingModal: [
    boolean | null,
    React.Dispatch<React.SetStateAction<boolean | null>>,
  ];
  cookiesSettingsModal: [
    boolean | null,
    React.Dispatch<React.SetStateAction<boolean | null>>,
  ];
  createPremiumModal: [
    boolean | null,
    React.Dispatch<React.SetStateAction<boolean | null>>,
  ];
  editDisplayNameModal: [
    EditDisplayNameModalIsOpen,
    React.Dispatch<React.SetStateAction<EditDisplayNameModalIsOpen>>,
  ];
  myGamesModal: [
    boolean | null,
    React.Dispatch<React.SetStateAction<boolean | null>>,
  ];
  votingHistoryModal: [
    boolean | null,
    React.Dispatch<React.SetStateAction<boolean | null>>,
  ];
  invitePlayersModal: [
    boolean | null,
    React.Dispatch<React.SetStateAction<boolean | null>>,
  ];
  settingsModal: [
    boolean | null,
    React.Dispatch<React.SetStateAction<boolean | null>>,
  ];
  handleProceedToCreatePremium: (
    isPurchaseNow: boolean,
    isNeedQuote: boolean,
  ) => void;
};

export const AppContext = React.createContext<AppContextType>({
  contactModal: [null, (_isOpen) => {}],
  cookiesSettingsModal: [null, (_isOpen) => {}],
  authModal: [null, (_isOpen) => {}],
  myAccountModal: [null, (_isOpen) => {}],
  manageFacilitatorsModal: [null, (_isOpen) => {}],
  pricingModal: [null, (_isOpen) => {}],
  createPremiumModal: [null, (_isOpen) => {}],
  editDisplayNameModal: [null, (_isOpen) => {}],
  myGamesModal: [null, (_isOpen) => {}],
  votingHistoryModal: [null, (_isOpen) => {}],
  invitePlayersModal: [null, (_isOpen) => {}],
  settingsModal: [null, (_isOpen) => {}],
  handleProceedToCreatePremium: () => {},
});

export const AppWrapper = ({ children }: AppWrapperProps) => {
  const [isContactOpen, setIsContactOpen] = useState<boolean | null>(null);
  const [isAuthModalOpen, setIsAuthModalOpen] = useState<AuthModalIsOpen>(null);
  const [isMyAccountModalOpen, setIsMyAccountModalOpen] = useState<
    boolean | null
  >(null);
  const [isManageFacilitatorsModalOpen, setIsManageFacilitatorsModalOpen] =
    useState<boolean | null>(null);
  const [isPricingModalOpen, setIsPricingModalOpen] = useState<boolean | null>(
    null,
  );
  const [isCreatePremiumModalOpen, setIsCreatePremiumModalOpen] = useState<
    boolean | null
  >(null);
  const [isCreatePremiumSuccessModalOpen, setIsCreatePremiumSuccessModalOpen] =
    useState<boolean | null>(null);
  const [isEditDisplayNameModalOpen, setIsEditDisplayNameModalOpen] =
    useState<EditDisplayNameModalIsOpen>(null);
  const [isMyGamesModalOpen, setIsMyGamesModalOpen] = useState<boolean | null>(
    null,
  );
  const [isInviteModalOpen, setIsInviteModalOpen] = useState<boolean | null>(
    null,
  );
  const [isVotingHistoryModalOpen, setIsVotingHistoryModalOpen] = useState<
    boolean | null
  >(null);
  const [isSettingsModalOpen, setIsSettingsModalOpen] = useState<
    boolean | null
  >(null);
  const [isCookiesSettingsModalOpen, setIsCookiesSettingsModal] = useState<
    boolean | null
  >(null);
  const [isPurchaseNow, setIsPurchaseNow] = useState<boolean | null>(null);
  const [isNeedQuote, setIsNeedQuote] = useState<boolean | null>(null);
  const { uid, user, isAnonymous } = useCurrentUser();

  const pathname = useLocation().pathname;

  const isUserLoaded = !!user;
  useEffect(() => {
    if (!isUserLoaded) return;

    updateCurrentUser({ splitTest: CONFIG.splitTest });
  }, [isUserLoaded]);

  useEffect(() => {
    sendEvent({ id: 'split-test-detected', splitTest: CONFIG.splitTest });
  }, []);

  useEffect(() => {
    if (isCreatePremiumModalOpen) {
      hotjarIdentify(HOTJAR_IDENTIFY_KEYS.OPENED_CREATE_PREMIUM_MODAL);
    }
  }, [isCreatePremiumModalOpen]);

  useEffect(() => {
    if (pathname === '/manage-account/') {
      if (isAnonymous === true) {
        setIsAuthModalOpen('sign-in');

        return;
      }

      setIsMyAccountModalOpen(true);
    }
  }, [pathname, isAnonymous]);

  useEffect(() => {
    if (pathname === '/pricing' || pathname === '/pricing/') {
      setIsPricingModalOpen(true);
    }
  }, [pathname]);

  const fromQuote = useLocation().search?.includes('quote=true');

  useEffect(() => {
    if (fromQuote) {
      setIsPricingModalOpen(true);
    }
  }, [fromQuote]);

  useHandleCampaignUrl();

  const handleProceedToCreatePremium = useCallback(
    (isPurchaseNow: boolean, isNeedQuote: boolean) => {
      setIsPurchaseNow(isPurchaseNow);
      setIsNeedQuote(isNeedQuote);
      setIsCreatePremiumModalOpen(true);
      setIsPricingModalOpen(false);
      hotjarIdentify(HOTJAR_IDENTIFY_KEYS.PROCEEDED_TO_PAYMENT, uid);
    },
    [uid],
  );

  return (
    <GeoLocalizationProvider>
      <AppContext.Provider
        value={{
          contactModal: [isContactOpen, setIsContactOpen],
          cookiesSettingsModal: [
            isCookiesSettingsModalOpen,
            setIsCookiesSettingsModal,
          ],
          authModal: [isAuthModalOpen, setIsAuthModalOpen],
          myAccountModal: [isMyAccountModalOpen, setIsMyAccountModalOpen],
          manageFacilitatorsModal: [
            isManageFacilitatorsModalOpen,
            setIsManageFacilitatorsModalOpen,
          ],
          pricingModal: [isPricingModalOpen, setIsPricingModalOpen],
          createPremiumModal: [
            isCreatePremiumModalOpen,
            setIsCreatePremiumModalOpen,
          ],
          editDisplayNameModal: [
            isEditDisplayNameModalOpen,
            (is) => {
              setIsEditDisplayNameModalOpen(is);
            },
          ],
          myGamesModal: [isMyGamesModalOpen, setIsMyGamesModalOpen],
          invitePlayersModal: [isInviteModalOpen, setIsInviteModalOpen],
          settingsModal: [isSettingsModalOpen, setIsSettingsModalOpen],
          votingHistoryModal: [
            isVotingHistoryModalOpen,
            setIsVotingHistoryModalOpen,
          ],
          handleProceedToCreatePremium,
        }}
      >
        <NotificationsProvider>
          {children}

          <CookiesBanner />

          <PaymentFailedAlert
            avoidShowingAlert={
              !!isMyAccountModalOpen ||
              !!isPricingModalOpen ||
              !!isCreatePremiumModalOpen ||
              !!isCreatePremiumSuccessModalOpen
            }
          />
          <NotAcceptedTermsAlert />
          <RedirectedFromPaymentHandler
            onOpenCreatePremiumSuccessModal={() =>
              setIsCreatePremiumSuccessModalOpen(true)
            }
          />

          {isContactOpen && (
            <ContactModal onClose={() => setIsContactOpen(false)} />
          )}

          {isPricingModalOpen && (
            <PricingModal onClose={() => setIsPricingModalOpen(false)} />
          )}
          {isCookiesSettingsModalOpen && (
            <CookiesSettingsModal
              onClose={() => setIsCookiesSettingsModal(false)}
            />
          )}

          {isCreatePremiumModalOpen && (
            <CreateSubscriptionModal
              isPurchaseNow={!!isPurchaseNow}
              isNeedQuote={!!isNeedQuote}
              onClose={() => setIsCreatePremiumModalOpen(false)}
              onGoBackToPricingModal={() => {
                setIsCreatePremiumModalOpen(false);
                setIsPricingModalOpen(true);
              }}
              onSubscriptionCreatedSuccessfully={() => {
                setIsCreatePremiumModalOpen(false);
                setIsCreatePremiumSuccessModalOpen(true);
              }}
            />
          )}

          {isCreatePremiumSuccessModalOpen && (
            <CreatePremiumSuccessModal
              onClose={() => setIsCreatePremiumSuccessModalOpen(false)}
            />
          )}

          {isMyAccountModalOpen && (
            <MyAccountModal onClose={() => setIsMyAccountModalOpen(false)} />
          )}

          {isManageFacilitatorsModalOpen && (
            <ManageFacilitatorsModal
              onClose={() => setIsManageFacilitatorsModalOpen(false)}
            />
          )}

          {isEditDisplayNameModalOpen && (
            <EditDisplayNameModal
              onClose={() => setIsEditDisplayNameModalOpen(false)}
              isPromptedModal={isEditDisplayNameModalOpen === 'prompted'}
            />
          )}

          {isAuthModalOpen && (
            <AuthModal
              authType={isAuthModalOpen || 'sign-up'}
              onChangeAuthType={setIsAuthModalOpen}
              onClose={() => setIsAuthModalOpen(false)}
            />
          )}

          {isMyGamesModalOpen && (
            <MyGamesModal onClose={() => setIsMyGamesModalOpen(false)} />
          )}

          {isInviteModalOpen && (
            <InvitePlayersModal onClose={() => setIsInviteModalOpen(false)} />
          )}

          {isVotingHistoryModalOpen && (
            <VotingHistoryModal
              onClose={() => setIsVotingHistoryModalOpen(false)}
            />
          )}

          {isSettingsModalOpen && (
            <SettingsModal onClose={() => setIsSettingsModalOpen(false)} />
          )}

          <BlackFridayModal />
          <VerifyEmailBanner />
        </NotificationsProvider>
      </AppContext.Provider>
    </GeoLocalizationProvider>
  );
};
