import React, { RefObject } from 'react';

import cx from 'classnames';

import styles from './Input.module.scss';

export interface InputProps {
  /** Value of the input */
  value?: string | number;
  /** html placeholder attr */
  placeholder?: string;
  /** html id attr */
  id?: string;
  /** html required attr */
  required?: boolean;
  /** html auto-complete attr */
  autoComplete?: string;
  /** html max-length attr */
  maxLength?: number;
  /** set error styles */
  isError?: boolean;
  /** html read-only attr */
  readOnly?: boolean;
  /** html type attr */
  type?: 'text' | 'password' | 'email' | 'number';
  /** size of the input */
  size?: 'm' | 's';
  /** html name attr */
  name?: string;
  /** html min attr */
  min?: number;
  /** For e2e testing */
  'data-test'?: string;
  /** Change event handler */
  onChange?: (value: string) => void;
  /** Focus event handler */
  onFocus?: () => void;
  /** Blur event handler */
  onBlur?: () => void;
  /** onKeyDown event handler */
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  /** React ref */
  ref?: RefObject<HTMLInputElement>;
}

const InputFC: React.ForwardRefRenderFunction<HTMLInputElement, InputProps> = (
  props,
  ref,
) => {
  const {
    value,
    readOnly,
    required,
    maxLength,
    onChange,
    onFocus,
    size,
    isError,
    min,
    onKeyDown,
    onBlur,
    autoComplete,
    placeholder,
    name,
  } = props;
  const type = props.type || 'text';

  function handleInputChange(e: React.FormEvent) {
    const { value } = e.target as HTMLInputElement;

    if (onChange) {
      onChange(value);
    }
  }

  function handleInputFocus(): void {
    if (onFocus) {
      onFocus();
    }
  }

  function handleInputBlur(): void {
    if (onBlur) {
      onBlur();
    }
  }

  return (
    <input
      id={name}
      ref={ref}
      className={cx(
        styles['input'],
        size && styles[`input--size-${size}`],
        isError && styles['input--error'],
      )}
      type={type}
      value={value}
      min={min}
      onFocus={handleInputFocus}
      onBlur={handleInputBlur}
      onChange={handleInputChange}
      maxLength={maxLength}
      readOnly={readOnly}
      placeholder={placeholder}
      required={required}
      name={name}
      data-test={props['data-test']}
      autoComplete={autoComplete}
      onKeyDown={onKeyDown}
    />
  );
};

export const Input = React.forwardRef(InputFC);
