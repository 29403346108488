import cx from 'classnames';
import { useEffect, useState } from 'react';
import { useGameContext } from '../../hooks/useGameContext';
import { usePlayerDistribution } from '../../hooks/usePlayerDistribution';
import { Player } from './Player/Player';
import { Player as PlayerType } from '../../../types';
import { throttle } from 'lodash';

import styles from './Table.module.scss';
import { TableContent } from './TableContent/TableContent';
import { TopLane } from './TopLane/TopLane';
import { useAuthContext } from '../../../auth/useAuthContext';
import { ProjectileType } from '@we-agile-you/types-planning-poker';
import { playerShootProjectile, playerVote } from '../../data/actions';
import { SpotlightTarget } from '@atlaskit/onboarding';
import { ShowCardsTooltip } from './ShowCardsTooltip';
import { StartNewGameTooltip } from './StartNewGameTooltip';

const USE_PANEL_POPUP_MEDIA_QUERY = 920;

export const Table = () => {
  const gameContext = useGameContext();
  const auth = useAuthContext();
  const [hasSomeProjectileThrown, setHasSomeProjectileThrown] = useState(false);
  const [layout, setLayout] = useState<'vertical' | 'horizontal' | null>(null);
  const { playerDistribution } = usePlayerDistribution(layout);
  const [hasSomeProjectilePickerOpen, setHasSomeProjectilePickerOpen] =
    useState<string | false | null>(false);

  const game = gameContext?.game;
  const players = gameContext?.players;
  const appId = auth && auth.appId;
  const uid = auth && auth.uid;
  const incomingProjectiles = gameContext?.projectiles || [];

  useEffect(() => {
    setLayout(
      window.innerWidth < USE_PANEL_POPUP_MEDIA_QUERY
        ? 'vertical'
        : 'horizontal',
    );

    const handleWindowResize = throttle(() => {
      setLayout(
        window.innerWidth < USE_PANEL_POPUP_MEDIA_QUERY
          ? 'vertical'
          : 'horizontal',
      );
    }, 1000);

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  if (
    !game ||
    !players ||
    !auth ||
    !auth.user ||
    !auth.uid ||
    !appId ||
    !playerDistribution
  ) {
    return null;
  }

  const user = auth.user;

  const playersNotSpectators = players.filter((player) => !player.isSpectator);

  const isUserLonely = players.length === 1 && players[0].uid === uid;
  const isCrowded = players.length && players.length > 10;
  const isAllPlayersVoted =
    !game.cardsUp &&
    !game.cardsUpCountdown &&
    playersNotSpectators.length >= 1 &&
    playersNotSpectators.every(
      (player) => typeof player.vote !== 'undefined' && player.vote !== null,
    );

  const handleProjectileThrowComplete = (projectileId: string) => {
    if (!hasSomeProjectileThrown) {
      setHasSomeProjectileThrown(true);
    }

    gameContext.handleProjectileThrowComplete(projectileId);
  };

  const renderPlayer = (
    player: PlayerType,
    position: 'top' | 'left' | 'right' | 'bottom',
  ) => {
    const hasProjectileSelector =
      !!game.enableProjectiles &&
      !!user?.enableFunFeatures &&
      (!hasSomeProjectilePickerOpen ||
        hasSomeProjectilePickerOpen === player.uid) &&
      player.uid !== auth.uid &&
      !!player.displayName;

    const handleShootProjectile = (
      projectileType: ProjectileType,
      emoji?: string,
    ) => {
      playerShootProjectile(
        appId,
        player.gameId,
        player.uid,
        projectileType,
        emoji,
      );
    };

    const handleIsProjectilePickerOpenChange = (
      isOpen: string | false | null,
    ) => {
      setHasSomeProjectilePickerOpen(isOpen);
    };

    return (
      <div className={styles['player-container']}>
        <Player
          player={player}
          position={position}
          onIsProjectilePickerOpenChange={(isOpen) =>
            isOpen !== null &&
            handleIsProjectilePickerOpenChange(isOpen ? player.uid : false)
          }
          hasProjectileSelector={!!hasProjectileSelector}
          onShootProjectile={handleShootProjectile}
          onProjectileShootComplete={handleProjectileThrowComplete}
          incomingProjectiles={incomingProjectiles.filter(
            (projectile) => projectile.targetPlayerId === player.uid,
          )}
          isCurrentUserPlayer={player.uid === auth.uid}
          isCurrentUserTheOwner={game.ownerId === auth.uid}
          onChangePoints={(value) =>
            game.id && playerVote(appId, game.id, value)
          }
        />
      </div>
    );
  };

  return (
    <div
      className={cx(styles.wrapper, isCrowded && styles['wrapper--is-crowded'])}
      data-test="table"
    >
      <div
        className={cx(
          styles.container,
          isUserLonely && styles['container--is-user-lonely'],
        )}
      >
        <div />
        <div className={cx(styles.top)}>
          <TopLane isUserLonely={isUserLonely}>
            {playerDistribution.top.map((player) =>
              renderPlayer(player, 'top'),
            )}
          </TopLane>
        </div>
        <div />
        <div className={styles.left}>
          {playerDistribution.left.map((player) =>
            renderPlayer(player, 'left'),
          )}
        </div>

        <SpotlightTarget name="table">
          <div
            className={cx(
              styles.table,
              isAllPlayersVoted && styles['glow-on-hover'],
            )}
          >
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <div className={styles['table-content']}>
              <TableContent />
            </div>
          </div>
        </SpotlightTarget>
        <ShowCardsTooltip />
        <StartNewGameTooltip />
        <div className={styles.right}>
          {playerDistribution.right.map((player) =>
            renderPlayer(player, 'right'),
          )}
        </div>
        <div />
        <div className={styles.bottom}>
          {playerDistribution.bottom.map((player) =>
            renderPlayer(player, 'bottom'),
          )}
        </div>
        <div />
      </div>
    </div>
  );
};
