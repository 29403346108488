import { createContext } from 'react';
import { AppData, AppUser } from '../app/data';
import { User } from '../types';

export type AuthState = {
  appId: string;
  uid: string;
  user: User | null;
  appData: AppData | null;
  appUsers: AppUser[] | null;
};

export const AuthContext = createContext<AuthState>({
  appId: '',
  uid: '',
  user: null,
  appData: null,
  appUsers: null,
});
