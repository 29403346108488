import { User } from '../../types';
import { Project, Field, ProjectApi } from '../api';

const ALL_POSSIBLE_NAMES = [
  'story point estimate',
  'story points',
  'story point',
  'story points estimate',
];

export const guessStoryPointsField = (
  currentUser: User,
  fields: Field[],
  project?: Project | ProjectApi | null,
): Field | null => {
  const userSelectedField = currentUser.lastSelectedStoryPointsField;

  if (userSelectedField) {
    return userSelectedField;
  }

  if (!fields) {
    return null;
  }

  const expectedName =
    project?.style === 'classic' ? 'story points' : 'story point estimate';

  const expectedNameField = fields.find((field) => {
    return (
      field?.name?.toLowerCase().includes(expectedName) ||
      field?.untranslatedName?.toLowerCase().includes(expectedName)
    );
  });

  if (expectedNameField) {
    return expectedNameField;
  }

  const field = fields.find((field) => {
    return ALL_POSSIBLE_NAMES.find(
      (expectedName) =>
        field?.name?.toLowerCase().includes(expectedName) ||
        field?.untranslatedName?.toLowerCase().includes(expectedName),
    );
  });

  return field || null;
};
