import React from 'react';

import styles from './StatsKpi.module.scss';

interface StatsKpiProps {
  label: string;
  isChart?: boolean;
  value: React.ReactNode;
}

export const StatsKpi = ({ label, isChart, value }: StatsKpiProps) => {
  return (
    <div className={styles['stats-kpi']} data-test="stats-kpi">
      <div className={styles['label']} data-test="stats-kpi-label">
        {label}
      </div>
      <div
        className={isChart ? '' : styles['value']}
        data-test="stats-kpi-value"
      >
        {value}
      </div>
    </div>
  );
};
