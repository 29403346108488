import { Field } from '@atlaskit/form';
import Select from '@atlaskit/select';
import { useEffect, useState } from 'react';
import { getIssueTypes, IssueType } from '../../../../../../jira/api';
import styles from '../Pickers.module.scss';

export const TypePicker = ({
  onChange,
  selectedIssueTypes,
}: {
  onChange: (issueTypes: IssueType[] | null) => void;
  selectedIssueTypes?: IssueType[] | null;
}) => {
  const [issueTypes, setIssueTypes] = useState<IssueType[] | null>(null);
  const [error, setError] = useState<Error | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);

    getIssueTypes()
      .then((issueTypes) => {
        setIssueTypes(
          issueTypes.filter(
            (status, index) =>
              index ===
              issueTypes.findIndex((_status) => _status.name === status.name),
          ),
        );
      })
      .catch((e) => {
        console.error('issue types error', e);
        setError(e);
        setIssueTypes(null);
      })
      .finally(() => setIsLoading(false));
  }, []);

  const issueTypesOptions = issueTypes?.map((issueType) => ({
    value: issueType,
    label: issueType.name,
  }));

  const values = issueTypesOptions?.filter(
    (option) =>
      selectedIssueTypes?.find(
        (selectedIssueType) => selectedIssueType.name === option.value.name,
      ),
  );

  const resolveNoOptionsMessage = () => {
    if (error) {
      return error.message;
    }

    return 'No issue types found';
  };

  return (
    <div className={styles['filter-select']}>
      <Field aria-required={true} name="type" label="Issue type">
        {() => (
          <Select
            placeholder="Type"
            options={issueTypesOptions || []}
            isLoading={isLoading}
            onChange={(multiValue) => {
              const values = multiValue?.map((option) => option.value);
              onChange(values);
            }}
            value={values?.length ? values : []}
            noOptionsMessage={resolveNoOptionsMessage}
            isClearable
            isMulti
          />
        )}
      </Field>
    </div>
  );
};
