import { useState } from 'react';
import { ButtonDropdown } from '../../../../components/ButtonDropdown/ButtonDropdown';
import { useAuthContext } from '../../../auth/useAuthContext';
import Avatar from '@atlaskit/avatar';
import { Icon } from '@we-agile-you/react-base';
import { HoritzontalSpacing } from '../../../../components/spacings/HoritzontalSpacing/HoritzontalSpacing';
import { DropdownItem } from '../../../../components/DropdownItem/DropdownItem';
import { Switch } from '../../../../components/Switch/Switch';
import { updateCurrentUser } from '../../../auth/data';

export const AuthButton = () => {
  const authContext = useAuthContext();
  const user = authContext && authContext.user;
  const [isOpen, setIsOpen] = useState(false);

  const appId = authContext && authContext.appId;

  const handleSpectatorToggleChange = (isChecked: boolean) => {
    if (!appId) {
      return;
    }

    updateCurrentUser(appId, {
      isSpectator: isChecked,
    });
  };

  if (!user) {
    return null;
  }

  return (
    <ButtonDropdown
      buttonComponent="avatar"
      avatar={user.avatar}
      dropdownAlign="bottom"
      isOpen={isOpen}
      buttonTooltip={user.displayName}
      onIsOpenChange={setIsOpen}
      translate="no"
      dropdown={
        <>
          <DropdownItem
            label="Spectator mode"
            icon={<Icon icon="eye2" />}
            rightContent={
              <>
                <Switch isActive={!!user?.isSpectator} />
                <HoritzontalSpacing spacing="spacing-xxs" />
              </>
            }
            onClick={() => handleSpectatorToggleChange(!user?.isSpectator)}
          />
        </>
      }
    />
  );
};
