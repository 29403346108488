import cx from 'classnames';
import React, { useState, useEffect } from 'react';

import Button, { LoadingButton } from '@atlaskit/button';
import Spinner from '@atlaskit/spinner';

import styles from './Panel.module.scss';
import { Inline, Stack } from '@atlaskit/primitives';
import { CardSelector } from '../CardSelector/CardSelector';
import { useGameContext } from '../../hooks/useGameContext';
import { playerVote, showCards } from '../../data/actions';
import { Paragraph } from '../../../../components/Paragraph/Paragraph';
import { VerticalSpacing } from '../../../../components/spacings/VerticalSpacing/VerticalSpacing';
import { updateCurrentUser } from '../../../auth/data';
import { useAuthContext } from '../../../auth/useAuthContext';
import { openIssueModal } from '../../../jira/actions';

interface PanelProps {
  onSelectCard?: (card: string | null) => void;
}

const Panel: React.FC<PanelProps> = ({ onSelectCard }) => {
  const gameContext = useGameContext();
  const auth = useAuthContext();
  const [isLoadingIssueModal, setIsLoadingIssueModal] = useState(false);
  const appId = auth && auth.appId;

  const game = gameContext?.game;
  const deckCards = gameContext?.deckCards;
  const currentPlayer = gameContext?.currentPlayer || null;
  const isOnCountdown = gameContext?.isOnCountdown || false;
  const isLoading = !game || !appId || currentPlayer === null;
  const uid = auth && auth?.uid;
  const currentIssue =
    typeof gameContext?.currentIssueIndex === 'number' &&
    !!game?.issues &&
    game?.issues[gameContext.currentIssueIndex];

  const [isTimeout, setIsTimeout] = useState(false);

  const handleNewVotingEnded = async () => {
    // if (
    //   !game.id ||
    //   !game.timerDurationMinutes ||
    //   !game.timerAutoRestart
    // )
    //   return;
    // cancelTimerForGame(game.id);
  };

  useEffect(() => {
    if (!isLoading) {
      return;
    }

    const handleTimeout = () => {
      console.error('Timeout on loading Panel');
      // check if this is useful for debugging as it is not in useEffect dependency
      console.error(gameContext);
      console.error(auth);

      setIsTimeout(true);
    };

    const timeout = window.setTimeout(handleTimeout, 10000);

    return () => {
      window.clearTimeout(timeout);
    };
  }, [isLoading]);

  const handleOpenIssue = () => {
    if (!currentIssue) {
      return;
    }

    setIsLoadingIssueModal(true);

    openIssueModal(currentIssue.key, () => {
      setIsLoadingIssueModal(false);
    });
  };

  function handleCardSelectorChange(card: string | null) {
    if (!gameContext || !game || !gameContext.players || !uid || !appId) {
      return;
    }

    const playersNoSpectatorsAndNotMe = gameContext.players.filter(
      (player) => !player.isSpectator && player.uid !== uid,
    );

    const everyoneVotedExceptMe =
      game &&
      !isOnCountdown &&
      !game.cardsUp &&
      playersNoSpectatorsAndNotMe.every(
        (player) => typeof player.vote !== 'undefined' && player.vote !== null,
      );

    if (game?.id && appId) {
      playerVote(appId, game.id, card);
    }

    if (game?.autoShowCards && everyoneVotedExceptMe && deckCards) {
      showCards({
        appId,
        game,
        players: gameContext.players.map((player) =>
          player.uid === uid ? { ...player, vote: card } : player,
        ),
        currentIssueIndex: gameContext.currentIssueIndex,
        deckCards,
      });
      handleNewVotingEnded();
    }

    if (onSelectCard) {
      onSelectCard(card);
    }
  }

  const handleDeactivateSpectator = () => {
    if (!appId) {
      return;
    }
    updateCurrentUser(appId, { isSpectator: false });
  };

  if (isLoading) {
    return (
      <div className={styles['loading-container']}>
        <Inline space="space.200">
          {isTimeout ? (
            <Stack space="space.050">
              <Inline space="space.100" alignBlock="center">
                <div>Still loading...</div>
                <Spinner interactionName="load" />
              </Inline>
              <div>
                Try refreshing the page, if it doesn't work please remember
                that:
              </div>
              <div>
                Some VPNs or network security softwares might prevent you from
                connecting.
              </div>
              <div>
                Please check your network/firewall configuration or ask help to
                the administrator.
              </div>
            </Stack>
          ) : (
            <Inline space="space.100" alignBlock="center">
              <div>Loading...</div>
              <Spinner interactionName="load" />
            </Inline>
          )}
        </Inline>
      </div>
    );
  }

  if (currentPlayer?.isSpectator) {
    return (
      <div className={styles['spectator-mode-container']}>
        <Paragraph align="center" sizeSmallScreen="micro">
          You are in spectator mode 👁
        </Paragraph>
        <VerticalSpacing spacing="spacing-xxs" />
        <Paragraph align="center" sizeSmallScreen="micro">
          <Button
            className={styles['deactivate-button']}
            onClick={handleDeactivateSpectator}
            appearance="link"
          >
            Deactivate
          </Button>
        </Paragraph>
      </div>
    );
  }
  function handleShowCardsButton() {
    if (!gameContext?.game || !gameContext?.players || !deckCards) {
      return;
    }

    showCards({
      appId,
      game: gameContext.game,
      players: gameContext.players,
      currentIssueIndex: gameContext.currentIssueIndex,
      deckCards,
    });
  }

  const currentPlayerCanShowCards =
    !game.whoCanShowCards ||
    (currentPlayer && game.whoCanShowCards.includes(currentPlayer.uid));

  return (
    <div className={cx(styles['panel'], !game.cardsUp && styles['is-open'])}>
      <div className={styles['card-list-label']}>
        {isOnCountdown || game.cardsUp ? (
          <Inline alignBlock="center" space="space.100" alignInline="center">
            <span className={styles['counting-votes-label']}>
              Counting votes...
            </span>
            {currentPlayerCanShowCards && (
              <div className={styles['count-now-button']}>
                <Button
                  spacing="none"
                  appearance="link"
                  onClick={handleShowCardsButton}
                >
                  Count now
                </Button>
              </div>
            )}
          </Inline>
        ) : (
          <Inline
            alignInline="center"
            alignBlock="center"
            space="space.100"
            grow="fill"
          >
            {currentIssue ? (
              <Inline space="space.050" alignBlock="center">
                <div>Now voting:</div>
                <LoadingButton
                  isLoading={isLoadingIssueModal}
                  appearance="link"
                  onClick={handleOpenIssue}
                  spacing="none"
                >
                  {currentIssue.key}
                </LoadingButton>
              </Inline>
            ) : (
              <div>Choose your estimation</div>
            )}
            <div role="img" aria-label="point below">
              👇
            </div>
          </Inline>
        )}
      </div>
      <div className={styles['card-list-container']}>
        {deckCards && (
          <CardSelector
            cards={deckCards}
            isDisabled={isOnCountdown || !!game.cardsUp}
            onChange={handleCardSelectorChange}
            value={currentPlayer && currentPlayer.vote}
          />
        )}
      </div>
    </div>
  );
};

export default Panel;
