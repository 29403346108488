import cx from 'classnames';
import { useEffect, useState } from 'react';

import styles from './Room.module.scss';
import { Table } from '../Table/Table';
import Panel from '../Panel/Panel';
import { useGameContext } from '../../hooks/useGameContext';
import { Loader } from '../../../../components/Loader/Loader';
import Results from '../Stats/Results';
import { useSidebarLayoutContext } from '../SidebarLayoutWrapper/useSidebarLayoutContext';

const Room = () => {
  const gameContext = useGameContext();
  const sidebarContext = useSidebarLayoutContext();

  const game = gameContext?.game;
  const isLoading = !game;

  const [isTimeout, setIsTimeout] = useState(false);

  useEffect(() => {
    if (!isLoading) {
      return;
    }

    const handleTimeout = () => {
      setIsTimeout(true);
    };

    const timeout = window.setTimeout(handleTimeout, 10000);

    return () => {
      window.clearTimeout(timeout);
    };
  }, [isLoading]);

  if (isLoading) {
    return (
      <div className={cx(styles.wrapper)}>
        <div className={styles['spacer-top']} />
        <div className={cx(styles['loading-container'])}>
          <Loader
            message={
              isTimeout ? (
                <>
                  Still loading...
                  <br />
                  Problems loading?
                  <br /> Some VPNs or network security softwares might prevent
                  you from connecting.
                  <br /> Please check your network/firewall configuration or ask
                  help to the administrator.
                </>
              ) : (
                'Loading game...'
              )
            }
          />
        </div>
      </div>
    );
  }

  return (
    <div className={cx(styles.wrapper)}>
      <div className={styles['spacer-top']} />
      <div className={cx(styles['table'])}>
        <Table />
      </div>
      <div
        className={cx(
          styles['room-bottom'],
          game.cardsUp && styles['room-bottom--results'],
        )}
      >
        <div
          className={cx(
            styles['panel'],
            !game.cardsUp && styles['panel--is-open'],
            sidebarContext.issuesSidebar[0] && styles['panel--is-sidebar-open'],
          )}
        >
          <Panel />
        </div>
        <div
          className={cx(
            styles['results'],
            game.cardsUp && styles['results--is-open'],
            sidebarContext.issuesSidebar[0] &&
              styles['results--is-sidebar-open'],
          )}
        >
          <Results />
        </div>
      </div>
      <div
        className={cx(
          styles['spacer-bottom'],
          !!game.cardsUp && styles['spacer-bottom--is-results'],
        )}
      />
    </div>
  );
};

export default Room;
