import React, { useState } from 'react';

import { Input } from '../../atoms/Input/Input';
import { Label } from '../../components/Label/Label';
import { Paragraph } from '../../atoms/text/Paragraph/Paragraph';
import { Icon } from '../../atoms/Icon/Icon';
import { FlexBox } from '../../atoms/FlexBox/FlexBox';

import styles from './FormInput.module.scss';
import { VerticalSpacing } from '../../atoms/spacings/VerticalSpacing/VerticalSpacing';
import { ButtonIcon } from '../../atoms/ButtonIcon/ButtonIcon';
import { EmojiPicker } from '../EmojiPicker/EmojiPicker';

export interface FormInputProps {
  /** Label for the input */
  label?: string;
  /** Value of the input */
  value?: string | number;
  /** Allow emojis on the input */
  showEmojiPicker?: boolean;
  /** small info message below input */
  inlineHint?: string;
  /** include button to clear inputs value */
  isClearable?: boolean;
  /** include button to clear inputs value */
  isError?: boolean;
  /** html id attr */
  id?: string;
  /** html required attr */
  required?: boolean;
  /** html auto-complete attr */
  autoComplete?: string;
  /** html max-length attr */
  maxLength?: number;
  /** html read-only attr */
  readOnly?: boolean;
  /** html type attr */
  type?: 'text' | 'password' | 'email' | 'number';
  /** html name attr */
  name?: string;
  /** html min attr */
  min?: number;
  /** For e2e testing */
  'data-test'?: string;
  /** Change event handler */
  onChange?: (value: string) => void;
  /** Focus event handler */
  onFocus?: () => void;
  /** Blur event handler */
  onBlur?: () => void;
}

const FormInputFC: React.ForwardRefRenderFunction<
  HTMLInputElement,
  FormInputProps
> = (props, ref) => {
  const {
    label,
    onFocus,
    onBlur,
    value,
    inlineHint,
    isClearable,
    isError,
    showEmojiPicker,
    ...inputProps
  } = props;
  const [isFocus, setIsFocus] = useState(false);

  const handleFocus = () => {
    setIsFocus(true);
    if (onFocus) {
      onFocus();
    }
  };
  const handleBlur = () => {
    setIsFocus(false);

    if (onBlur) {
      onBlur();
    }
  };

  const isLabelAPlaceholder = !value && value !== 0 && !isFocus;

  return (
    <div className={styles['form-group']}>
      {label && (
        <Label htmlFor={props.id} isPlaceholder={isLabelAPlaceholder}>
          {label}
        </Label>
      )}
      <Input
        ref={ref}
        value={value}
        onFocus={handleFocus}
        onBlur={handleBlur}
        isError={isError}
        {...inputProps}
      />
      {isClearable && !!value && (
        <ButtonIcon
          className={styles['clear-button']}
          icon={<Icon icon="close" />}
          onClick={() => props.onChange && props.onChange('')}
        />
      )}
      {inlineHint && (
        <>
          <VerticalSpacing spacing="spacing-xs" />
          <FlexBox alignItems="center">
            <div className={styles.icon}>
              <Icon icon="info" />
            </div>
            <Paragraph size="micro" color="info">
              {inlineHint}
            </Paragraph>
          </FlexBox>
        </>
      )}
      {showEmojiPicker && (
        <div className={styles['emoji-picker']}>
          <EmojiPicker
            buttonColor="secondary"
            buttonSize="xs"
            onEmojiSelect={(emoji) =>
              props.onChange && props.onChange(`${value}${emoji}`)
            }
          />
        </div>
      )}
    </div>
  );
};

export const FormInput = React.forwardRef(FormInputFC);
