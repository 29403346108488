import Form, { ErrorMessage, Field, useFormState } from '@atlaskit/form';
import TextField from '@atlaskit/textfield';
import LoadingButton from '@atlaskit/button/loading-button';
import { useIssueActions } from '../../hooks/useIssueActions';
import { Inline } from '@atlaskit/primitives';
import styles from './QuickAdd.module.scss';
import { useRef } from 'react';

type QuickAddForm = {
  key: string;
};

const LoadingButtonQuickAdd = ({ submitting }: { submitting: boolean }) => {
  const formState = useFormState<QuickAddForm>({
    values: true,
    pristine: true,
    dirty: true,
  });

  return (
    <LoadingButton
      type="submit"
      appearance="primary"
      isDisabled={!formState?.values?.key}
      isLoading={submitting}
    >
      Add issue
    </LoadingButton>
  );
};

export const QuickAdd = ({ onIssueAdded }: { onIssueAdded: () => void }) => {
  const { addIssueByKey } = useIssueActions();
  const inputRef = useRef<HTMLInputElement>(null);

  return (
    <Form<QuickAddForm>
      onSubmit={async ({ key }) => {
        try {
          await addIssueByKey(key);

          onIssueAdded();
        } catch (e: any) {
          console.error(e);
          const message =
            e.message || `Unexpected error adding the issue ${key}`;

          return { key: message };
        }
      }}
    >
      {({ formProps, submitting }) => (
        <form {...formProps}>
          <Inline alignInline="stretch" alignBlock="center" space="space.200">
            <div className={styles['field-wrapper']}>
              <Field
                aria-required={true}
                name="key"
                isRequired
                validate={() => {}}
              >
                {({ fieldProps, error }) => (
                  <div className={styles['field-container']}>
                    <TextField
                      autoComplete="off"
                      placeholder="Quick add issue by key (ie. ABC-123)"
                      ref={inputRef}
                      testId="quick-add-key"
                      {...fieldProps}
                    />
                    {error && (
                      <div className={styles['field-error']}>
                        <ErrorMessage>{error}</ErrorMessage>
                      </div>
                    )}
                  </div>
                )}
              </Field>
            </div>
            <LoadingButtonQuickAdd submitting={submitting} />
          </Inline>
        </form>
      )}
    </Form>
  );
};
