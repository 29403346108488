import { useEffect, useRef, useState } from 'react';
import ReactDom from 'react-dom';
import { StatsKpi } from '../StatsKpi/StatsKpi';
import party from 'party-js';
import styles from './Agreement.module.scss';
import { usePopover } from 'react-use-popover';
import { useHandleClickOuside, usePrevious } from '@we-agile-you/react-base';
import { useGameContext } from '../../../hooks/useGameContext';
import { DonutChart } from '../../../../../components/DonutChart/DonutChart';
import { Paragraph } from '../../../../../components/Paragraph/Paragraph';

interface AgreementProps {
  agreement?: number | null | 'COFFE_TIME';
}

export const Agreement = ({ agreement }: AgreementProps) => {
  const gameContext = useGameContext();
  const game = gameContext?.game;

  const cardsUp = !!game?.cardsUp;

  const containerRef = useRef<HTMLDivElement | null>(null);
  const prevCardsUp = usePrevious(cardsUp);
  const [bubbleElement, setBubbleElement] = useState<HTMLDivElement | null>(
    null,
  );
  const [isBubbleOpen, setIsBubbleOpen] = useState(false);
  const [isMouseOver, setIsMouseOver] = useState(false);

  const { dropdownStyle: bubbleStyle } = usePopover({
    anchorRef: containerRef,
    dropdownElement: bubbleElement,
    position: 'right',
    marginFromAnchor: 16,
  });

  useHandleClickOuside([containerRef], () => setIsBubbleOpen(false));

  const isFullConsensusVoting = agreement === 100;
  useEffect(() => {
    if (isFullConsensusVoting && !prevCardsUp && cardsUp) {
      window.setTimeout(() => {
        if (containerRef.current) {
          party.confetti(containerRef.current, {
            count: party.variation.range(20, 40),
          });
        }
        setIsBubbleOpen(true);
      }, 240);
    }
  }, [isFullConsensusVoting, cardsUp, prevCardsUp]);

  if (agreement === null || !game) return null;

  const unanimousVotingsForCurrentMeeting =
    game?.unanimousVotingsForCurrentMeeting || 0;
  let consensusText: string;

  if (isFullConsensusVoting) {
    switch (unanimousVotingsForCurrentMeeting) {
      case 0:
        consensusText = 'Hi! I am watching!';
        break;
      case 1:
        consensusText = 'Yeah! You reached full consensus.';
        break;
      case 2:
        consensusText = `Wow! You reached full consensus ${unanimousVotingsForCurrentMeeting} times in this meeting!`;
        break;
      case 3:
        consensusText = `Awesome! You reached full consensus ${unanimousVotingsForCurrentMeeting} times in this meeting!`;
        break;
      case 4:
        consensusText = `Amazing! You reached full consensus ${unanimousVotingsForCurrentMeeting} times in this meeting!`;
        break;
      case 5:
        consensusText = `How do you do it!? You reached full consensus ${unanimousVotingsForCurrentMeeting} times in this meeting!`;
        break;
      default:
        consensusText = `I am speechless! You reached full consensus ${unanimousVotingsForCurrentMeeting} times in this meeting!`;
        break;
    }
  } else {
    if (!unanimousVotingsForCurrentMeeting) {
      consensusText = 'Hi! I am watching!';
    } else {
      consensusText = `You have reached full consensus ${unanimousVotingsForCurrentMeeting} time${
        unanimousVotingsForCurrentMeeting !== 1 ? 's' : ''
      } in this meeting.`;
    }
  }

  if (agreement === 'COFFE_TIME') {
    return (
      <div className={styles['agreement']}>
        <StatsKpi label="Coffee time!" value={'☕'} />
      </div>
    );
  }

  return (
    <div className={styles['agreement']}>
      {agreement !== null && (
        <StatsKpi
          label="Agreement:"
          isChart
          value={
            <div
              ref={containerRef}
              onClick={() => setIsBubbleOpen(!isBubbleOpen)}
              className={styles['agreement-container']}
              onMouseEnter={() => setIsMouseOver(true)}
              onMouseLeave={() => setIsMouseOver(false)}
            >
              <DonutChart percentatge={agreement || 2} />
            </div>
          }
        />
      )}

      {(isBubbleOpen || isMouseOver) &&
        ReactDom.createPortal(
          <div
            ref={setBubbleElement}
            className={styles.bubble}
            style={{ ...bubbleStyle, zIndex: 9 }}
          >
            <Paragraph size="micro">{consensusText}</Paragraph>
          </div>,
          document.body,
        )}
    </div>
  );
};
