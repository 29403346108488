import React from 'react';
import cx from 'classnames';

import styles from './Container.module.scss';

interface ContainerProps {
  children: React.ReactNode;
  size?: 'small' | 'medium' | 'big';
  isCenteredInPage?: boolean;
  className?: string;
}

const ContainerFC: React.ForwardRefRenderFunction<
  HTMLDivElement,
  ContainerProps
> = ({ children, size, className, isCenteredInPage }, ref) => {
  return (
    <div
      className={cx(
        styles['container'],
        size && styles[`container--${size}`],
        isCenteredInPage && styles[`container--centered`],
        className,
      )}
      ref={ref}
    >
      {isCenteredInPage ? <div>{children}</div> : children}
    </div>
  );
};

export const Container = React.forwardRef(ContainerFC);
