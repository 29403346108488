import { Projectile } from '@we-agile-you/types-planning-poker';
import { createContext } from 'react';
import { Player, Game } from '../types';

export type GameContextType = {
  game: Game | null;
  players: Player[] | null;
  playersAll: Player[] | null;
  currentPlayer: Player | null;
  currentIssueIndex: number | null;
  isVoting: boolean | null;
  isOnCountdown: boolean;
  deckCards: string[];
  projectiles: Projectile[];
  handleProjectilesChange: (projectiles: Projectile[]) => void;
  handleProjectileThrowComplete: (projectileId: string) => void;
};

export const GameContext = createContext<GameContextType | null>(null);
