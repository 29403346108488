import React from "react";
import {
  BillingCycle,
  BillingDetails,
  CreateSubscriptionModalStep,
} from "../../types";

type CreateSubscriptionModalContextType = {
  currentStep: CreateSubscriptionModalStep;
  onChangeCurrentStep: (step: CreateSubscriptionModalStep) => void;
  facilitatorsQuantity: number;
  onChangeFacilitatorsQuantity: (facilitatorsQuantity: number) => void;
  billingCycle: BillingCycle;
  onChangeBillingCycle: (billingCycle: BillingCycle) => void;
  isPurchaseNow: boolean;
  onChangeIsPurchaseNow: (isPurchaseNow: boolean) => void;
  error: Error | null;
  onChangeError: (error: Error | null) => void;
  isLoading: string | false;
  onChangeIsLoading: (error: string | false) => void;
  isNeedQuoteChecked: boolean;
  onChangeIsNeedQuoteChecked: (error: boolean) => void;
  isEU: boolean;
  subscriptionId: string | null;
  onChangeSubscriptionId: (subscriptionId: string | null) => void;
  currentBillingDetails: BillingDetails | null;
  onChangeCurrentBillingDetails: (billingDetails: BillingDetails) => void;
  onSubscriptionCreatedSuccessfully: () => void;
};
export const CreateSubscriptionModalContext =
  React.createContext<CreateSubscriptionModalContextType>({
    facilitatorsQuantity: 1,
    onChangeFacilitatorsQuantity: () => {},
    billingCycle: "monthly",
    onChangeBillingCycle: () => {},
    isPurchaseNow: false,
    onChangeIsPurchaseNow: () => {},
    error: null,
    onChangeError: () => {},
    currentStep: "facilitators",
    onChangeCurrentStep: () => {},
    isLoading: false,
    onChangeIsLoading: () => {},
    isNeedQuoteChecked: false,
    onChangeIsNeedQuoteChecked: () => {},
    isEU: false,
    subscriptionId: null,
    onChangeSubscriptionId: () => {},
    currentBillingDetails: null,
    onChangeCurrentBillingDetails: () => {},
    onSubscriptionCreatedSuccessfully: () => {},
  });
