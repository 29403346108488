import { User } from './../types';
import { useEffect, useState } from 'react';
import { subscribeToCurrentUser } from './data';
import { SignInResult } from './useSignin';

export const useSubscribeToCurrentUser = (auth: SignInResult) => {
  const [user, setUser] = useState<null | User>(null);

  useEffect(() => {
    if (!auth?.isAuthenticated) {
      return;
    }

    return subscribeToCurrentUser(auth.appId, (user) =>
      setUser({
        ...user,
        enableFunFeatures: true,
      }),
    );
  }, [auth]);

  return user;
};
