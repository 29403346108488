import { useState } from 'react';
import { hideCards as hideCardsData, showCards } from '../../../data/actions';
import { useGameContext } from '../../../hooks/useGameContext';
import { useIssueActions } from '../../../hooks/useIssueActions';
import Button, { LoadingButton } from '@atlaskit/button';

import styles from '../Table.module.scss';
import { useAuthContext } from '../../../../auth/useAuthContext';

export const TableContent = () => {
  const gameContext = useGameContext();
  const auth = useAuthContext();
  const [isHidingCards, setIsHidingCards] = useState(false);
  const { unsetVotingIssue, voteNextIssue } = useIssueActions();

  // const { voteNextIssue, unsetVotingIssue } = useIssueActions();

  if (!gameContext || !auth || !gameContext.game) {
    return null;
  }

  const appId = auth.appId;

  const {
    game,
    isVoting,
    isOnCountdown,
    currentPlayer,
    currentIssueIndex,
    players,
    deckCards,
  } = gameContext;

  const haveMoreTicketsToVote =
    !!game.issues &&
    currentIssueIndex !== null &&
    currentIssueIndex + 1 < game.issues?.length;
  const isVotingIssue = currentIssueIndex !== null;

  const hideCards = async () => {
    if (!game?.id) return;

    setIsHidingCards(true);

    await hideCardsData(appId, game.id);

    setIsHidingCards(false);
  };

  // const handleVotingStarted = async () => {
  //   if (
  //     !pokerTable.id ||
  //     !pokerTable.timerDurationMinutes ||
  //     !pokerTable.timerAutoRestart
  //   )
  //     return;

  //   startTimerForGame(pokerTable.id, pokerTable.timerDurationMinutes);
  // };
  // const handleNewVotingEnded = async () => {
  //   if (
  //     !pokerTable.id ||
  //     !pokerTable.timerDurationMinutes ||
  //     !pokerTable.timerAutoRestart
  //   )
  //     return;

  //   cancelTimerForGame(pokerTable.id);
  // };
  async function handleNewVotingClick() {
    hideCards();
    // handleVotingStarted();

    if (isVotingIssue) unsetVotingIssue();
  }

  const handleVoteNextTicketClick = () => {
    hideCards();
    voteNextIssue();
    // handleVotingStarted();
  };

  function handleShowCardsButton() {
    if (!players) {
      return;
    }

    showCards({ game, appId, players, currentIssueIndex, deckCards });
    // handleNewVotingEnded();
  }

  const currentPlayerCanShowCards =
    !game.whoCanShowCards ||
    (currentPlayer && game.whoCanShowCards.includes(currentPlayer.uid));

  if (game.cardsUp) {
    if (currentPlayerCanShowCards) {
      const isLastIssue = isVotingIssue && !haveMoreTicketsToVote;
      let label = haveMoreTicketsToVote
        ? 'Vote next issue'
        : 'Start new voting';
      const labelSmall = haveMoreTicketsToVote ? 'Next issue' : 'New voting';
      const labelSuperSmall = haveMoreTicketsToVote ? 'Next' : 'Reset';
      if (isLastIssue) {
        label = 'Start new voting';
      }
      return (
        <div className={styles['show-cards-wrapper']}>
          <div>
            <LoadingButton
              onClick={
                haveMoreTicketsToVote
                  ? handleVoteNextTicketClick
                  : handleNewVotingClick
              }
              isLoading={isHidingCards}
              appearance="primary"
            >
              <span className={styles['label-super-small-screen']}>
                {labelSuperSmall}
              </span>
              <span className={styles['label-small-screen']}>{labelSmall}</span>
              <span className={styles['label-big-screen']}>{label}</span>
            </LoadingButton>
          </div>
        </div>
      );
    }

    return <div className={styles['table-caption']}>Voting finished</div>;
  }

  if (!isVoting) {
    return <div className={styles['table-caption']}>Pick your cards!</div>;
  }

  if (isOnCountdown) {
    return (
      <div className={styles['show-cards-wrapper']}>
        <p>{game.cardsUpCountdown}</p>
      </div>
    );
  }

  return currentPlayerCanShowCards ? (
    <div className={styles['show-cards-wrapper']}>
      <Button
        appearance="primary"
        onClick={handleShowCardsButton}
        testId="reval-cards-button"
      >
        <span className={styles['label-super-small-screen']}>Reveal</span>
        <span className={styles['label-small-screen']}>Reveal cards</span>
        <span className={styles['label-big-screen']}>Reveal cards</span>
      </Button>
    </div>
  ) : (
    <div className={styles['table-caption']}>Voting in progress</div>
  );
};
