import { createContext } from 'react';

export type IsOpenState = boolean | null;

export type SidebarContextType = {
  issuesSidebar: [
    IsOpenState,
    React.Dispatch<React.SetStateAction<IsOpenState>>,
  ];
  settingsSidebar: [
    IsOpenState,
    React.Dispatch<React.SetStateAction<IsOpenState>>,
  ];
  manageIssuesPanel: [
    IsOpenState,
    React.Dispatch<React.SetStateAction<IsOpenState>>,
  ];
};

export const SidebarLayoutWrapperContext = createContext<SidebarContextType>({
  issuesSidebar: [null, (isOpen) => isOpen],
  settingsSidebar: [null, (isOpen) => isOpen],
  manageIssuesPanel: [null, (isOpen) => isOpen],
});
