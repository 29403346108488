import React from 'react';

export const Shield = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.1429 3.55392C17.4216 3.55392 17.6991 3.54794 17.9752 3.53611C17.9917 3.82539 18 4.11699 18 4.41072C18 10.1878 14.7648 15.2131 10 17.7669C5.2352 15.2131 2 10.1878 2 4.41072C2 4.11699 2.00834 3.82539 2.02478 3.53611C2.30086 3.54795 2.57835 3.55392 2.85714 3.55392C5.37883 3.55392 7.79069 3.06531 10 2.17627C12.2093 3.06531 14.6212 3.55392 17.1429 3.55392ZM17.1429 1.55392C18.0221 1.55392 18.8858 1.48773 19.7294 1.36007C19.7846 1.66752 19.8316 1.97781 19.8702 2.29071C19.9132 2.63957 19.9458 2.99168 19.9675 3.3467C19.9891 3.69861 20 4.05339 20 4.41072C20 11.3293 15.9015 17.2906 10 19.9992C4.09849 17.2906 0 11.3293 0 4.41072C0 4.05339 0.0109329 3.69861 0.0324824 3.3467C0.0542236 2.99166 0.0867713 2.63954 0.129801 2.29067C0.168392 1.97778 0.215414 1.6675 0.270633 1.36007C1.1142 1.48773 1.97794 1.55392 2.85714 1.55392C5.40633 1.55392 7.82555 0.997509 10 -0.000488281C12.1744 0.997508 14.5937 1.55392 17.1429 1.55392ZM14.7809 7.62473C15.1259 7.19347 15.056 6.56418 14.6247 6.21917C14.1934 5.87416 13.5641 5.94408 13.2191 6.37534L9.91708 10.5029L7.70711 8.29293C7.31658 7.90241 6.68342 7.90241 6.29289 8.29293C5.90237 8.68345 5.90237 9.31662 6.29289 9.70714L9.29289 12.7071C9.49403 12.9083 9.77129 13.0142 10.0553 12.9985C10.3393 12.9828 10.6032 12.8468 10.7809 12.6247L14.7809 7.62473Z"
      fill="currentColor"
    />
  </svg>
);
