import React from 'react';

export const Face = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 12C4 7.58172 7.58172 4 12 4C16.4183 4 20 7.58172 20 12C20 16.4183 16.4183 20 12 20C7.58172 20 4 16.4183 4 12ZM12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2ZM9.60019 14.2006C9.15864 13.8688 8.53176 13.9578 8.20002 14.3994C7.86828 14.8409 7.95729 15.4678 8.39884 15.7996C9.40182 16.5531 10.6502 17.0001 12 17.0001C13.3498 17.0001 14.5982 16.5531 15.6012 15.7996C16.0427 15.4678 16.1317 14.8409 15.8 14.3994C15.4682 13.9578 14.8414 13.8688 14.3998 14.2006C13.7313 14.7028 12.9018 15.0001 12 15.0001C11.0982 15.0001 10.2687 14.7028 9.60019 14.2006ZM10 9.5C10 10.3284 9.32843 11 8.5 11C7.67157 11 7 10.3284 7 9.5C7 8.67157 7.67157 8 8.5 8C9.32843 8 10 8.67157 10 9.5ZM15.5 11C16.3284 11 17 10.3284 17 9.5C17 8.67157 16.3284 8 15.5 8C14.6716 8 14 8.67157 14 9.5C14 10.3284 14.6716 11 15.5 11Z"
      fill="currentColor"
    />
  </svg>
);
