import { Field } from '@atlaskit/form';
import Select from '@atlaskit/select';
import { Board } from '../../../../../../jira/api';

import styles from '../Pickers.module.scss';

export const BoardPicker = ({
  boardId,
  projectId,
  onChange,
  boards,
}: {
  onChange: (boardId: Board | null) => void;
  projectId?: string | null;
  boardId?: string | null;
  boards: Board[];
}) => {
  const projectBoards = boards.filter((board) => board.projectId === projectId);

  const boardOptions = projectBoards.map((board) => ({
    value: board,
    label: board.name,
  }));

  if (!boardOptions || boardOptions?.length <= 1) {
    return null;
  }

  return (
    <div className={styles['filter-select']}>
      <Field aria-required={true} name="board" label="Board">
        {() => (
          <Select
            placeholder="Board"
            options={boardOptions || []}
            isLoading={!boardOptions}
            value={
              boardOptions?.find((option) => option.value.id === boardId) ||
              null
            }
            isClearable
            onChange={(value) => onChange(value?.value || null)}
          />
        )}
      </Field>
    </div>
  );
};
