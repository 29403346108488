import { useState, useRef, useEffect } from 'react';
import { ButtonDropdown } from '../../../../components/ButtonDropdown/ButtonDropdown';

import Form, { ErrorMessage, Field, FormFooter } from '@atlaskit/form';

import ButtonGroup from '@atlaskit/button/button-group';
import LoadingButton from '@atlaskit/button/loading-button';
import TextField from '@atlaskit/textfield';
import { copyToClipboard } from '@we-agile-you/js-base';
import { showNotification } from '../../../jira/actions';

import styles from './InvitePlayersButton.module.scss';
import { InviteTooltip } from './InviteTooltip';
import { SpotlightTarget } from '@atlaskit/onboarding';

export type InviteData = {
  url: string;
};

export const InvitePlayersButton = ({
  isTableButton,
}: {
  isTableButton?: boolean;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const inputRef = useRef<HTMLInputElement | null>(null);

  const [gameUrl, setGameUrl] = useState<string | null>(null);

  useEffect(() => {
    window.AP.getLocation(function (location) {
      setGameUrl(location);
    });
  }, []);

  function copyLink() {
    const inputElement = inputRef.current;

    if (inputElement) {
      copyToClipboard(inputElement);

      showNotification({
        type: 'success',
        title: 'Invitation link copied to clipboard!',
        body: 'Share it to your teammates and start playing!',
      });

      setIsOpen(false);
    }
  }

  const handleSubmit = () => {
    copyLink();
  };

  if (!gameUrl) {
    return null;
  }

  return (
    <>
      <SpotlightTarget
        name={isTableButton ? 'invite-button-table' : 'invite-button'}
      >
        <ButtonDropdown
          buttonComponent="default"
          dropdownAlign={isTableButton ? 'right-bottom' : 'bottom-left'}
          isOpen={isOpen}
          onIsOpenChange={() => setIsOpen(!isOpen)}
          translate="no"
          dropdown={
            <div className={styles['dropdown']}>
              <Form<InviteData> onSubmit={handleSubmit}>
                {({ formProps, submitting }) => (
                  <form {...formProps}>
                    <Field name="url" label="Game's url" defaultValue={gameUrl}>
                      {({ fieldProps, error }) => (
                        <>
                          <TextField
                            autoComplete="off"
                            maxLength={60}
                            ref={inputRef}
                            placeholder="Set an optional game name"
                            {...fieldProps}
                          />
                          {error && <ErrorMessage>{error}</ErrorMessage>}
                        </>
                      )}
                    </Field>
                    <FormFooter>
                      <ButtonGroup>
                        <LoadingButton
                          type="submit"
                          appearance="primary"
                          isLoading={submitting}
                        >
                          Copy game link
                        </LoadingButton>
                      </ButtonGroup>
                    </FormFooter>
                  </form>
                )}
              </Form>
            </div>
          }
        >
          Invite players
        </ButtonDropdown>
      </SpotlightTarget>
      <InviteTooltip />
    </>
  );
};
