import React, { MouseEvent, ReactNode } from 'react';
import cx from 'classnames';

import styles from './DropdownItem.module.scss';
import { HoritzontalSpacing } from '../spacings/HoritzontalSpacing/HoritzontalSpacing';

interface DropdownItemProps {
  label: ReactNode;
  rightContent?: React.ReactNode;
  icon?: React.ReactNode;
  onClick?: (event: MouseEvent<HTMLDivElement>) => void;
  isDisabled?: boolean;
  isDanger?: boolean;
}

export const DropdownItem: React.FC<DropdownItemProps> = ({
  label,
  rightContent,
  icon,
  onClick,
  isDisabled,
  isDanger,
}) => {
  const handleClick = (e: MouseEvent<HTMLDivElement>) => {
    if (onClick && !isDisabled) {
      onClick(e);
    }
  };

  return (
    <div
      onClick={handleClick}
      className={cx(
        styles.item,
        isDisabled && styles.itemDisabled,
        isDanger && styles.itemDanger,
      )}
    >
      {icon ? (
        <span className={styles.icon}>{icon}</span>
      ) : (
        <HoritzontalSpacing spacing="spacing-l" />
      )}
      <span className={styles.label}>{label}</span>
      {rightContent ? (
        <div className={styles.right}>{rightContent}</div>
      ) : (
        <HoritzontalSpacing spacing="spacing-l" />
      )}
    </div>
  );
};
