import React from 'react';
import cx from 'classnames';
import emojiRegex from 'emoji-regex';

import styles from './CardValueSide.module.scss';

interface CardValueSideProps {
  card: string;
  size?: 'm' | 'l' | 'xl';
  isSelected?: boolean;
  isValueSideStyle?: boolean;
  isButton?: boolean;
  isTableButton?: boolean;
  isDisabled?: boolean;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  color?: 'default' | 'alternative';
}

const regex = emojiRegex();

export const CardValueSide: React.FC<CardValueSideProps> = ({
  card,
  size,
  isSelected,
  isValueSideStyle,
  isButton,
  isTableButton,
  isDisabled,
  onClick,
  color,
}) => {
  const className = cx(
    styles['value-side'],
    isSelected && styles['is-selected'],
    isValueSideStyle && styles['is-value-side-style'],
    color && styles[`color-${color}`],
    isButton && styles['is-button'],
    !card && styles['is-empty'],
    isTableButton && styles['is-table-button'],
    isDisabled && styles['is-disabled'],
    size && styles[size],
    'notranslate',
  );

  let cardContent = <span>{card}</span>;

  const matches = card.matchAll(regex);

  let match = matches.next();

  if (match.value) {
    let cardString = card;

    while (match.value) {
      const emoji = match.value[0];
      cardString = cardString.replace(
        emoji,
        `<span style="font-weight: normal">${emoji}</span>`,
      );
      match = matches.next();
    }

    cardContent = <span dangerouslySetInnerHTML={{ __html: cardString }} />;
  }

  if (isButton || isTableButton) {
    return (
      <button
        onClick={(event) => {
          event.stopPropagation();
          event.preventDefault();
          if (!isDisabled && onClick) {
            onClick(event);
          }
        }}
        className={className}
        translate="no"
      >
        {cardContent}
      </button>
    );
  }

  return (
    <div className={className} translate="no">
      {cardContent}
    </div>
  );
};
