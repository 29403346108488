import { LogoIcon } from '../../../../components/LogoIcon/LogoIcon';
import styles from './GameHeader.module.scss';
import { AuthButton } from '../AuthButton/AuthButton';
import { Inline } from '@atlaskit/primitives';
import { InvitePlayersButton } from '../InvitePlayersButton/InvitePlayersButton';
import { Link } from '../../../../components/Link/Link';
import { useGameContext } from '../../hooks/useGameContext';
import Heading from '@atlaskit/heading';
import SettingsIcon from '@atlaskit/icon/glyph/settings';
import IssuesIcon from '@atlaskit/icon/glyph/editor/layout-two-right-sidebar';
import { IconButton } from '@atlaskit/button/new';
import { useSidebarLayoutContext } from '../SidebarLayoutWrapper/useSidebarLayoutContext';
import Tooltip from '@atlaskit/tooltip';
import cx from 'classnames';
import { FeedbackButton } from '../FeedbackButton/FeedbackButton';

export const GameHeader = () => {
  const gameContext = useGameContext();
  const gameName = (gameContext && gameContext.game?.name) || '';
  const sidebarContext = useSidebarLayoutContext();
  const isMissingStoryPoints =
    gameContext?.game?.id && !gameContext.game.storyPointsField;

  const isSidebarOpen = sidebarContext.issuesSidebar[0];
  const setIsSidebarOpen = sidebarContext.issuesSidebar[1];

  const setIsSettingsSidebarOpen = sidebarContext.settingsSidebar[1];

  return (
    <div
      className={cx(
        styles.header,
        !isSidebarOpen && styles['header--sidebar-closed'],
      )}
    >
      <Inline alignBlock="center" space="space.200">
        <Link href="/" className={styles['logo-link']}>
          <LogoIcon className={styles['logo-icon']} color="primary" />
        </Link>

        {gameName && <Heading level="h700">{gameName}</Heading>}

        <Tooltip content="Game settings">
          {(tooltipProps) => (
            <div {...tooltipProps}>
              <div
                className={cx(
                  styles['settings-button-wrapper'],
                  isMissingStoryPoints &&
                    styles['settings-button-wrapper--error'],
                )}
              >
                <IconButton
                  label="Settings"
                  shape="circle"
                  icon={SettingsIcon}
                  onClick={() => {
                    setIsSettingsSidebarOpen(true);
                  }}
                />
              </div>
            </div>
          )}
        </Tooltip>
      </Inline>
      <Inline alignBlock="center" space="space.100">
        <InvitePlayersButton />
        <FeedbackButton />
        <AuthButton />
        <Tooltip
          content={
            isSidebarOpen ? 'Hide issues sidebar' : 'Show issues sidebar'
          }
        >
          {(tooltipProps) => (
            <div {...tooltipProps}>
              <IconButton
                label="Issues"
                shape="circle"
                icon={IssuesIcon}
                isSelected={!!isSidebarOpen}
                onClick={() => {
                  setIsSidebarOpen((isSidebarOpen) => !isSidebarOpen);
                }}
              />
            </div>
          )}
        </Tooltip>
      </Inline>
    </div>
  );
};
