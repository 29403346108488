import { useContext } from 'react';
import { GameTemplate } from '../game/templates/GameTemplate/GameTemplate';
import { NewGameTemplate } from '../game/templates/NewGameTemplate/NewGameTemplate';
import { DashboardTemplate } from '../game/templates/DashboardTemplate/DashboardTemplate';
import { NavigationContext } from './NavigationContext';
import { FieldsProvider } from '../jira/fields/FieldsProvider';

type Route = 'game' | 'new-game' | 'dashboard' | 'get-started';

const resolveRoute = (path?: string): { route: Route; gameId?: string } => {
  if (typeof path === 'undefined') {
    return { route: 'dashboard' };
  }

  const parts = path.split('/');

  if (parts.length === 2 && parts[0] === 'game') {
    return { route: 'game', gameId: parts[1] };
  }

  if (path === 'new-game') {
    return { route: 'new-game' };
  }

  return { route: 'dashboard' };
};

export const Router = () => {
  const navigation = useContext(NavigationContext);
  const { route, gameId } = resolveRoute(navigation?.currentRoute);

  switch (route) {
    case 'new-game':
      return (
        <FieldsProvider>
          <NewGameTemplate />
        </FieldsProvider>
      );
    case 'game':
      return gameId ? (
        <FieldsProvider gameId={gameId}>
          <GameTemplate gameId={gameId} />
        </FieldsProvider>
      ) : null;
    default:
      return <DashboardTemplate />;
  }
};
