import { Field } from '@atlaskit/form';
import Select from '@atlaskit/select';
import { useEffect, useState } from 'react';
import { IssueFilter } from '../../../types';

type ExtraFilter = { value: IssueFilter; label: string };

const EXTRA_FILTERS: ExtraFilter[] = [
  { value: 'type', label: 'Type' },
  { value: 'status', label: 'Status' },
  { value: 'filter', label: 'My Filters' },
  { value: 'epic', label: 'Epic' },
];

export const MoreFiltersPicker = ({
  onAddFilter,
  activeFilters,
}: {
  onAddFilter: (filter: IssueFilter) => void;
  activeFilters: IssueFilter[];
}) => {
  const [filters, setFilters] = useState<ExtraFilter[] | null>(null);

  useEffect(() => {
    const filters = EXTRA_FILTERS.filter(
      (extraFilter) =>
        !activeFilters.find(
          (activeFilter) => activeFilter === extraFilter.value,
        ),
    );

    setFilters(filters);
  }, [activeFilters]);

  return (
    <div>
      <Field aria-required={true} name="filter" label={<div />}>
        {() => (
          <Select
            placeholder="More filters"
            value={null}
            options={filters || []}
            isLoading={!filters}
            onChange={(value) => value?.value && onAddFilter(value?.value)}
            appearance="subtle"
          />
        )}
      </Field>
    </div>
  );
};
