import { Field } from '@atlaskit/form';
import Select from '@atlaskit/select';
import { useEffect, useState } from 'react';
import { Epic, getEpics } from '../../../../../../jira/api';
import styles from '../Pickers.module.scss';

export const EpicPicker = ({
  selectedEpics,
  onChange,
  boardId,
  projectId,
}: {
  selectedEpics?: Epic[] | null;
  onChange: (epic: Epic[] | null) => void;
  boardId?: string | null;
  projectId?: string | null;
}) => {
  const [epics, setEpics] = useState<Epic[] | null>(null);
  const [error, setError] = useState<Error | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!boardId) {
      setEpics(null);

      return;
    }

    setIsLoading(true);

    getEpics(boardId)
      .then((epics) => {
        setEpics(epics);
      })
      .catch((e) => {
        console.error('epics error', e);
        setError(e);
        setEpics(null);
      })
      .finally(() => setIsLoading(false));
  }, [boardId]);

  const epicOptions = epics?.map((epic) => ({
    value: epic,
    label: epic.summary,
  }));
  const values = epicOptions?.filter(
    (option) =>
      selectedEpics?.find(
        (selectedEpic) => selectedEpic.id === option.value.id,
      ),
  );

  const isProjectSelected = !!projectId;

  const resolveNoOptionsMessage = () => {
    if (error) {
      return error.message;
    }

    if (isProjectSelected) {
      return `No epics for project ${projectId}`;
    }

    return 'Please select a project first';
  };

  return (
    <div className={styles['filter-select']}>
      <Field aria-required={true} name="epic" label="Epic">
        {() => (
          <Select
            placeholder="Epic"
            options={epicOptions || []}
            value={values?.length ? values : []}
            noOptionsMessage={resolveNoOptionsMessage}
            isLoading={isLoading}
            isClearable
            onChange={(multiValue) => {
              const values = multiValue?.map((option) => option.value);
              onChange(values);
            }}
            isMulti
          />
        )}
      </Field>
    </div>
  );
};
