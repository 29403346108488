import { CustomDeck } from '@we-agile-you/types-planning-poker';
import { useContext, useRef, useState } from 'react';

import styles from './NewGameTemplate.module.scss';
import { NavigationContext } from '../../../navigation/NavigationContext';
import { CreateGameForm } from '../../components/CreateGameForm/CreateGameForm';

export const NewGameTemplate = () => {
  const [isCreateDeckModalOpen, setIsCreateDeckModalOpen] = useState(false);
  const settingsFormRef = useRef<{
    setSelectedDeck: (deck: CustomDeck) => void;
  }>({
    setSelectedDeck: () => ({}),
  });
  const nav = useContext(NavigationContext);

  const onCreated = (gameId: string) => {
    if (!nav) {
      return;
    }
    const path = `game/${gameId}`;
    nav.navigate(path);
  };

  // const handleDeckCreated = (deck: CustomDeck) => {
  //   setIsCreateDeckModalOpen(false);
  //   settingsFormRef.current.setSelectedDeck(deck);
  // };

  return (
    <>
      <div className={styles['form-wrapper']}>
        <div className={styles['form-container']}>
          <CreateGameForm
            // ref={settingsFormRef}
            onCreated={onCreated}
            // onSelectCustomDeck={() => setIsCreateDeckModalOpen(true)}
          />
        </div>
      </div>
      {/* {isCreateDeckModalOpen && (
        <CreateDeckModal
          onCreated={handleDeckCreated}
          onClose={() => setIsCreateDeckModalOpen(false)}
        />
      )} */}
    </>
  );
};
