import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import * as Sentry from '@sentry/gatsby';

import {
  AuthErrorCodes,
  fetchSignInMethodsForEmail,
  GithubAuthProvider,
  GoogleAuthProvider,
  OAuthProvider,
  linkWithCredential,
  OAuthCredential,
} from 'firebase/auth';
import { linkWithPopup, signInWithPopup, signOut } from '../../data/auth';
import { auth } from '@we-agile-you/firebase';

import { InlineAlert, VerticalSpacing } from '@we-agile-you/react-base';
import { USER_SIGNED_IN } from '../../constants';

import { GoogleSignInButton } from '../../components/GoogleSignInButton/GoogleSignInButton';
import { GithubSignInButton } from '../../components/GithubSignInButton/GithubSignInButton';

import { FirebaseError } from 'firebase/app';
import { AuthError } from 'firebase/auth';
import {
  hotjarIdentify,
  HOTJAR_IDENTIFY_KEYS,
} from '@we-agile-you/planning-poker-app/src/vendors/hotjar/identify';
import { MicrosoftSignInButton } from '../../components/MicrosoftSignInButton/MicrosoftSignInButton';

const providers = {
  'google.com': new GoogleAuthProvider(),
  'github.com': new GithubAuthProvider(),
  'microsoft.com': new OAuthProvider('microsoft.com'),
};

const providerTypes = {
  'google.com': GoogleAuthProvider,
  'github.com': GithubAuthProvider,
  'microsoft.com': OAuthProvider,
};

const providerTypeToNameMapping = {
  'google.com': 'Google',
  'github.com': 'GitHub',
  'microsoft.com': 'Microsoft',
  emailLink: 'Email',
  password: 'Email and Password',
};

export type SupportedProviderType =
  | 'google.com'
  | 'github.com'
  | 'microsoft.com';

export type ExistingCredential = {
  credential: OAuthCredential;
  email: string;
};

export type onOpenMailInUseAlertFunc = () => void;

export type ThirdPartySignInButtonsProps = {
  onOpenMailInUseAlert: onOpenMailInUseAlertFunc;
  onSignedIn: () => void;
  mode: 'sign-up' | 'sign-in';
};

export const ThirdPartySignInButtons = (
  props: ThirdPartySignInButtonsProps,
) => {
  const [loadingProvider, setLoadingProvider] =
    useState<SupportedProviderType | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [existingCredential, setExistingCredential] =
    useState<ExistingCredential | null>(null);
  const dispatch = useDispatch();

  const { mode, onSignedIn, onOpenMailInUseAlert } = props;

  const handleSignIn = async (providerName: SupportedProviderType) => {
    setLoadingProvider(providerName);

    if (mode === 'sign-up') {
      try {
        const userCredential = await linkWithPopup(
          providers[providerName],
          true,
        );
        if (userCredential?.user) {
          dispatch({
            type: USER_SIGNED_IN,
            uid: userCredential.user.uid,
            email: userCredential.user.email,
            isAnonymous: userCredential.user.isAnonymous,
          });
          onSignedIn();
        }
      } catch (e) {
        if (e instanceof FirebaseError) {
          if (
            e.code === AuthErrorCodes.CREDENTIAL_ALREADY_IN_USE ||
            e.code === AuthErrorCodes.EMAIL_EXISTS ||
            e.code === AuthErrorCodes.NEED_CONFIRMATION
          ) {
            Sentry.captureException(
              `User tried to sign up with ${providerName}, but already has existing account`,
            );
            hotjarIdentify(HOTJAR_IDENTIFY_KEYS.HAS_SIGNUP_ERROR(providerName));
            onOpenMailInUseAlert();
          } else if (e.code === AuthErrorCodes.POPUP_CLOSED_BY_USER) {
            // do nothing
          } else {
            Sentry.captureException(e);
            const message =
              e.code === AuthErrorCodes.POPUP_BLOCKED
                ? 'Popup blocked by browser. Please allow popups for this site at your browser top bar.'
                : e.message || 'Unknown error signing up, please try again.';

            setError(message);
          }
        }
      } finally {
        setLoadingProvider(null);
      }
    } else {
      try {
        const credential = await signInWithPopup(providers[providerName]);
        if (existingCredential) {
          const existingEmail = existingCredential.email;
          if (credential?.user.email === existingEmail) {
            await linkWithCredential(
              credential.user,
              existingCredential.credential,
            );
            onSignedIn();
          } else {
            setError('Looks like the accounts has different emails.');
            signOut();
          }
        } else {
          onSignedIn();
        }
      } catch (e) {
        if (e instanceof FirebaseError) {
          if (e.code === AuthErrorCodes.NEED_CONFIRMATION) {
            const authError = e as AuthError;
            var email = authError.customData.email;
            if (email) {
              const methods = await fetchSignInMethodsForEmail(auth, email);
              const defaultMethod =
                methods[0] as keyof typeof providerTypeToNameMapping;
              if (
                defaultMethod === 'emailLink' ||
                defaultMethod === 'password'
              ) {
                Sentry.captureException(
                  `User tried to login with ${providerName}, but already has ${defaultMethod} credentials`,
                );
                hotjarIdentify(
                  HOTJAR_IDENTIFY_KEYS.HAS_LOGIN_ERROR(
                    providerName,
                    defaultMethod,
                  ),
                );
                setError(
                  `You already have an account! Please login with ${providerTypeToNameMapping[defaultMethod]} to continue.`,
                );
              } else {
                const credential =
                  await providerTypes[providerName].credentialFromError(e);
                if (credential) {
                  Sentry.captureException(
                    `User tried to login with ${providerName}, but already has ${defaultMethod} credentials`,
                  );
                  hotjarIdentify(
                    HOTJAR_IDENTIFY_KEYS.HAS_LOGIN_ERROR(
                      providerName,
                      defaultMethod,
                    ),
                  );
                  setExistingCredential({
                    credential: credential,
                    email: email,
                  });
                  setError(
                    `You already have an account! Please login with ${providerTypeToNameMapping[defaultMethod]} to continue.`,
                  );
                }
              }
            }
          }
        } else if (e instanceof Error) {
          Sentry.captureException(e);
          setError(e.message || 'Unknown error signing up, please try again.');
        }
      } finally {
        setLoadingProvider(null);
      }
    }
  };

  return (
    <>
      {error && <InlineAlert content={error} withoutBorder />}
      <VerticalSpacing spacing="spacing-m" />
      <GoogleSignInButton
        onSignIn={() => handleSignIn('google.com')}
        isLoading={loadingProvider === 'google.com'}
        isDisabled={loadingProvider !== null}
        mode={mode}
      />
      <VerticalSpacing spacing="spacing-m" />
      <GithubSignInButton
        onSignIn={() => handleSignIn('github.com')}
        isLoading={loadingProvider === 'github.com'}
        isDisabled={loadingProvider !== null}
        mode={mode}
      />
      <VerticalSpacing spacing="spacing-m" />
      <MicrosoftSignInButton
        onSignIn={() => handleSignIn('microsoft.com')}
        isLoading={loadingProvider === 'microsoft.com'}
        isDisabled={loadingProvider !== null}
        mode={mode}
      />
    </>
  );
};
