import { useState, useEffect } from 'react';

import { Navigation } from './types';

const getPath = () => {
  return window?.AP?.history?.getState('hash') || '/';
};

export const useNavigation = (): Navigation => {
  const [currentRoute, setCurrentRoute] = useState(getPath());

  const navigate = (path: string) => {
    window?.AP?.history?.pushState(path);
  };

  const goBack = () => {
    window?.AP?.history?.back();
  };

  useEffect(() => {
    window?.AP?.history?.popState((e: any) => {
      setCurrentRoute(e.newURL);
    });
  }, []);

  return { navigate, currentRoute, goBack };
};
