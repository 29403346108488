import { Field } from '@atlaskit/form';
import Select from '@atlaskit/select';
import { useEffect, useState } from 'react';
import { getStatuses, Status } from '../../../../../../jira/api';

import styles from '../Pickers.module.scss';

export const StatusPicker = ({
  projectId,
  onChange,
  selectedStatuses,
}: {
  projectId: string | null;
  onChange: (statuses: Status[] | null) => void;
  selectedStatuses?: Status[] | null;
}) => {
  const [statuses, setStatuses] = useState<Status[] | null>(null);
  const [error, setError] = useState<Error | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!projectId) {
      setStatuses(null);

      return;
    }

    setIsLoading(true);

    getStatuses(projectId)
      .then((statuses) => {
        setStatuses(
          statuses.filter(
            (status, index) =>
              index ===
              statuses.findIndex((_status) => _status.name === status.name),
          ),
        );
      })
      .catch((e) => {
        console.error('statuses error', e);
        setError(e);
        setStatuses(null);
      })
      .finally(() => setIsLoading(false));
  }, [projectId]);

  const statusesOptions = statuses?.map((status) => ({
    value: status,
    label: status.name,
  }));

  const isProjectSelected = !!projectId;

  const values = statusesOptions?.filter(
    (option) =>
      selectedStatuses?.find(
        (selectedStatus) => selectedStatus.name === option.value.name,
      ),
  );

  const resolveNoOptionsMessage = () => {
    if (error) {
      return error.message;
    }

    if (isProjectSelected) {
      return `No statuses for project ${projectId}`;
    }

    return 'Please select a project first';
  };

  return (
    <div className={styles['filter-select']}>
      <Field aria-required={true} name="status" label="Issue status">
        {() => (
          <Select
            placeholder="Status"
            options={statusesOptions || []}
            isLoading={isLoading}
            onChange={(multiValue) => {
              const values = multiValue?.map((option) => option.value);
              onChange(values);
            }}
            value={values?.length ? values : []}
            noOptionsMessage={resolveNoOptionsMessage}
            isClearable
            isMulti
          />
        )}
      </Field>
    </div>
  );
};
