import React from 'react';

export const Games = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.732 2C12.9622 0.666667 11.0377 0.666666 10.2679 2L6.80385 8C6.03405 9.33333 6.9963 11 8.5359 11H15.4641C17.0037 11 17.966 9.33333 17.1962 8L13.732 2ZM8.5359 9L12 3L15.4641 9H8.5359ZM6 15C4.34315 15 3 16.3431 3 18C3 19.6569 4.34315 21 6 21C7.65685 21 9 19.6569 9 18C9 16.3431 7.65685 15 6 15ZM1 18C1 15.2386 3.23858 13 6 13C8.76142 13 11 15.2386 11 18C11 20.7614 8.76142 23 6 23C3.23858 23 1 20.7614 1 18ZM13 15C13 13.8954 13.8954 13 15 13H21C22.1046 13 23 13.8954 23 15V21C23 22.1046 22.1046 23 21 23H15C13.8954 23 13 22.1046 13 21V15ZM21 15H15V21H21V15Z"
      fill="currentColor"
    />
  </svg>
);
