import { createContext } from 'react';
import { Field } from '../api';

export type FieldContextType = {
  fields: Field[] | null;
  guessedStoryPointsField: Field | null;
  error: Error | null;
};

export const FieldsContext = createContext<FieldContextType>({
  fields: null,
  guessedStoryPointsField: null,
  error: null,
});
