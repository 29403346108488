import { forwardRef, ReactNode, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import TextField from '@atlaskit/textfield';
import Form, { ErrorMessage, FormFooter, Field } from '@atlaskit/form';
import Select, { ValueType } from '@atlaskit/select';

import ButtonGroup from '@atlaskit/button/button-group';
import LoadingButton from '@atlaskit/button/loading-button';
import ButtonDefault from '@atlaskit/button/standard-button';

import styles from './SettingsForm.module.scss';
import { CUSTOM_DECK_VALUE, DECKS, DEFAULT_DECK } from '../../../constants';
import Button from '@atlaskit/button';
import { CreateDeckModal } from '../CreateDeckModal/CreateDeckModal';
import { useAuthContext } from '../../../auth/useAuthContext';
import { Inline, Stack } from '@atlaskit/primitives';
import Avatar from '@atlaskit/avatar';
import Heading from '@atlaskit/heading';
import { useNavigation } from '../../../navigation/useNavigaton';
import { SelectWhoCan, UserOption } from './SelectWhoCan';
import { ToggleField } from '../../../../components/ToggleField/ToogleField';
import { CustomDeck, Deck, DefaultDeck, Game } from '../../../types';
import { updateCurrentUser } from '../../../auth/data';
import { StoryPointsFieldPicker } from '../SearchPanel/components/StoryPointsFieldPicker/StoryPointsFieldPicker';
import { Field as FieldJira } from '../../../jira/api';
import { getObjectWithoutUndefinedValues } from '@we-agile-you/js-base';
import { ManageDecksModal } from '../ManageDecksModal/ManageDecksModal';

interface Option {
  label: ReactNode;
  value: string;
  deck?: Deck;
}

export type SettingsFormData = {
  name: string;
  deck: ValueType<Option>;
  whoCanShowCards: UserOption[] | null;
  whoCanEditIssues: UserOption[] | null;
  autoShowCards?: boolean;
  enableProjectiles?: boolean;
  showAverageInResults?: boolean;
  storyPointsField?: FieldJira | null;
};
export type SettingsFormValues = {
  name: string;
  deck: Option;
  whoCanShowCards: string[] | null;
  whoCanEditIssues: string[] | null;
  autoShowCards: boolean;
  enableProjectiles: boolean;
  showAverageInResults: boolean;
  storyPointsField: FieldJira | null;
};

const renderCustomDeckOptionLabel = (customDeck: CustomDeck) => {
  return (
    <Stack>
      <div>{`${customDeck.name} (${customDeck.value})`} </div>
      <div>
        <Inline alignBlock="center">
          <Avatar size="xsmall" src={customDeck.creatorAvatar} />
          <Heading level="h100">{customDeck.creatorName}</Heading>
        </Inline>
      </div>
    </Stack>
  );
};

const getDeckOptionValue = (deck: Deck) => {
  const customDeck = deck as CustomDeck;

  if (customDeck.creatorId) {
    return `${customDeck.creatorId}_${customDeck.name}_${customDeck.value}`;
  }

  return `${deck.name}_${deck.value}`;
};

export const getDeckOption = (deck: Deck): Option => {
  return {
    deck: deck,
    value: getDeckOptionValue(deck),
    label: (deck as CustomDeck).creatorId
      ? renderCustomDeckOptionLabel(deck as CustomDeck)
      : deck.name,
  };
};

const DEFAULT_FORM_VALUES: SettingsFormValues = {
  name: '',
  deck: getDeckOption(DEFAULT_DECK),
  whoCanShowCards: null,
  whoCanEditIssues: null,
  autoShowCards: false,
  enableProjectiles: true,
  showAverageInResults: true,
  storyPointsField: null,
};
const getDeckOptions = (customDecks?: CustomDeck[] | null): Option[] => {
  const customDecksSorted = customDecks
    ? [...customDecks].sort((customDeckA, customDeckB) => {
        const creatorA: string = customDeckA.creatorName
          .replace(/\s/g, '')
          .toLowerCase();
        const creatorB: string = customDeckB.creatorName
          .replace(/\s/g, '')
          .toLowerCase();
        const nameA = customDeckA.name.replace(/\s/g, '').toLowerCase();
        const nameB = customDeckB.name.replace(/\s/g, '').toLowerCase();

        if (creatorA !== creatorB) {
          return creatorA < creatorB ? -1 : 1;
        }
        if (nameA !== nameB) {
          return nameA < nameB ? -1 : 1;
        }

        return 0;
      })
    : [];

  const customDecksOptions: Option[] = customDecksSorted.map((customDeck) => ({
    label: renderCustomDeckOptionLabel(customDeck),
    deck: customDeck,
    value: getDeckOptionValue(customDeck),
  }));

  const defaultDecksOptions: Option[] = DECKS.map((deck: DefaultDeck) => {
    return {
      label: deck.name,
      deck: deck,
      value: getDeckOptionValue(deck),
    };
  });

  const customDeckOption: Option = {
    label: <Button appearance="link">Create custom deck</Button>,
    value: CUSTOM_DECK_VALUE,
  };

  const decks: Option[] = [
    ...defaultDecksOptions,
    ...customDecksOptions,
    customDeckOption,
  ];

  return decks;
};

type SettingsFormCreateProps = {
  onSubmit: (data: Partial<Game>) => void;
  defaultFormValues: Partial<SettingsFormValues>;
  formMode: 'new-game';
};
type SettingsFormGameProps = {
  defaultFormValues: Partial<SettingsFormValues>;
  onValuesChanged: (values: Partial<Game>) => void;
  formMode: 'game-settings';
  onSubmit: () => void;
};

type SettingsFormProps = SettingsFormCreateProps | SettingsFormGameProps;

const SettingsForm = forwardRef(function SettingsForm(
  props: SettingsFormProps,
) {
  const formMode = props.formMode;
  const defaultValues: SettingsFormValues = {
    ...DEFAULT_FORM_VALUES,
    ...props.defaultFormValues,
  };

  const [isCreateDeckModalOpen, setIsCreateDeckModalOpen] = useState(false);
  const [isManageDecksModalOpen, setIsManageDecksModalOpen] = useState(false);
  const [isShowMoreSettings, setIsShowMoreSettings] = useState(false);
  const textInput = useRef<HTMLInputElement | null>(null);
  const auth = useAuthContext();
  const appData = auth && auth.appData;
  const navigation = useNavigation();
  const deckOptions = getDeckOptions(
    appData && appData.customDecks ? appData.customDecks : [],
  );

  const handleNameChanged = (name: string) => {
    if (props.formMode === 'game-settings') {
      props.onValuesChanged({
        name,
      });
    }
  };

  const handleWhoCanShowCardsChanged = (playerIds: string[] | null) => {
    if (props.formMode === 'game-settings') {
      props.onValuesChanged({
        whoCanShowCards: playerIds?.length ? playerIds : null,
      });
    }
  };

  const handleWhoCanEditIssuesChanged = (playerIds: string[] | null) => {
    if (props.formMode === 'game-settings') {
      props.onValuesChanged({
        whoCanEditIssues: playerIds?.length ? playerIds : null,
      });
    }
  };

  const handleAutoShowCardsChanged = (autoShowCards: boolean) => {
    if (props.formMode === 'game-settings') {
      props.onValuesChanged({
        autoShowCards,
      });
    }
  };

  const handleEnableProjectilesChanged = (enableProjectiles: boolean) => {
    if (props.formMode === 'game-settings') {
      props.onValuesChanged({
        enableProjectiles,
      });
    }
  };

  const handleShowAverageInResultsChanged = (showAverageInResults: boolean) => {
    if (props.formMode === 'game-settings') {
      props.onValuesChanged({
        hideAverageInResults: !showAverageInResults,
      });
    }
  };

  const handleStoryPointsChanged = (field: FieldJira | null) => {
    updateCurrentUser(auth.appId, {
      lastSelectedStoryPointsField: field,
    });

    if (props.formMode === 'game-settings') {
      props.onValuesChanged({
        storyPointsField: field,
      });
    }
  };

  const handleSubmit = (data: SettingsFormData) => {
    if (!auth || !auth.appId) {
      return;
    }

    const dataComplete: SettingsFormData = {
      ...defaultValues,
      ...getObjectWithoutUndefinedValues(data),
    };

    const whoCanShowCards = dataComplete.whoCanShowCards?.length
      ? dataComplete.whoCanShowCards.map((option) =>
          typeof option === 'string' ? option : option.value,
        )
      : null;
    const whoCanEditIssues = dataComplete.whoCanEditIssues?.length
      ? dataComplete.whoCanEditIssues.map((option) =>
          typeof option === 'string' ? option : option.value,
        )
      : null;

    const deckValue: Deck = dataComplete.deck?.deck
      ? dataComplete.deck.deck
      : DEFAULT_DECK;

    if (formMode === 'new-game') {
      updateCurrentUser(auth.appId, {
        lastSelectedWhoCanShowCards: whoCanShowCards,
        lastSelectedWhoCanEditIssues: whoCanEditIssues,
        lastSelectedAutoShowCards: !!dataComplete.autoShowCards,
        lastSelectedEnableProjectiles: !!dataComplete.enableProjectiles,
        lastSelectedHideAverage: !dataComplete.showAverageInResults,
      });

      props.onSubmit({
        name: dataComplete.name,
        deck: deckValue,
        whoCanShowCards,
        whoCanEditIssues,
        autoShowCards: dataComplete.autoShowCards,
        enableProjectiles: dataComplete.enableProjectiles,
        hideAverageInResults: !dataComplete.showAverageInResults,
        storyPointsField: dataComplete.storyPointsField,
      });
    } else {
      props.onSubmit();
    }
  };

  return (
    <div className={styles['settings']}>
      <Form<SettingsFormData> onSubmit={handleSubmit}>
        {({ formProps, submitting }) => (
          <form {...formProps}>
            <Field
              name="name"
              label="Game's name"
              defaultValue={defaultValues.name}
            >
              {({ fieldProps, error }) => (
                <>
                  <TextField
                    maxLength={60}
                    ref={textInput}
                    placeholder="Set an optional game name"
                    {...fieldProps}
                    onChange={(e) => {
                      handleNameChanged(e.currentTarget.value);
                      fieldProps.onChange(e);
                    }}
                  />
                  {error && <ErrorMessage>{error}</ErrorMessage>}
                </>
              )}
            </Field>
            <Field<ValueType<Option>> name="deck" label="Voting system">
              {({ fieldProps: { id, onChange, ...rest }, error }) => {
                const handleDeckChanged = (valueOption: Option | null) => {
                  onChange(valueOption);

                  if (!auth || !auth.appId) {
                    return;
                  }

                  const deck = valueOption?.deck;

                  if (!deck) return;

                  updateCurrentUser(auth.appId, {
                    lastSelectedDeck: deck,
                  });

                  if (props.formMode === 'game-settings') {
                    props.onValuesChanged({
                      deck,
                    });
                  }
                };

                const handleSelectCustomDeck = () => {
                  if (!rest.value) {
                    onChange(defaultValues.deck);
                  }
                  setIsCreateDeckModalOpen(true);
                };

                const handleCustomDeckCreation = (customDeck: CustomDeck) => {
                  setIsCreateDeckModalOpen(false);
                  handleDeckChanged({
                    label: renderCustomDeckOptionLabel(customDeck),
                    deck: customDeck,
                    value: getDeckOptionValue(customDeck),
                  });
                };

                return (
                  <>
                    <Select<Option>
                      inputId={id}
                      {...rest}
                      onChange={(option) => {
                        if (option?.value === CUSTOM_DECK_VALUE) {
                          handleSelectCustomDeck();
                        } else {
                          handleDeckChanged(option);
                        }
                      }}
                      options={deckOptions}
                      defaultValue={defaultValues.deck}
                      required
                    />
                    {error && <ErrorMessage>{error}</ErrorMessage>}
                    {formMode === 'game-settings' && (
                      <Stack space="space.100">
                        <div />
                        <Inline alignInline="end">
                          <Button
                            onClick={() => setIsManageDecksModalOpen(true)}
                          >
                            Manage decks
                          </Button>
                        </Inline>
                      </Stack>
                    )}

                    {isCreateDeckModalOpen &&
                      createPortal(
                        <CreateDeckModal
                          onClose={() => setIsCreateDeckModalOpen(false)}
                          onCreated={handleCustomDeckCreation}
                        />,
                        document.body,
                      )}
                    {isManageDecksModalOpen &&
                      createPortal(
                        <ManageDecksModal
                          onClose={() => setIsManageDecksModalOpen(false)}
                        />,
                        document.body,
                      )}
                  </>
                );
              }}
            </Field>

            {formMode === 'new-game' && !isShowMoreSettings && (
              <div className={styles['show-more-button']}>
                <Button onClick={() => setIsShowMoreSettings(true)}>
                  Show advanced settings...
                </Button>
              </div>
            )}

            {(formMode === 'game-settings' || isShowMoreSettings) && (
              <div>
                <SelectWhoCan
                  label="Who can reveal cards"
                  name="whoCanShowCards"
                  onChanged={handleWhoCanShowCardsChanged}
                  defaultValue={defaultValues.whoCanShowCards}
                  helperMessage="Players who are allowed to flip cards and show results."
                />
                <SelectWhoCan
                  label="Who can manage issues"
                  name="whoCanEditIssues"
                  onChanged={handleWhoCanEditIssuesChanged}
                  defaultValue={defaultValues.whoCanEditIssues}
                  helperMessage="Players who are allowed to add, edit and delete issues."
                />
                <Stack space="space.200">
                  <div />
                  <StoryPointsFieldPicker
                    defaultValue={defaultValues.storyPointsField}
                    onChanged={handleStoryPointsChanged}
                  />
                  <ToggleField
                    defaultValue={defaultValues.autoShowCards}
                    id="auto-show-cards"
                    name="autoShowCards"
                    label="Auto-reveal cards"
                    helperMessage="Show cards automatically after everyone voted."
                    onChanged={handleAutoShowCardsChanged}
                  />
                  <ToggleField
                    defaultValue={defaultValues.enableProjectiles}
                    id="enable-projectiles"
                    name="enableProjectiles"
                    label="Enable fun features"
                    helperMessage="Allow players throw projectiles to each other in this game."
                    onChanged={handleEnableProjectilesChanged}
                  />
                  <ToggleField
                    defaultValue={defaultValues.showAverageInResults}
                    id="show-average-in-results"
                    name="showAverageInResults"
                    label="Show average in the results"
                    helperMessage="Include the average value in the results of the voting."
                    onChanged={handleShowAverageInResultsChanged}
                  />
                </Stack>
              </div>
            )}
            <div className={styles[`footer--${formMode}`]}>
              <FormFooter>
                <ButtonGroup>
                  <ButtonDefault
                    appearance="subtle"
                    onClick={() => navigation.goBack()}
                  >
                    Cancel
                  </ButtonDefault>
                  <LoadingButton
                    type="submit"
                    appearance="primary"
                    isLoading={submitting}
                  >
                    {'Create game'}
                  </LoadingButton>
                </ButtonGroup>
              </FormFooter>
            </div>
          </form>
        )}
      </Form>
    </div>
  );
});

export default SettingsForm;
