import cx from 'classnames';
import React, { useRef, useState, ReactNode } from 'react';
import ReactDOM from 'react-dom';
import { ButtonIcon } from '../../atoms/ButtonIcon/ButtonIcon';
import { Icon } from '../../atoms/Icon/Icon';
import { useHandleClickOuside } from '../../hooks/useHandleClickOutside';
import { usePopover } from 'react-use-popover';

import styles from './ButtonDropdown.module.scss';
import { ButtonFloating } from '../../atoms/ButtonFloating/ButtonFloating';

interface ButtonDropdownCommonProps {
  /** The content of the Button */
  children?: React.ReactNode;
  /** The content of the dropdown */
  dropdown?: React.ReactNode;
  /** Show/hide dropdown */
  isOpen: boolean;
  /** Size of the dropdown */
  dropdownSize?: 's' | 'm';
  /** Optional change the dropdown position */
  dropdownAlign?:
    | 'bottom'
    | 'bottom-left'
    | 'bottom-right'
    | 'left-bottom'
    | 'left-top'
    | 'right-bottom';
  /** Text hint to show on button over  */
  buttonTooltip?: ReactNode;
  /** Optional change the button tooltip position when default position doesn't look good  */
  buttonTooltipPosition?:
    | 'bottom-left'
    | 'bottom-right'
    | 'top-right'
    | 'right'
    | 'left';
  /** Whether you want to hide button tooltip on small devices */
  isDontHideButtonTooltipOnSmallScreen?: boolean;
  /** Tooltip is always visible (not only on over)  */
  buttonTooltipPin?: boolean;
  /** avoid auto translation */
  translate?: 'no';
  /** For e2e testing */
  'data-test'?: string;
  /** Extra css class */
  className?: string;
  /** Use this to handle the isOpen state */
  onIsOpenChange: (isOpen: boolean) => void;
  /** Click event handler */
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

interface ButtonDropdownDefaultProps extends ButtonDropdownCommonProps {
  /** button size */
  buttonSize?: 's' | 'm';
  /** The actual button component to use */
  buttonComponent: 'default';
  /** Actual color of the button */
  buttonColor?: 'default' | 'light';
}

interface ButtonDropdownIconDefaultProps extends ButtonDropdownCommonProps {
  /** button size */
  buttonSize?: 's' | 'm';
  /** The actual button component to use */
  buttonComponent: 'icon';
  /** Actual color of the button */
  buttonColor?: 'primary' | 'secondary' | 'light';
  /** When button is placed on non white backgrounds, used to visually fix the active outline effect */
  containerBackgroundColor?: 'grey200' | 'primary-white';
}

interface ButtonDropdownFloatingProps extends ButtonDropdownCommonProps {
  /** The actual button component to use */
  buttonComponent: 'floating';
  /** Actual color of the button */
  buttonStyle?: 'primary' | 'primary-opacity' | 'secondary' | 'tooltip';
  /** button size */
  buttonSize?: 'xs' | 's' | 'm';
}

export type ButtonDropdownProps =
  | ButtonDropdownDefaultProps
  | ButtonDropdownIconDefaultProps
  | ButtonDropdownFloatingProps;

export const ButtonDropdown = (props: ButtonDropdownProps) => {
  const dropdownRef = useRef<HTMLDivElement | null>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);

  const [dropdownElement, setDropdownElement] = useState<HTMLDivElement | null>(
    null,
  );

  const { updateDropdownPosition, dropdownStyle } = usePopover({
    anchorRef: buttonRef,
    position: props.dropdownAlign,
    dropdownElement,
  });

  const handleToggleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    document.body.click(); // hack to close other dropdowns
    updateDropdownPosition();

    props.onIsOpenChange(!props.isOpen);

    if (props.onClick) {
      props.onClick(event);
    }
  };

  useHandleClickOuside([dropdownRef, buttonRef], () => {
    props.isOpen && props.onIsOpenChange(false);
  });

  const renderButton = () => {
    const tooltip =
      (!props.isOpen || props.buttonTooltipPin) && props.buttonTooltip;

    if (props.buttonComponent === 'icon') {
      return (
        <ButtonIcon
          ref={buttonRef}
          buttonColor={props.buttonColor}
          onClick={handleToggleClick}
          translate={props.translate}
          className={props.className}
          data-test={props['data-test']}
          isActive={props.isOpen}
          size={props.buttonSize}
          tooltip={tooltip}
          pinTooltip={props.buttonTooltipPin}
          tooltipPosition={props.buttonTooltipPosition}
          isDontHideTooltipOnSmallScreen={
            props.isDontHideButtonTooltipOnSmallScreen
          }
          icon={props.children}
          containerBackgroundColor={props.containerBackgroundColor}
        />
      );
    } else if (props.buttonComponent === 'floating') {
      return (
        <ButtonFloating
          ref={buttonRef}
          onClick={handleToggleClick}
          buttonStyle={props.buttonStyle}
          translate={props.translate}
          className={props.className}
          data-test={props['data-test']}
          isActive={props.isOpen}
          size={props.buttonSize}
          tooltip={tooltip}
          pinTooltip={props.buttonTooltipPin}
          tooltipPosition={props.buttonTooltipPosition}
          isDontHideTooltipOnSmallScreen={
            props.isDontHideButtonTooltipOnSmallScreen
          }
        >
          {props.children}
        </ButtonFloating>
      );
    } else {
      return (
        <button
          ref={buttonRef}
          onClick={handleToggleClick}
          translate={props.translate}
          data-test={props['data-test']}
          className={cx(
            props.className,
            styles.button,
            props.isOpen && styles[`button--is-active`],
            props.buttonColor && styles[`button--${props.buttonColor}`],
          )}
        >
          {props.children}
          <div className={styles.chevron}>
            <Icon icon="chevron-down" />
          </div>
        </button>
      );
    }
  };

  return (
    <div className={styles.wrapper}>
      {renderButton()}

      {props.isOpen &&
        ReactDOM.createPortal(
          <div
            className={cx(
              styles['dropdown'],
              props.dropdownSize && styles[`dropdown--${props.dropdownSize}`],
            )}
            style={dropdownStyle}
            ref={(el) => {
              setDropdownElement(el);
              dropdownRef.current = el;
            }}
          >
            {props.dropdown}
          </div>,
          document.body,
        )}
    </div>
  );
};
