import { Field } from '@atlaskit/form';
import Select from '@atlaskit/select';
import { useEffect, useState } from 'react';
import { getSprints, Sprint } from '../../../../../../jira/api';
import styles from '../Pickers.module.scss';

export const SprintPicker = ({
  sprintId,
  onChange,
  boardId,
  projectId,
}: {
  sprintId?: string | null;
  onChange: (sprint: Sprint | null) => void;
  boardId?: string | null;
  projectId?: string | null;
}) => {
  const [sprints, setSprints] = useState<Sprint[] | null>(null);
  const [error, setError] = useState<Error | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!boardId) {
      setSprints(null);

      return;
    }

    setIsLoading(true);

    getSprints(boardId)
      .then((sprints) => {
        setSprints(sprints);
      })
      .catch((e) => {
        console.error('sprints error', e);
        setError(e);
        setSprints(null);
      })
      .finally(() => setIsLoading(false));
  }, [boardId]);

  const sprintOptions = sprints?.map((sprint) => ({
    value: sprint,
    label: sprint.name,
  }));

  const isProjectSelected = !!projectId;

  const resolveNoOptionsMessage = () => {
    if (error) {
      return error.message;
    }

    if (isProjectSelected) {
      return `No future sprints for project ${projectId}`;
    }

    return 'Please select a project first';
  };

  return (
    <div className={styles['filter-select']}>
      <Field aria-required={true} name="sprint" label="Sprint">
        {() => (
          <Select
            placeholder="Sprint"
            options={sprintOptions || []}
            value={
              sprintOptions?.find((option) => option.value.id === sprintId) ||
              null
            }
            noOptionsMessage={resolveNoOptionsMessage}
            isLoading={isLoading}
            isClearable
            onChange={(value) => onChange(value?.value || null)}
          />
        )}
      </Field>
    </div>
  );
};
