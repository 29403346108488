import React from 'react';
import cx from 'classnames';

import styles from './Switch.module.scss';

export interface SwitchProps {
  isActive: boolean;
  isDisabled?: boolean;
  onChange?: (value: boolean) => void;
}

export const Switch = ({ isActive, isDisabled, onChange }: SwitchProps) => {
  return (
    <button
      type="button"
      className={cx(
        styles.switch,
        isActive && styles.switchActive,
        isDisabled && styles.switchDisabled,
      )}
      onClick={() => onChange && !isDisabled && onChange(!isActive)}
    >
      <div className={styles.ball} />
    </button>
  );
};
