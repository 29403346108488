import {
  FormSelect,
  Paragraph,
  SelectPlayers,
  SelectPlayersValue,
  VerticalSpacing,
} from '@we-agile-you/react-base';
import { WhoCanShowCardsType } from '@we-agile-you/types-planning-poker';
import React, { useState } from 'react';
import { useCurrentUser } from '@we-agile-you/auth';
import { useCurrentTable } from '../../../spaces/poker-table/hooks/useCurrentTable';

interface SelectWhoCanShowCardsProps {
  onChange: (value: WhoCanShowCardsType) => void;
  isCreateForm: boolean;
  defaultValue?: 'all' | 'just-me' | string[] | null;
}

const LABEL = 'Who can reveal cards';
const OPTIONS_CREATE_GAME = [
  { value: 'all', label: 'All players' },
  { value: 'just-me', label: 'Just me' },
];

export const SelectWhoCanShowCards: React.FC<SelectWhoCanShowCardsProps> = ({
  onChange,
  isCreateForm,
  defaultValue,
}) => {
  const { uid } = useCurrentUser();
  const { pokerTable } = useCurrentTable();
  const { playersAll, whoCanShowCards } = pokerTable;

  const [value, setValue] = useState<SelectPlayersValue | 'just-me'>(
    isCreateForm ? defaultValue || 'all' : whoCanShowCards || 'all',
  );

  const handleChange = (value: SelectPlayersValue | 'just-me') => {
    setValue(value);

    if (value === 'all') {
      onChange(null);

      return;
    }

    if (value === 'just-me') {
      if (uid) {
        onChange([uid]);
      }

      return;
    }

    onChange(value);
  };

  return (
    <>
      {isCreateForm ? (
        <FormSelect
          label={LABEL}
          options={OPTIONS_CREATE_GAME}
          value={OPTIONS_CREATE_GAME.find((option) => option.value === value)}
          isNoMargin
          onChange={({ value }) => handleChange(value)}
        />
      ) : (
        <SelectPlayers
          label={LABEL}
          value={value !== 'just-me' ? value : 'all'}
          players={playersAll}
          onChange={handleChange}
          currentUserUid={uid}
        />
      )}
      <VerticalSpacing spacing="spacing-xxs" />
      <Paragraph color="grey500" size="small">
        Players who are allowed to flip cards and show results.
      </Paragraph>
    </>
  );
};
