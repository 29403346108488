import React from 'react';

export const Issues = () => (
  <svg
    width="22"
    height="18"
    viewBox="0 0 22 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 3C0 1.34315 1.34315 0 3 0H15C16.6569 0 18 1.34315 18 3H16C16 2.44772 15.5523 2 15 2H3C2.44772 2 2 2.44772 2 3V11C2 11.5523 2.44772 12 3 12V14C1.34315 14 0 12.6569 0 11V3ZM4 7C4 5.34315 5.34315 4 7 4H19C20.6569 4 22 5.34315 22 7V15C22 16.6569 20.6569 18 19 18H7C5.34315 18 4 16.6569 4 15V7ZM7 6C6.44772 6 6 6.44772 6 7V15C6 15.5523 6.44772 16 7 16H19C19.5523 16 20 15.5523 20 15V7C20 6.44772 19.5523 6 19 6H7ZM9 8C8.44772 8 8 8.44772 8 9C8 9.55228 8.44772 10 9 10H17C17.5523 10 18 9.55228 18 9C18 8.44772 17.5523 8 17 8H9ZM9 12C8.44772 12 8 12.4477 8 13C8 13.5523 8.44772 14 9 14H13C13.5523 14 14 13.5523 14 13C14 12.4477 13.5523 12 13 12H9Z"
      fill="currentColor"
    />
  </svg>
);
