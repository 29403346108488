import { JIRA_CONNECT_APPS_DATA_COLLECTION } from '../../constants';
import { query, collection, onSnapshot, orderBy } from 'firebase/firestore';
import { firestore } from '../../../firebase';
import { useEffect, useState } from 'react';
import { Game } from '../../types';

export const useCurrentAppGames = (appId: string | false | null) => {
  const [games, setGames] = useState<Game[] | null>(null);

  useEffect(() => {
    if (!appId) {
      return;
    }

    const gamesRef = query(
      collection(
        firestore,
        `${JIRA_CONNECT_APPS_DATA_COLLECTION}/${appId}/games`,
      ),
      orderBy('createdAt', 'desc'),
    );

    return onSnapshot(gamesRef, function (querySnapshot) {
      const games: Game[] = [];

      querySnapshot.forEach(function (doc) {
        const data = doc.data();
        games.push({
          ...data,
          createdAt: data.createdAt?.toDate(),
          id: doc.id,
        } as Game);
      });

      setGames(games);
    });
  }, [appId]);

  return games;
};
